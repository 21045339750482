import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { UserModel } from "../../_models/user.model";
import { SchoolModel } from "../../_models/school.model";
import { ClassModel } from "../../_models/class.model";
import { RoleModel } from "../../_models/role.model";
import { HttpClient, HttpErrorResponse, HttpParams } from "@angular/common/http";
import { ActivatedRoute, Router } from "@angular/router";
import { UserService } from "../../_stores/user/_service/user.service";
import { API } from "../../_stores/api";
import { ModalOpenAction } from "../../_stores/modal/_actions/modal.actions";
import { Store } from "@ngxs/store";
import { environment } from "../../../environments/environment";
import { AuthService } from "../../_stores/auth/_services/auth.service";
import { AuthLogin } from "../../_stores/auth/_actions/auth.actions";
import { SetPracticeToUser } from "../../_stores/user/_actions/user.actions";

@Component({
  selector: 'ui-page-register',
  templateUrl: './ui-page-register.component.html',
  styleUrls: ['./ui-page-register.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UiPageRegisterComponent implements OnInit {
  interface: {
    loading: boolean,
    error: boolean,
    submit: boolean,
    clever: boolean,
    user: {
      firstName: string,
      lastName: string,
      email: string,
      phone: string,
      password: string,
      role: string,
      school: string,
      practice: string
    },
    teacher: UserModel,
    schools: SchoolModel[],
    class: ClassModel,
    roles: RoleModel[],
    _cache: {
      accepted: boolean,
      open_search_school: boolean,
      selected_school: string,
      selected_district: string,
      error_fields: string[],
      clever_auth_url: string,
      selected_grade: string,
      open_select_grade: boolean
    }
  } = {
    loading: false,
    error: false,
    submit: false,
    clever: false,
    user: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      password: '',
      role: '',
      school: '',
      practice: undefined
    },
    teacher: undefined,
    schools: [],
    class: undefined,
    roles: undefined,
    _cache: {
      accepted: false,
      open_search_school: false,
      selected_school: '',
      selected_district: '',
      error_fields: [],
      clever_auth_url: '',
      selected_grade:'',
      open_select_grade:false,
    }
  }

  constructor(private store: Store,private cdr: ChangeDetectorRef,private http: HttpClient,private activatedRoute: ActivatedRoute,private userService: UserService,private router: Router, private  authService: AuthService) {

  }

  ngOnInit(): void {
    this.initRoles();
    this.initSchools();
  }
  initRoles(): void {
    this.interface.roles = [
      {
        _id: '62ebc2da8e33b7bc52c853c4',
        name: 'Teacher',
      },
      {
        _id: '62ebc2e38e33b7bc52c853c6',
        name: 'Student',
      },
    ];
    this.interface.user.role = this.interface.roles[0]._id;
  }
  initSchools(): void {
    this.http.get<any[]>(API.url('schools/search'),  { params: { search: '' } }).subscribe({
      next: (_next) => {
        this.interface.schools = _next;
        this.cdr.detectChanges();
      },
    });
  }

  actionSelectRole(role): void {
    this.interface.user.role = role._id;
  }
  actionToggleSelectGrade(): void {
    this.interface._cache.open_select_grade = !this.interface._cache.open_select_grade;
  }
  actionSelectGrade(grade, grade_id): void {
    this.interface._cache.open_select_grade = false;
    this.interface._cache.selected_grade = grade;
    this.interface.user.practice = grade_id;
  }


  actionToggleSearchSchool(): void {
    this.interface._cache.open_search_school = !this.interface._cache.open_search_school;
  }
  actionSearchSchool(event): void {
    const _search = event.target.value;
    if (event.target.value.length < 3) {
      return;
    }
    this.http.get<any[]>(API.url('schools/search'), { params: { search: _search } }).subscribe({
      next: (_next) => {
        this.interface.schools = _next;
        this.cdr.detectChanges();
      },
    });
  }
  actionSelectSchool(school): void {
    this.interface._cache.open_search_school = false;
    this.interface._cache.selected_school = school.schoolName;
    this.interface.user.school = school._id;
    this.cdr.detectChanges();

    if(this.interface.clever) {
      const url = 'https://argoprep.com/clever.php';
      let _clever_search = new HttpParams();
      _clever_search = _clever_search.append("q","(and name:'"+school.schoolName+"' state:'"+school.state+"')");
      _clever_search = _clever_search.append("q.parser",'structured');
      _clever_search = _clever_search.append("return",'_all_fields');
      this.http.get<any>(url,{
        headers: {
          'Content-Type': 'application/json',
        },
        params:_clever_search
      }).subscribe({
        next: (_next) => {
          if(_next.hits.hit.length > 0) {
            this.interface._cache.selected_district = _next.hits.hit[0].fields.district_id;
            //this.interface._cache.selected_district = '5b2ad81a709e300001e2cd7a';
            const _clever_state = {
              school_id: this.interface.user.school,
              district_id: this.interface._cache.selected_district,
              type: 'register'
            }
            this.interface._cache.clever_auth_url = '';
            this.interface._cache.clever_auth_url += environment.clever.auth_url;
            this.interface._cache.clever_auth_url += '&client_id='+environment.clever.client_id;
            this.interface._cache.clever_auth_url += '&redirect_uri='+environment.clever.redirect_url;
            this.interface._cache.clever_auth_url += '&district_id='+this.interface._cache.selected_district;
            this.interface._cache.clever_auth_url += '&state='+encodeURIComponent(JSON.stringify(_clever_state));

            this.cdr.detectChanges();
          } else {
            this.interface._cache.selected_district = '';
            this.cdr.detectChanges();
          }
        },
      });
    }
  }
  actionEnterFirstName(event): void {
    this.interface.user.firstName = event.target.value;
  }
  actionEnterLastName(event): void {
    this.interface.user.lastName = event.target.value;
  }
  actionEnterLogin(event): void {
    this.interface.user.email = event.target.value.toLowerCase();
    this.interface._cache.error_fields = this.interface._cache.error_fields.filter((e) => e !== 'email');
    if(this.interface.user.role != '62ebc2e38e33b7bc52c853c6') {
      this.actionGrantEmail();
    }
  }

  actionEnterPhone(event): void {
    this.interface.user.phone = event.target.value.toLowerCase();
    this.interface._cache.error_fields = this.interface._cache.error_fields.filter((e) => e !== 'phone');
  }
  actionEnterPassword(event): void {
    this.interface.user.password = event.target.value.replace(/[^a-zA-Z0-9!@#$%^&*()]/gi, '',);
  }
  actionGeneratePassword(): void {
    this.interface.user.password = Math.random().toString(36).slice(-8);
  }
  actionConfirmAccepted(event): void {
    this.interface._cache.accepted = event.target.checked;
  }

  actionPasteEmail(event): boolean {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData?.getData('text');
    if(pastedText) {
      this.interface.user.email = pastedText.toLowerCase();
      if(this.interface.user.role != '62ebc2e38e33b7bc52c853c6') {
        this.actionGrantEmail();
      }
    }
   return false;
  }

  actionGrantEmail(): boolean {
    let _grant = true;
    const _email_rules = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;
    const _domains = [
      'gmail.com',
      'amazon.com',
      'protonmail.com',
      'protonmail.ch',
      'outlook.com',
      'hotmail.com',
      'zohomail.eu',
      'icloud.com',
      'me.com',
      'hubspot.com',
      'yahoo.com',
      'msn.com',
      'yandex.com',
      'aol.com',
      'mail.com',
      'tutanota.com'
    ];
    const _user_email_domain = this.interface.user.email.split('@').pop();

    if (!_email_rules.test(this.interface.user.email) || _domains.includes(_user_email_domain)) {
      this.interface._cache.error_fields.push('email');
      _grant = false;
    }
    return _grant;
  }

  actionGrantRegister(): boolean {
    let _grant = true;

    if(this.interface.user.firstName.length < 3) {
      _grant = false;
    }
    if(this.interface.user.lastName.length < 3) {
      _grant = false;
    }
    if(this.interface.user.password.length == 0) {
      _grant = false;
    }
    if(!this.interface._cache.accepted) {
      _grant = false;
    }
    if(this.interface.user.email.length < 6) {
      _grant = false;
    }
    if(this.interface.user.phone.length < 9) {
      //_grant = false;
    }
    if(this.interface._cache.accepted && this.interface.user.email.length > 5 && this.interface.user.role != '62ebc2e38e33b7bc52c853c6') {
      _grant = this.actionGrantEmail();
    }
    return _grant;
  }

  actionRegister(): void {
    this.interface.submit = true;
    //this.interface.user.phone = '+1' +this.interface.user.phone;
    this.interface.user.phone = '+10000000000';
    this.userService.registerUser(this.interface.user).subscribe({
      next: (_next) => {
        this.interface.submit = false;
        //this.router.navigate(['/']).then();
        this.store.dispatch(new AuthLogin(this.interface.user.email, this.interface.user.password));
        this.cdr.detectChanges();
      },
      error: (_error: HttpErrorResponse) => {
        this.interface.submit = false;
        if (_error.status === 401) {
          this.store.dispatch(new ModalOpenAction('not_whitelisted', false));
          this.cdr.detectChanges();
        }
        else  if (_error.status === 422) {
          _error.error.message.forEach((_message) => {
            if(_message.includes('already')) {
              this.store.dispatch(new ModalOpenAction('already_register', false));
            }
            else {
              this.store.dispatch(new ModalOpenAction('exceeded_the_limit', false));
            }
          });


          this.cdr.detectChanges();
        }
        else {
          _error.error.message.forEach((_message) => {
            if(_message.includes('login')) {
              this.interface._cache.error_fields.push('email');
            }
          });
        }
        this.cdr.detectChanges();
      },
    });
  }
  actionToggleClever(): void {
    this.interface.clever = !this.interface.clever;
    this.interface._cache.selected_school = '';
    this.interface.user.school = '';
  }
}
