import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { HttpClient } from '@angular/common/http';
import { CategoriesState } from '../../_stores/categories/_state/categories.state';
import { ModalCloseAction } from '../../_stores/modal/_actions/modal.actions';
import { API } from '../../_stores/api';
import { UiControlCategoryEditModel } from '../../_models/ui-control-category-edit.model';
import { EditCategory } from '../../_stores/categories/_actions/categories.actions';

@Component({
  selector: 'ui-control-category-edit',
  templateUrl: './ui-control-category-edit.component.html',
  styleUrls: ['./ui-control-category-edit.component.scss'],
})
export class UiControlCategoryEditComponent implements OnInit {
  public interface: UiControlCategoryEditModel = {
    _cache: {
      category_type_selected: {
        _id: '',
        description: '',
        order: 0,
        slug: '',
        thumbnail: '',
        title: '',
      },
      edit_category: null,
      open_select_category: false,
      open_select_category_type: false,
      open_select_material: false,
      parent_category: null,
      validate_form_fields: {
        categoryDescription: '',
        categorySlug: '',
        categoryThumbnail: '',
        categoryTitle: '',
        category_material_selected: { title: '' },
      },
    },
    _cache_error: { fields: '   ' },
    category_types: [{ _id: '', description: '', order: 0, slug: '', thumbnail: '', title: '' }],
    edit_category: undefined,
    loading: false,
    materials: [{ _id: '', title: '' }],
  };

  constructor(private store: Store, private cdr: ChangeDetectorRef, private http: HttpClient) {}

  ngOnInit(): void {
    this.store.select(CategoriesState.selectEditCategoryByModalId).subscribe((category) => {
      this.interface = { ...this.interface, edit_category: category };
      this.interface._cache = { ...this.interface._cache, edit_category: category };
      this.interface._cache.edit_category = { ...this.interface._cache.edit_category, options: category?.options };
      this.interface._cache.edit_category = { ...this.interface._cache.edit_category, type: category.type };
      this.interface._cache.edit_category.options = {
        ...this.interface._cache.edit_category.options,
        visible: category.options.visible,
      };
      this.cdr.detectChanges();
    });
    this.initTypesOfCategory();
    this.initMaterialsOfCategory();
  }

  actionClose() {
    this.store.dispatch(new ModalCloseAction());
  }

  actionEnterCategoryDescription(event) {
    this.interface._cache.validate_form_fields.categoryDescription = event.target.value;
    this.actionValidateFields();
    this.cdr.detectChanges();
  }

  actionValidateFields() {
    this.interface._cache_error.fields = '';
    Object.keys(this.interface._cache.validate_form_fields).map((item) => {
      if (!!this.interface._cache.validate_form_fields[item]) {
      } else {
        this.interface._cache_error.fields = this.interface._cache_error.fields.concat(item.toString());
      }
    });
    this.cdr.detectChanges();
  }

  actionEditCategory() {
    this.store.dispatch(new EditCategory({ data: this.interface._cache.edit_category }));
    this.cdr.detectChanges();
  }

  actionEnterCategoryTitle(event) {
    this.interface._cache.edit_category.title = event.target.value;
    this.interface._cache.edit_category.slug = this.interface._cache.edit_category.title
      .toLowerCase()
      .replace(/ /g, '_');
    this.cdr.detectChanges();
  }

  actionEnterCategorySlug(event) {
    this.interface._cache.edit_category.slug = event.target.value.replace(/ /g, '_');
    this.cdr.detectChanges();
  }

  actionToggleSelectCategory() {
    this.interface._cache = {
      ...this.interface._cache,
      open_select_category: !this.interface._cache.open_select_category,
    };
  }

  actionSelectCategoryType(category_type) {
    this.interface._cache = {
      ...this.interface._cache,
      open_select_category: !this.interface._cache.open_select_category,
    };
    this.interface._cache.edit_category = { ...this.interface._cache.edit_category, type: category_type };
    this.cdr.detectChanges();
  }

  actionToggleSelectCategoryMaterial() {
    this.interface._cache = {
      ...this.interface._cache,
      open_select_material: !this.interface._cache.open_select_material,
    };
  }

  actionSelectCategoryMaterial(_category_material_selected) {
    this.interface._cache = {
      ...this.interface._cache,
      open_select_material: !this.interface._cache.open_select_material,
    };
    this.interface._cache.edit_category = {
      ...this.interface._cache.edit_category,
      materials: _category_material_selected,
    };
    this.cdr.detectChanges();
  }

  actionEnterCategoryThumbnail(event) {
    this.interface._cache.edit_category = {
      ...this.interface._cache.edit_category,
      thumbnail: event.target.value,
    };
    this.cdr.detectChanges();
  }

  actionSelectVisible(event) {
    this.interface._cache.edit_category.options.visible = !this.interface._cache.edit_category.options.visible;
  }

  private initTypesOfCategory() {
    this.http
      .get<[{ _id: ''; title: ''; description: ''; order: 0; slug: ''; thumbnail: '' }]>(API.url('/types'))
      .subscribe({
        next: (_types) => {
          this.interface = {
            ...this.interface,
            category_types: _types,
          };
          let find = _types.find((value) => this.interface._cache.edit_category.type.toString() === value._id);
          if (find) {
            this.interface._cache = {
              ...this.interface._cache,
              category_type_selected: find,
            };
          }
          this.cdr.detectChanges();
        },
        error: (err) => {
          //console.log('err: ', err);
        },
        complete: () => {},
      });
  }

  private initMaterialsOfCategory() {
    this.http.get<{ _id: ''; title: '' }[]>(API.url('/material')).subscribe({
      next: (_materials) => {
        this.interface = {
          ...this.interface,
          materials: _materials.filter((type) => this.filteredMaterialTypes(type)),
        };
      },
      error: (err) => {
        //console.log('err: ', err);
      },
      complete: () => {},
    });
  }

  private filteredMaterialTypes(type: { _id: ''; title: '' }) {
    return (
      type.title &&
      (type.title === 'category' || type.title === 'question' || type.title === 'worksheet' || type.title === 'video')
    );
  }
}
