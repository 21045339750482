<div class="ui-ai-opener" (click)="actionOpenChat()" *ngIf="interface.init_chat">
  <svg width="256" height="256" viewBox="0 0 256 256" fill="none" xmlns="http://www.w3.org/2000/svg" class="w-14 h-14 animate-zoom-in">
    <g clip-path="url(#clip0_1_51)">
      <path d="M151.825 50.4494C146.061 58.3666 139.258 66.1282 139.258 66.1282L162.674 73.4853L157.134 51.2461C156.449 48.6444 153.468 48.2025 151.825 50.4494Z" fill="#EC9823"/>
      <path d="M188.255 307.348C230.213 278.318 175.533 220.159 171.207 207.014C164.267 185.907 182.653 129.609 182.653 129.609L143.005 115.517V109.692L127.444 112.605L111.884 109.692V115.517L74.4825 130.779C74.4825 130.779 94.1386 187.955 83.7691 207.014C77.2336 219.02 26.0643 276.862 65.2146 306.253C89.0472 306.925 105.759 299.611 123.816 300.028C159.792 300.881 187.048 309.016 188.255 307.348Z" fill="#FFD74D"/>
      <path d="M129.94 303.626C166.196 303.141 212.76 319.566 225.899 299.723C227.549 297.234 226.833 291.495 223.914 290.673C220.366 289.678 215.281 291.595 212.828 293.288C208.31 296.4 201.811 304.08 193.272 305.114C181.103 306.595 159.306 297.564 156.169 278.972C150.312 244.216 172.607 236.716 151.688 170.963C150.132 166.071 137.465 169.295 132.262 169.388C118.631 169.625 121.917 169.494 108.498 167.147C107.365 166.948 107.328 166.102 106.855 167.147C81.2731 223.682 97.6864 224.591 100.381 275.237C100.655 280.335 97.064 293.854 91.207 298.616C84.1923 304.317 74.4016 306.24 65.3827 304.784C58.2124 303.626 47.4382 292.304 44.5938 290.549C38.2015 286.609 33.72 289.765 31.6412 292.665C28.0373 296.642 34.4545 305.699 41.9236 308.817C55.1563 314.325 105.137 303.091 129.965 303.626" fill="#EC9823"/>
      <path d="M193.483 135.136C192.351 112.25 184.95 76.5849 158.441 70.0682L141.051 65.7859C114.604 59.2816 87.8459 87.7761 78.105 106.642C78.105 106.642 40.5293 174.697 119.95 189.461C200.784 204.48 193.483 135.136 193.483 135.136Z" fill="#FFD74D"/>
      <path d="M123.275 103.517C127.475 97.5439 127.152 90.0815 122.554 86.8489C117.956 83.6163 110.825 85.8378 106.626 91.8107C102.426 97.7835 102.749 105.246 107.347 108.479C111.945 111.711 119.076 109.49 123.275 103.517Z" fill="#7B5A2B"/>
      <path d="M126.197 97.6449C127.327 93.0551 125.893 88.7566 122.996 88.0438C120.098 87.3311 116.834 90.474 115.705 95.0638C114.576 99.6536 116.009 103.952 118.907 104.665C121.804 105.378 125.068 102.235 126.197 97.6449Z" fill="white"/>
      <path d="M112.556 107.631C114.546 107.631 116.16 106.018 116.16 104.028C116.16 102.037 114.546 100.424 112.556 100.424C110.566 100.424 108.952 102.037 108.952 104.028C108.952 106.018 110.566 107.631 112.556 107.631Z" fill="white"/>
      <path d="M166.362 123.009C171.935 122.278 175.683 115.817 174.734 108.578C173.785 101.338 168.498 96.0619 162.925 96.7926C157.352 97.5233 153.604 103.984 154.553 111.224C155.503 118.463 160.79 123.739 166.362 123.009Z" fill="#7B5A2B"/>
      <path d="M170.943 115.449C173.836 114.72 175.246 110.413 174.091 105.83C172.936 101.246 169.655 98.1216 166.761 98.8506C163.868 99.5795 162.459 103.886 163.613 108.469C164.768 113.053 168.049 116.177 170.943 115.449Z" fill="white"/>
      <path d="M163.004 119.457C164.994 119.457 166.607 117.844 166.607 115.854C166.607 113.863 164.994 112.25 163.004 112.25C161.013 112.25 159.4 113.863 159.4 115.854C159.4 117.844 161.013 119.457 163.004 119.457Z" fill="white"/>
      <path d="M179.902 177.722C177.444 180.492 173.622 183.393 171.842 185.602C170.41 187.389 170.485 191.385 171.636 194.042C172.788 196.7 176.093 200.335 177.91 202.588C172.502 200.721 166.439 196.364 164.323 191.055C163.333 188.565 162.879 185.646 164.118 183.262C165.182 181.208 167.286 179.938 169.302 178.799C172.315 177.1 175.396 175.444 178.745 174.573" fill="#FFD74D"/>
      <path d="M77.8748 167.869C80.5014 171.355 83.8936 176.614 85.1073 179.185C85.7545 180.662 86.0954 182.254 86.1093 183.867C86.1232 185.479 85.8098 187.077 85.1882 188.565C84.0305 191.223 80.7317 194.858 78.9142 197.111C84.323 195.244 90.3854 190.887 92.4955 185.577C93.4851 183.088 93.9395 180.169 92.7071 177.785C91.6427 175.731 89.5327 174.461 87.5223 173.322C84.5098 171.623 87.292 165.853 83.9434 164.988" fill="#FFD74D"/>
      <path d="M181.278 99.4839C181.126 99.4847 180.976 99.444 180.846 99.3661C180.715 99.2882 180.609 99.1761 180.537 99.042C180.245 98.5129 179.915 97.9029 179.61 97.2494C176.329 90.7264 171.363 80.8672 156.81 88.0749C156.635 88.1581 156.436 88.1784 156.247 88.1324C156.058 88.0863 155.891 87.9767 155.774 87.822C155.656 87.6672 155.595 87.4767 155.602 87.2825C155.608 87.0883 155.681 86.9021 155.808 86.7553C161.846 79.9647 166.95 79.6784 170.174 80.6245C176.815 82.5913 180.954 91.0252 182.106 98.5129C182.138 98.7089 182.099 98.9099 181.997 99.0799C181.894 99.25 181.734 99.3777 181.545 99.4403C181.459 99.4699 181.369 99.4846 181.278 99.4839Z" fill="#7B5A2B"/>
      <path d="M133.202 81.9067C133.074 81.9063 132.947 81.8769 132.832 81.8209C132.717 81.7649 132.615 81.6836 132.536 81.583C122.577 68.7549 113.82 75.4584 108.019 79.89C107.44 80.3319 106.898 80.749 106.407 81.1349C106.245 81.2479 106.049 81.3008 105.852 81.2846C105.655 81.2683 105.471 81.1839 105.33 81.0458C105.189 80.9078 105.1 80.7248 105.08 80.5285C105.059 80.3323 105.108 80.135 105.218 79.9709C109.525 73.7467 116.926 67.9582 123.766 69.0848C127.083 69.6325 131.546 72.1284 134.017 80.8672C134.07 81.0562 134.055 81.2577 133.976 81.437C133.896 81.6164 133.757 81.7626 133.582 81.8507C133.461 81.8995 133.331 81.9187 133.202 81.9067Z" fill="#7B5A2B"/>
      <path d="M69.6898 179.745C59.8369 171.878 53.5068 159.467 48.1478 147.068C46.7909 143.912 45.3593 140.576 42.8074 138.665C38.6061 138.665 41.6373 150.883 38.7305 155.938C37.5853 157.929 36.1911 159.815 35.6184 162.118C34.4483 166.761 36.9753 171.672 40.2119 174.729C43.4485 177.785 47.4133 179.658 50.7308 182.596C54.6459 186.069 55.6231 189.941 58.1315 194.876C60.6398 199.812 59.3016 202.588 67.2064 210.81C70.8102 214.545 80.4453 217.601 84.4724 214.302C94.4871 206.092 86.5264 183.181 86.5264 183.181C83.408 192.878 75.2356 184.177 69.6898 179.745Z" fill="#FFD74D"/>
      <path d="M52.5857 181.494C52.6655 181.794 52.6853 182.107 52.644 182.415C52.6026 182.722 52.501 183.019 52.3449 183.287C52.1888 183.555 51.9812 183.79 51.7342 183.978C51.4871 184.166 51.2054 184.303 50.9051 184.382L48.6893 184.967C48.3893 185.047 48.0766 185.067 47.7689 185.026C47.4612 184.984 47.1648 184.883 46.8965 184.727C46.6282 184.57 46.3933 184.363 46.2054 184.116C46.0174 183.869 45.8801 183.587 45.8013 183.287L28 104.04L52.5857 181.494Z" fill="#E84E1B"/>
      <path d="M45.1789 180.934L45.8013 183.274C45.8802 183.575 46.0175 183.856 46.2054 184.103C46.3933 184.35 46.6282 184.558 46.8965 184.714C47.1648 184.87 47.4613 184.972 47.7689 185.013C48.0766 185.055 48.3894 185.035 48.6893 184.955L50.9052 184.37C51.2054 184.291 51.4871 184.154 51.7342 183.966C51.9813 183.778 52.1888 183.543 52.3449 183.275C52.501 183.006 52.6027 182.71 52.644 182.402C52.6853 182.095 52.6655 181.782 52.5857 181.482L51.9633 179.141L45.1789 180.934Z" fill="#35A8E0"/>
      <path d="M53.2546 177.29L46.4443 179.116L46.8343 180.571L53.6446 178.746L53.2546 177.29Z" fill="white"/>
      <path d="M150.482 186.39L92.0519 224.204L137.256 294.052L195.686 256.238L150.482 186.39Z" fill="#0971B7"/>
      <g style="mix-blend-mode:overlay" opacity="0.4">
        <path d="M102.068 222.456L149.111 192.013L191.162 254.405L146.696 198.082L102.068 222.456Z" fill="white"/>
      </g>
      <path d="M195.687 256.222L137.26 294.041L92.0535 224.192L140.714 285.781L195.687 256.222Z" fill="#0C6493"/>
      <path d="M91.207 229.041C91.0292 229.042 90.856 228.985 90.7134 228.879C90.5707 228.773 90.4663 228.623 90.4157 228.453C90.3652 228.282 90.3714 228.1 90.4333 227.934C90.4951 227.767 90.6094 227.625 90.7589 227.529L97.2632 223.321C97.4465 223.204 97.6687 223.165 97.881 223.212C98.0934 223.258 98.2785 223.387 98.396 223.57C98.4545 223.66 98.4945 223.761 98.5137 223.867C98.5328 223.972 98.5308 224.081 98.5076 224.185C98.4844 224.29 98.4406 224.39 98.3787 224.477C98.3168 224.565 98.238 224.64 98.147 224.697L91.6489 228.904C91.5188 228.993 91.3647 229.041 91.207 229.041Z" fill="#444443"/>
      <path d="M95.0287 234.942C94.8914 234.943 94.7561 234.909 94.6354 234.844C94.5146 234.778 94.4122 234.684 94.3378 234.568C94.2204 234.386 94.1801 234.164 94.2256 233.952C94.2711 233.74 94.3987 233.554 94.5806 233.435L101.085 229.228C101.175 229.17 101.276 229.13 101.382 229.111C101.487 229.092 101.596 229.094 101.701 229.117C101.806 229.14 101.905 229.183 101.993 229.244C102.081 229.305 102.157 229.383 102.215 229.474C102.273 229.564 102.312 229.665 102.331 229.771C102.35 229.876 102.348 229.985 102.326 230.089C102.303 230.194 102.26 230.294 102.198 230.382C102.137 230.47 102.059 230.545 101.969 230.603L95.452 234.811C95.3254 234.892 95.1791 234.937 95.0287 234.942Z" fill="#444443"/>
      <path d="M98.8503 240.848C98.6744 240.847 98.5034 240.789 98.3627 240.684C98.2221 240.578 98.119 240.43 98.0688 240.261C98.0187 240.093 98.024 239.912 98.084 239.747C98.144 239.581 98.2555 239.439 98.4022 239.342L104.907 235.128C105.089 235.026 105.304 234.998 105.507 235.049C105.71 235.1 105.886 235.227 105.999 235.403C106.112 235.579 106.153 235.792 106.114 235.998C106.076 236.204 105.96 236.387 105.79 236.51L99.2923 240.718C99.1603 240.802 99.0071 240.848 98.8503 240.848Z" fill="#444443"/>
      <path d="M102.666 246.749C102.49 246.746 102.32 246.688 102.181 246.582C102.041 246.475 101.939 246.327 101.89 246.159C101.84 245.99 101.846 245.811 101.906 245.646C101.966 245.481 102.078 245.34 102.224 245.243L108.722 241.035C108.904 240.936 109.116 240.91 109.317 240.961C109.518 241.012 109.692 241.137 109.804 241.311C109.917 241.485 109.959 241.694 109.924 241.898C109.888 242.102 109.777 242.285 109.612 242.411L103.114 246.618C102.98 246.704 102.825 246.749 102.666 246.749Z" fill="#444443"/>
      <path d="M106.487 252.656C106.312 252.653 106.142 252.595 106.002 252.488C105.863 252.382 105.761 252.234 105.711 252.066C105.662 251.897 105.668 251.717 105.728 251.553C105.788 251.388 105.899 251.247 106.046 251.15L112.544 246.942C112.634 246.873 112.737 246.824 112.847 246.797C112.957 246.771 113.071 246.767 113.183 246.786C113.294 246.806 113.4 246.848 113.495 246.911C113.589 246.973 113.67 247.054 113.731 247.149C113.793 247.244 113.834 247.351 113.852 247.463C113.87 247.574 113.865 247.689 113.838 247.798C113.81 247.908 113.759 248.011 113.69 248.1C113.62 248.189 113.533 248.263 113.434 248.318L106.936 252.525C106.802 252.61 106.646 252.656 106.487 252.656Z" fill="#444443"/>
      <path d="M110.309 258.556C110.134 258.554 109.964 258.495 109.824 258.389C109.684 258.283 109.582 258.135 109.533 257.966C109.484 257.798 109.489 257.618 109.549 257.453C109.61 257.288 109.721 257.147 109.867 257.05L116.365 252.843C116.547 252.744 116.76 252.717 116.96 252.768C117.161 252.819 117.335 252.944 117.448 253.118C117.56 253.292 117.603 253.502 117.567 253.706C117.531 253.91 117.42 254.093 117.255 254.218L110.757 258.426C110.624 258.513 110.468 258.559 110.309 258.556Z" fill="#444443"/>
      <g opacity="0.39">
        <path d="M110.253 227.305L144.381 207.81L151.632 218.018L116.054 238.116L110.253 227.305Z" fill="white"/>
      </g>
      <path d="M198.077 220.775C198.786 219.288 198.948 216.225 198.214 214.041C193.16 199.034 178.919 195.443 171.251 192.256C160.85 187.899 163.52 193.7 164.796 200.347C164.796 200.466 168.263 205.713 168.275 205.825C169.95 217.234 162.306 215.784 153.63 223.57C149.098 227.641 145.283 232.819 144.617 239.548C143.584 250.004 163.999 256.913 163.196 252.93V252.836C163.051 252.156 163.044 251.454 163.173 250.771C163.303 250.087 163.568 249.437 163.952 248.857C164.337 248.278 164.833 247.781 165.411 247.395C165.99 247.01 166.64 246.743 167.323 246.612C169.346 246.264 170.964 245.486 170.952 244.122C170.952 242.946 163.974 239.859 171.257 235.956C182.386 230.043 193.676 230.043 198.077 220.775Z" fill="#FFD74D"/>
      <path d="M159.873 170.216C162.101 168.405 180.481 155.9 173.902 145.655C172.147 142.91 167.404 143.053 164.771 145.033C163.654 145.953 162.724 147.079 162.032 148.35C160.29 151.258 158.978 154.403 158.136 157.687C157.395 160.593 157.028 163.6 156.057 166.444C155.086 169.289 153.399 172.046 150.711 173.565C159.885 170.527 167.012 162.361 168.562 153.13" fill="#F29892"/>
      <path d="M160.651 170.994C167.298 165.753 179.56 156.958 175.763 146.931C173.896 142.058 167.958 141.211 163.993 144.261C154.993 151.189 159.337 166.432 150.15 172.612C149.179 173.235 149.758 175.102 151.003 174.623C160.532 171.168 167.634 163.419 169.626 153.423C169.9 152.041 167.759 151.45 167.503 152.838C166.618 157.321 164.575 161.494 161.578 164.943C158.581 168.392 154.733 170.998 150.418 172.5L151.264 174.517C156.518 170.982 157.489 165.566 158.79 159.734C159.723 155.639 161.055 151.17 163.769 147.852C167.261 143.526 174.12 143.757 174.244 150.286C174.406 158.546 164.908 164.9 159.126 169.438C158.012 170.316 159.58 171.865 160.682 170.994H160.651Z" fill="#D44A41"/>
      <path d="M121.307 176.16C131.528 177.673 151.706 181.725 167.192 166.606C170.877 163.008 177.929 155.483 175.047 152.633C174.693 152.29 174.219 151.967 174.101 152.234C171.269 158.763 166.109 168.218 149.509 172.625C119.739 180.523 102.79 168.952 102.79 168.952C102.79 168.952 107.49 174.112 121.307 176.16Z" fill="#B37014"/>
    </g>
    <defs>
      <clipPath id="clip0_1_51">
        <rect width="256" height="256" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</div>
<div class="ui-ai-chat" [class.ui-ai-chat-open]="interface.chat_open">
  <div class="ui-ai-chat-header">
    <img src="assets/images/diana.svg" loading="lazy">
    <p>
      <small>Beta</small>
      <span><b>Argosha</b> - Personal assistant</span>
    </p>
  </div>
  <div class="ui-ai-chat-body w-full">
      <div class="bh-ai-chat-messages" [class.bh-ai-chat-messages-empty]="interface.messages.length == 0">
        <ng-container *ngFor="let message of interface.messages; let m = index">
          <div class="bh-ai-chat-message" id="bhaimsg">
            <div class="bh-ai-chat-message-question" *ngIf="message.show">
              <div class="bh-ai-chat-message-question-text">{{message.question}}</div>
              <div class="bh-ai-chat-message-question-avatar">
                <img src="{{interface.user?.avatar}}" alt="{{interface.user?.displayName}}" loading="lazy">
              </div>
            </div>
            <div class="bh-ai-chat-message-answer">
              <div class="bh-ai-chat-message-answer-avatar">
                <img src="./assets/images/diana.svg"  alt="support"/>
              </div>
              <div class="bh-ai-chat-message-answer-text">
                <ng-container *ngIf="!message.answer">
                  <svg class="animate-spin mx-auto h-5 w-5 text-stone-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                </ng-container>
                <ng-container *ngIf="message.answer">{{message.answer}}</ng-container>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="interface.messages.length == 0">
          <div class="bh-ai-chat-message-waiting">
            <img src="./assets/images/logo_icon.svg"  alt="support"/>
            <span>Meet <b>Argosha</b>, your personal assistant from the ArgoPrep. Simplify tasks, manage schedules, and boost productivity with AI-driven efficiency. Experience the future today.</span>
          </div>
        </ng-container>
      </div>
  </div>
  <div class="ui-ai-chat-footer">
    <div class="bh-form-input-group w-full">
      <div class="bh-form-text bh-form-text-xs" *ngIf="interface.user?.email == 'teacher@argoprep.com'">Your Question to Argosha</div>
      <div class="bh-form-textarea bh-form-textarea-sm" *ngIf="interface.user?.email == 'teacher@argoprep.com'">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
        </svg>
        <textarea id="question" name="question" placeholder="Messages" [disabled]="interface.user?.email != 'teacher@argoprep.com'" [value]="interface._cache.message || ''" (keydown)="preventEnter($event)" (keyup)="actionEnterMessages($event)">{{interface._cache.message || '' }}</textarea>
      </div>
      <div class="bh-form-text bh-form-text-xs bh-form-text-error">
        <ng-container *ngIf="interface.user?.email != 'teacher@argoprep.com'">
          Apologies, but you are not currently part of the initial beta testing phase. We'll be opening up access to this fantastic assistant for all our users very soon.
        </ng-container>
        &nbsp;
      </div>
    </div>
    <div class="flex flex-wrap gap-2 mt-2">
      <button class="ui-btn ui-btn-error"  (click)="actionOpenChat()">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
        <span>Close</span>
      </button>
      <button class="ui-btn ui-btn-pap" [disabled]="interface._cache.message?.length == 0" (click)="actionSubmitQuestion()">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M15.042 21.672L13.684 16.6m0 0l-2.51 2.225.569-9.47 5.227 7.917-3.286-.672zM12 2.25V4.5m5.834.166l-1.591 1.591M20.25 10.5H18M7.757 14.743l-1.59 1.59M6 10.5H3.75m4.007-4.243l-1.59-1.59" />
        </svg>
        <span>Send question</span>
      </button>
    </div>
  </div>
</div>
