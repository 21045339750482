import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from "@angular/router";
import { UiTooltipModule } from "../ui-tooltip/ui-tooltip.module";
import { UiAppNoDataModule } from "../ui-app-no-data/ui-app-no-data.module";
import { PipesModule } from "../../app.pipes.module";
import { UiPageWorksheetGeneratorsComponent } from "./ui-page-worksheet-generators.component";



@NgModule({
  declarations: [UiPageWorksheetGeneratorsComponent],
  exports:[UiPageWorksheetGeneratorsComponent],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: "",
        component: UiPageWorksheetGeneratorsComponent,
        children: [
          {
            path: "**",
            component: UiPageWorksheetGeneratorsComponent
          }
        ]
      }
    ]),
    UiTooltipModule,
    UiAppNoDataModule,
    PipesModule
  ]
})
export class UiPageWorksheetGeneratorsModule { }
