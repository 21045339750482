import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngxs/store';
import { AssignmentsState } from '../../_stores/asignments/_state/assignments.state';
import { AssignmentModel } from '../../_models/assignment.model';
import { API } from '../../_stores/api';
import { HttpClient } from '@angular/common/http';
import { UserModel } from '../../_models/user.model';
import { QuestionModel } from '../../_models/question.model';
import { Router } from '@angular/router';

@Component({
  selector: 'ui-page-assignment',
  templateUrl: './ui-page-assignment.component.html',
  styleUrls: ['./ui-page-assignment.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UiPageAssignmentComponent implements OnInit {
  interface: {
    assignment: AssignmentModel;
    _cache: {
      selected_material: any;
      result: {
        show: boolean;
        student: UserModel;
        worksheet: {
          currentPage: string;
          materialData: { image: string }[];
        };
        book: {
          currentPage: string;
          materialData: { image: string }[];
        };
        quiz: {
          questions: QuestionModel[];
          current: QuestionModel;
          progress: any;
          time: number;
        };
      };
    };
  } = {
    _cache: {
      result: {
        book: { currentPage: '', materialData: [] },
        quiz: { current: undefined, progress: undefined, questions: [], time: 0 },
        show: false,
        student: undefined,
        worksheet: { currentPage: '', materialData: [] },
      },
      selected_material: undefined,
    },
    assignment: undefined,
  };

  constructor(private store: Store, private cdr: ChangeDetectorRef, private http: HttpClient, private router: Router) {}

  ngOnInit(): void {
    this.initAssignment();
  }

  initAssignment(): void {
    this.store.select(AssignmentsState.selectAssignmentsByRouteId).subscribe((_assignment) => {
      if (_assignment) {
        this.interface.assignment = { ..._assignment };
        this.initAssignmentMaterials();
        this.cdr.detectChanges();
      }
    });
  }

  initAssignmentMaterials(): void {
    this.interface._cache.selected_material = undefined;
    this.http
      .get<AssignmentModel>(API.url('assignments/' + this.interface.assignment._id + '/creator'))
      .subscribe((_assignment) => {
        this.interface.assignment = { ..._assignment };
        this.normalizeMaterials();
        this.cdr.detectChanges();
      });
  }

  normalizeMaterials(): void {
    this.interface.assignment.materials = this.interface.assignment.materials.reduce((materials, material) => {
      let _material: any = { ...material };
      _material.progress = {
        done: this.interface.assignment.students.reduce((_done: number, _student) => {
          let _track = _student.prepTracker.find((_track) => _track.material == _material._id);
          if (_track && _track.done) {
            _done++;
          }
          return _done;
        }, 0),
        total: this.interface.assignment.students.length,
      };
      materials.push(_material);
      return materials;
    }, []);
    this.actionSelectAssignmentMaterial(this.interface.assignment.materials[0]);
  }

  actionEditAssignment(id: string): void {
    this.router.navigate(['assignments/builder/' + id]);
  }

  actionSelectAssignmentMaterial(_material): void {
    this.interface._cache.selected_material = _material;
  }

  actionShowResult(student): void {
    if (this.interface._cache.selected_material.type == 'Video') {
      return;
    }
    this.interface._cache.result.show = true;
    this.interface._cache.result.student = student;
    if (this.interface._cache.selected_material.type == 'Worksheet') {
      let _result_worksheet = this.interface._cache.result.student.prepTracker.find(
        (_tracker) => _tracker.done && _tracker.material == this.interface._cache.selected_material._id,
      );
      this.interface._cache.result.worksheet.materialData = _result_worksheet.materialData;
      this.interface._cache.result.worksheet.currentPage = this.interface._cache.result.worksheet.materialData[0].image;
    }
    if (this.interface._cache.selected_material.type == 'Book') {
      let _result_book = this.interface._cache.result.student.prepTracker.find(
        (_tracker) => _tracker.done && _tracker.material == this.interface._cache.selected_material._id,
      );
      this.interface._cache.result.book.materialData = _result_book.materialData;
      this.interface._cache.result.book.currentPage = _result_book.materialData[0].image;
    }
    if (this.interface._cache.selected_material.type == 'Category') {
      let _result_quiz = this.interface._cache.result.student.prepTracker.find(
        (_tracker) => _tracker.done && _tracker.material == this.interface._cache.selected_material._id,
      );
      var date1 = new Date(_result_quiz.createdAt);
      var date2 = new Date(_result_quiz.updatedAt);
      var Difference_In_Time = (date2.getTime() - date1.getTime()) / 1000;


      this.interface._cache.result.quiz.progress = _result_quiz.materialData;
      this.interface._cache.result.quiz.time = _result_quiz.timeSpend == 0? Difference_In_Time: _result_quiz.timeSpend;

      this.http
        .get<QuestionModel[]>(API.url('question/parent/' + this.interface._cache.selected_material._id))
        .subscribe((_questions) => {
          this.interface._cache.result.quiz.questions = _questions;
          this.cdr.detectChanges();
        });
    }
  }

  actionHideResult(): void {
    this.interface._cache.result.show = false;
    this.interface._cache.result.student = undefined;
    this.interface._cache.result.worksheet = undefined;
    this.interface._cache.result.quiz.questions = [];
    this.interface._cache.result.quiz.progress = [];
  }

  checkDoneStatus(student): boolean {
    return student.prepTracker.find(
      (_tracker) => _tracker.done && _tracker.material == this.interface._cache.selected_material._id,
    );
  }

  checkNotStartedStatus(student): boolean {
    return !student.prepTracker.find((_tracker) => _tracker.material == this.interface._cache.selected_material._id);
  }

  checkStartedStatus(student): boolean {
    return student.prepTracker.find(
      (_tracker) => !_tracker.done && _tracker.material == this.interface._cache.selected_material._id,
    );
  }

  checkQuestionStatus(question): any {
    return this.interface._cache.result.quiz.progress.find((_question) => _question.id == question._id);
  }

  actionNextImage() {
    let index = this.getIndex();
    if (this.interface._cache.selected_material.type == 'Worksheet') {
      this.interface._cache.result.worksheet.currentPage =
        this.interface._cache.result.worksheet.materialData[index + 1].image;
      this.cdr.detectChanges();
    }
    if (this.interface._cache.selected_material.type == 'Book') {
      this.interface._cache.result.book.currentPage = this.interface._cache.result.book.materialData[index + 1].image;
      this.cdr.detectChanges();
    }
  }

  actionPrevImage() {
    let index = this.getIndex();
    if (this.interface._cache.selected_material.type == 'Worksheet') {
      this.interface._cache.result.worksheet.currentPage =
        this.interface._cache.result.worksheet.materialData[index - 1].image;
      this.cdr.detectChanges();
    }
    if (this.interface._cache.selected_material.type == 'Book') {
      this.interface._cache.result.book.currentPage = this.interface._cache.result.book.materialData[index - 1].image;
      this.cdr.detectChanges();
    }

    this.cdr.detectChanges();
  }

  getIndex(): number {
    if (this.interface._cache.selected_material.type == 'Worksheet') {
      return this.interface._cache.result.worksheet.materialData.findIndex(
        (value) => value.image === this.interface._cache.result.worksheet.currentPage,
      );
    }
    if (this.interface._cache.selected_material.type == 'Book') {
      return this.interface._cache.result.book.materialData.findIndex(
        (value) => value.image === this.interface._cache.result.book.currentPage,
      );
    }
  }
}
