import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { HttpClient } from '@angular/common/http';
import { ModalState } from '../../_stores/modal/_state/modal.state';
import { ModalCloseAction } from '../../_stores/modal/_actions/modal.actions';
import { DeleteCategory } from '../../_stores/categories/_actions/categories.actions';

@Component({
  selector: 'ui-control-category-delete',
  templateUrl: './ui-control-category-delete.component.html',
  styleUrls: ['./ui-control-category-delete.component.scss'],
})
export class UiControlCategoryDeleteComponent implements OnInit {
  interface = {
    _id: '',
    loading: false,
  };

  constructor(private store: Store, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.store.select(ModalState.selectOpenedID).subscribe((_openedID) => {
      this.interface._id = _openedID;
      this.cdr.detectChanges();
    });
  }

  categoryDelete() {
    this.interface.loading = true;
    this.store.dispatch(new DeleteCategory({ categoryID: this.interface._id })).subscribe({
      next: () => {
        this.store.dispatch(new ModalCloseAction());
        this.interface.loading = false;
        this.cdr.detectChanges();
      },
    });
    this.cdr.detectChanges();
  }

  actionClose() {
    this.store.dispatch(new ModalCloseAction());
  }
}
