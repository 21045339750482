import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UiHomeworksModel } from '../../_models/ui-homeworks.model';
import { Store } from '@ngxs/store';
import { AssignmentsState } from '../../_stores/asignments/_state/assignments.state';
import { GetHomeworks } from '../../_stores/asignments/_actions/asignments.actions';

@Component({
  selector: 'ui-homeworks',
  templateUrl: './ui-homeworks.component.html',
  styleUrls: ['./ui-homeworks.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UiHomeworksComponent implements OnInit {
  interface: UiHomeworksModel = {
    homeworks: [],
    loading: false,
  };

  constructor(private store: Store, private cdr: ChangeDetectorRef) {
    this.store.dispatch(new GetHomeworks());
  }

  ngOnInit(): void {
    this.initAssignments();
  }

  initAssignments(): void {
    this.store.select(AssignmentsState.selectHomeworkList).subscribe({
      next: (res) => {
        this.interface.homeworks = res;
        this.cdr.detectChanges();
      },
    });
  }
}
