import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { PagePracticesModel } from '../../_models/page-practices.model';
import { Store } from '@ngxs/store';
import { UserState } from '../../_stores/user/_state/user.state';
import { PracticeState } from '../../_stores/practice/_state/practice.state';

@Component({
  selector: 'ui-page-practices',
  templateUrl: './ui-page-practices.component.html',
  styleUrls: ['./ui-page-practices.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UiPagePracticesComponent implements OnInit {
  interface: PagePracticesModel = {
    _cache: {},
    grades: [],
    categories: [],
    user: undefined,
  };

  constructor(private store: Store, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.initGrades();
    this.initCategories();
    this.initUser();
  }

  initGrades(): void {
    this.store.select(PracticeState.selectGrades).subscribe((_grades) => {
      this.interface.grades = _grades;
      this.cdr.detectChanges();
    });
  }

  initCategories(): void {
    this.store.select(PracticeState.selectCategories).subscribe((_categories) => {
      this.interface.categories = _categories;
      this.cdr.detectChanges();
    });
  }

  initUser(): void {
    this.store.select(UserState.selectUser).subscribe((_user) => {
      this.interface.user = _user;
      this.cdr.detectChanges();
    });
  }

  getCurrentUserGrade(): string {
    const _grade = this.interface.grades?.find((grade) => grade._id === this.interface.user?.practice);
    return _grade ? _grade.title : '';
  }
}
