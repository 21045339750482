import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngxs/store';
import { PracticeState } from '../../_stores/practice/_state/practice.state';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'ui-page-practices-tree',
  templateUrl: './ui-page-practices-tree.component.html',
  styleUrls: ['./ui-page-practices-tree.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UiPagePracticesTreeComponent implements OnInit, OnDestroy {
  private destroy: Subject<boolean> = new Subject<boolean>();
  interface = {
    tree: [],
    isChangeGrade: false,
  };

  constructor(private store: Store, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.initCategoryTree();
  }

  ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.unsubscribe();
  }

  initCategoryTree(): void {
    this.store
      .select(PracticeState.selectCategoryTree)
      .pipe(takeUntil(this.destroy))
      .subscribe((_tree) => {
        this.interface.tree = _tree;
        this.cdr.detectChanges();
      });
  }

  initChangeGrade(event) {
    this.interface.isChangeGrade = event;
    this.cdr.detectChanges();
  }
}
