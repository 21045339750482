import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ui-page-worksheet-generators',
  templateUrl: './ui-page-worksheet-generators.component.html',
  styleUrls: ['./ui-page-worksheet-generators.component.scss']
})
export class UiPageWorksheetGeneratorsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
