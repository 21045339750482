import { ChangeDetectorRef, Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { ChartComponent, } from 'ng-apexcharts';
import { Store } from "@ngxs/store";
import { HttpClient } from "@angular/common/http";
import { LoadControlDashboardData, LoadControlRoles } from "../../_stores/control/_actions/control.actions";
import { ControlState } from "../../_stores/control/_state/control.state";
import { formatDate } from "@angular/common";
import { RoleModel } from "../../_models/role.model";



@Component({
  selector: 'ui-control-dashboard',
  templateUrl: './ui-control-dashboard.component.html',
  styleUrls: ['./ui-control-dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UiControlDashboardComponent implements OnInit {
  @ViewChild("chart") chart: ChartComponent;
  interface: {
    loading: boolean;
    statistic: any;
    charts: any;
    roles: RoleModel[]
  } = {
    loading: false,
    statistic: undefined,
    charts: {
      loading: true,
      today: Date.now(),
      start_date: new Date(),
      end_date: new Date(),
      users_register: {
        chart: {
          height: 400,
          type: 'bar',
          stacked: true,
          fontFamily: 'Montserrat, sans-serif',
          toolbar: {
            show: false,
            offsetY: -30,
            tools: {
              download: false,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
            },
            autoSelected: 'pan',
          },
        },
        colors: ['#727EEB'],
        tooltip: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
          width: [0.5, 0.5, 3],
        },
        plotOptions: {
          bar: {
            borderRadius: 12,
            dataLabels: {
              position: 'center',
            },
          },
        },
        legend: {
          show: true,
          horizontalAlign: 'center',
          offsetX: 40,
          showForSingleSeries: true,
          markers: {
            width: 20,
            height: 20,
            radius: 6,
            offsetX: -3,
          },
        },
        series: [
          {
            name: '',
            type: 'area',
            data: [],
          },
        ],
        dataLabels: {
          enabled: true,
          enabledOnSeries: [0, 1, 2],
          style: {
            fontFamily: 'Montserrat, sans-serif',
            fontWeight: 200,
          }
        },
        xaxis: {
          categories: [],
          labels: {
            rotateAlways: true,
          },
        },
        yaxis: [
          {
            forceNiceScale: true,
            axisTicks: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
            title: {
              text: 'User per day',
              style: {

              },
            },
          },
        ],
      },
      users_register_by_roles: {
        chart: {
          height: 400,
          type: "bar",
          stacked: false,
          fontFamily: 'Montserrat, sans-serif',
          toolbar: {
            show: false,
            offsetY: -30,
            tools: {
              download: false,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
            },
            autoSelected: 'pan'
          }
        },
        colors: ['#9EA7F2', '#FEC688', '#94a3b8', '#fb7185'],
        tooltip: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 4,
          colors: ['transparent']
        },
        plotOptions: {
          bar: {
            borderRadius: 7,
            endingShape: 'rounded',
            dataLabels: {
              position: 'center'
            }
          }
        },
        legend: {
          show: true,
          horizontalAlign: "center",
          offsetX: 40,
          showForSingleSeries: true,
          markers: {
            width: 20,
            height: 20,
            radius: 6,
            offsetX: -3
          }
        },
        series: [
          {
            name: "",
            type: "column",
            data: []
          },
          {
            name: "",
            type: "column",
            data: []
          },
          {
            name: "",
            type: "column",
            data: []
          },
          {
            name: "",
            type: "column",
            data: []
          }
        ],
        dataLabels: {
          enabled: true,
          style: {
            fontFamily: 'Montserrat, sans-serif',
            fontWeight: 200
          },
          background: {
            borderRadius: 5
          }
        },
        xaxis: {
          categories: [],
        },
        yaxis: [
          {
            forceNiceScale: true,
            axisTicks: {
              show: true
            },
            axisBorder: {
              show: false,
            },
            title: {
              text: "",
              style: {
              }
            }
          }
        ]
      },
    },
    roles: []
  };

  constructor(private store: Store, private cdr: ChangeDetectorRef, private http: HttpClient) {
    this.store.dispatch(new LoadControlRoles());
    this.store.dispatch(new LoadControlDashboardData());
  }

  ngOnInit(): void {
    this.initRoles();
    this.initStatistic();
  }
  initRoles(): void {
    this.store.select(ControlState.selectRoles).subscribe((_data) => {
      this.interface.roles = _data;
    });
  }

  initStatistic(): void {
    this.interface.loading = true;
    this.store.select(ControlState.selectDashboard).subscribe({
      next: (res) => {
        if(!res) {
          return;
        }
        this.interface.statistic = res;
        this.initChartUserRegisterByRoles();
      },
      error: (err) => {
        this.interface.loading = false;
        this.cdr.detectChanges();
      },
    })
  }

  initChartUserRegisterByRoles(): void {
    let _data = this.interface.statistic.user.register.reduce((data, current) => {
      const _date = formatDate(new Date(current.createdAt),'MM-dd-yyyy', 'en')
      if(!data[_date]) {
        data[_date] = [current];
      } else {
        data[_date].push(current);
      }
      return data
    }, []);
    this.interface.charts.users_register_by_roles.series = this.interface.roles.reduce((data, role) => {
      let _serie = {
        name: role.name,
        data: Object.values(_data).reduce((data, current: any[]) => {
          let _by_role = current.filter((_user) => _user.role?.name == role?.name);
          // @ts-ignore
          data.push(_by_role.length);
          return data;
        }, [])
      };
      data.push(_serie);
      return data
    }, []);
    this.interface.charts.users_register_by_roles.xaxis.categories  = Object.keys(_data);
    this.interface.loading = false;
    this.cdr.detectChanges();
  }

}
