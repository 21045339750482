export const environment = {
  production: true,
  storeDevtools: false,
  hmr: false,
  socket_uri: 'wss://schools.argoprep.com/',
  clever: {
    url: 'https://schools.argoprep.com',
    client_id: 'ce47e032960cc2648d13',
    auth_url: 'https://clever.com/oauth/authorize?response_type=code',
    redirect_url: 'https://schools.argoprep.com/api/auth/clever/redirect',
  },
};

