import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Router } from '@angular/router';
import { AssignmentModel } from '../../_models/assignment.model';

@Component({
  selector: 'ui-homework',
  templateUrl: './ui-homework.component.html',
  styleUrls: ['./ui-homework.component.scss'],
})
export class UiHomeworkComponent implements OnInit {
  @Input('homework') set _homework(_homework) {
    if (_homework) {
      this.interface.homework = _homework;
    }
  }

  interface: {
    homework: AssignmentModel;
  } = {
    homework: undefined,
  };

  constructor(private store: Store, private cdr: ChangeDetectorRef, private router: Router) {}

  ngOnInit(): void {}

  actionOpenHomeworkPage(): void {
    this.router.navigate(['homeworks/' + this.interface.homework._id]).then();
  }

  getTotalProgress(): number {
    let _progress = 0;
    let _done_materials = this.interface.homework.materials.filter((_material) => _material.isDone).length;
    let _all_materials = this.interface.homework.materials.length;
    _progress = Math.floor((_done_materials / _all_materials) * 100);
    return _progress;
  }

  getTotalDone(): number {
    return this.interface.homework.materials.filter((_material) => _material.isDone).length;
  }

  isCompleted() {
    return this.interface.homework.options.isActive && this.interface.homework.options.isDone;
  }

  isScheduled() {
    let now = new Date();
    let _scheduleDate = new Date(this.interface?.homework?.dates?.scheduleDate);
    return (
      this.interface?.homework?.options?.isActive &&
      this.interface?.homework?.dates?.scheduleDate !== null &&
      _scheduleDate > now
    );
  }
}
