import {
  Action,
  NgxsAfterBootstrap,
  NgxsOnChanges,
  NgxsOnInit,
  NgxsSimpleChange,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import { Injectable } from '@angular/core';
import { AuthModel } from '../../../_models/auth.model';
import { AuthError, AuthLogin, AuthLogout, AuthStatus, AuthSuccess, DetectAuthToken } from "../_actions/auth.actions";
import { AuthService } from '../_services/auth.service';
import {Router} from "@angular/router";
import {ClearUsers, CurrentUsers} from "../../user/_actions/user.actions";
import { ClearClasses } from "../../classes/_actions/classes.actions";
import { ClearAssignments } from "../../asignments/_actions/asignments.actions";
import { DisconnectWebSocket } from "@ngxs/websocket-plugin";


export interface AuthStateModel {
  status: boolean;
  token: string;
  error: {
    error: string
    message: string
    statusCode: number
  };
}

@State<AuthStateModel>({
  name: 'SAP_AUTH',
  defaults: {
    status: false,
    token: undefined,
    error: undefined
  }
})
@Injectable()
export class AuthState implements NgxsOnInit, NgxsOnChanges, NgxsAfterBootstrap {

  constructor(private store: Store, private authService: AuthService, private router: Router) {}

  @Selector()
  static selectAuth(state: AuthStateModel) {
    return state;
  }

  @Selector()
  static selectAuthStatus(state: AuthStateModel) {
    return state.status;
  }

  @Selector()
  static selectAuthToken(state: AuthStateModel) {
    return state.token;
  }

  ngxsAfterBootstrap(ctx?: StateContext<AuthStateModel>): void {
    const _state = ctx.getState();
    ctx.patchState({
      ..._state,
      error: undefined,
    });
  }

  ngxsOnInit(ctx?: StateContext<AuthStateModel>): void {}

  ngxsOnChanges(change: NgxsSimpleChange<AuthStateModel>): void {}

  @Action(AuthStatus)
  authStatus(ctx: StateContext<AuthStateModel>) {
    this.authService.status().subscribe({
      next: _next => {
        ctx.dispatch(new CurrentUsers());
      },
      error: _error => {
        ctx.dispatch(new AuthError(_error.error));
      }
    });
  }

  @Action(AuthLogin)
  authLogin(ctx: StateContext<AuthStateModel>, payload) {
    this.authService.login(payload.login, payload.password).subscribe({
      next: _next => {
        ctx.dispatch(new AuthSuccess(_next.token));
      },
      error: _error => {
        ctx.dispatch(new AuthError(_error.error));
      }
    });
  }

  @Action(AuthLogout)
  authLogout(ctx: StateContext<AuthStateModel>) {
    this.authService.logout().subscribe({
      next: _next => {},
      error: _error => {},
    });
    ctx.patchState({
      status: false,
      token: undefined,
      error: undefined,
    });
    ctx.dispatch(new ClearUsers());
    ctx.dispatch(new ClearClasses());
    ctx.dispatch(new ClearAssignments());
    ctx.dispatch(new DisconnectWebSocket())
    this.router.navigate(['/auth']).then();
  }

  @Action(AuthSuccess)
  authSuccess(ctx: StateContext<AuthStateModel>, payload) {
    ctx.patchState({
      status: true,
      token: payload.token,
      error: undefined,
    });
    this.router.navigate(['/']).then();
  }

  @Action(AuthError)
  authError(ctx: StateContext<AuthStateModel>, payload) {
    ctx.patchState({
      status: false,
      token: undefined,
      error: payload.error,
    });
  }


  @Action(DetectAuthToken)
  detectAuthToken(ctx: StateContext<AuthStateModel>) {
    this.authService.token().subscribe({
      next: _next => {
        ctx.dispatch(new AuthSuccess(_next.token));
      },
      error: _error => {
        ctx.dispatch(new AuthError(_error.error));
      }
    });
  }

}
