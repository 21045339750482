import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiControlQuestionDeleteComponent } from './ui-control-question-delete.component';

@NgModule({
  declarations: [UiControlQuestionDeleteComponent],
  exports: [UiControlQuestionDeleteComponent],
  imports: [CommonModule],
})
export class UiControlQuestionDeleteModule {}
