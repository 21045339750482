import { ChangeDetectorRef, Component, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngxs/store';
import { ControlState } from '../../_stores/control/_state/control.state';
import { ModalOpenAction, ModalOpenActionWithID } from '../../_stores/modal/_actions/modal.actions';
import { LoadControlVideos } from '../../_stores/control/_actions/control.actions';
import { ActivatedRoute } from '@angular/router';
import { API } from '../../_stores/api';
import { HttpClient } from '@angular/common/http';
import { VideoModel } from '../../_models/video.model';
import { CategoriesBreadCrumbModel } from '../../_models/categories-bread-crumb-model';
import { GetVideo, GetVideoListByParent } from '../../_stores/videos/_actions/videos.actions';
import { VideosState } from '../../_stores/videos/_state/videos.state';

export interface UiControlVideosModal {
  category_parent: string;
  bread_crumbs: CategoriesBreadCrumbModel[];
  pages: any[];
  currentPage: number;
  data: VideoModel[];
  perPage: number;
  totalData: number;
  totalPage: number;
}

@Component({
  selector: 'ui-control-videos',
  templateUrl: './ui-control-videos.component.html',
  styleUrls: ['./ui-control-videos.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UiControlVideosComponent implements OnInit, OnChanges {
  interface: UiControlVideosModal = {
    bread_crumbs: [],
    category_parent: '',
    currentPage: 0,
    data: [],
    pages: [],
    perPage: 0,
    totalData: 0,
    totalPage: 0,
  };

  constructor(
    private store: Store,
    private http: HttpClient,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
  ) {
    this.store.dispatch(new LoadControlVideos(1));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes && changes.currentPage.currentValue) || (changes.total && changes.total.currentValue)) {
      this.interface = {
        ...this.interface,
        pages: this.getPages(this.interface.currentPage, this.interface.totalPage),
      };
      this.initData();
    }
  }

  ngOnInit(): void {
    this.initData();
  }

  initData(): void {
    this.interface.category_parent = this.route.snapshot.paramMap.get('category');
    if (this.interface.category_parent && this.interface.category_parent.length > 0) {
      this.store.dispatch(new GetVideoListByParent({ parentId: this.interface.category_parent }));
      this.store.select(VideosState.selectVideoList).subscribe({
        next: (_data) => {
          if (_data && _data.length > 0) {
            this.interface.data = _data;
            this.getBreadCrumbs();
            this.cdr.detectChanges();
          }
        },
      });
    } else {
      this.store.select(ControlState.selectVideos).subscribe((_data) => {
        this.interface = {
          ...this.interface,
          currentPage: _data.currentPage,
          data: _data.data,
          perPage: _data.perPage,
          totalData: _data.totalData,
          totalPage: _data.totalPage,
        };
        this.interface = {
          ...this.interface,
          pages: this.getPages(this.interface.currentPage, this.interface.totalPage),
        };
        this.cdr.detectChanges();
      });
    }
  }

  private getBreadCrumbs() {
    this.http
      .get<CategoriesBreadCrumbModel[]>(API.url('categories/bread_crumbs/' + this.interface.category_parent))
      .subscribe({
        next: (_bread_crumbs) => {
          if (_bread_crumbs && _bread_crumbs.length > 0) {
            this.interface = { ...this.interface, bread_crumbs: _bread_crumbs };
            this.cdr.detectChanges();
          }
        },
      });
  }

  actionOpenAddDataModal(): void {
    this.store.dispatch(new ModalOpenActionWithID('control_video_add', this.interface.category_parent, true));
  }

  actionOpenEditDataItemModal(_id): void {
    this.store.dispatch(new GetVideo({ videoID: _id }));
    this.store.dispatch(new ModalOpenActionWithID('control_video_edit', _id, false));
  }

  actionOpenDeleteDataItemModal(_id): void {
    this.store.dispatch(new ModalOpenActionWithID('control_video_delete', _id));
  }

  actionSearchVideos(event) {
    if (event.target.value && event.target.value.length > 0) {
      this.interface.data = this.interface.data.filter((item) => {
        return item.title.toLowerCase().includes(event.target.value.toLowerCase());
      });
    } else {
      this.initData();
    }
  }

  actionNextPage(event): void {
    let element = document.querySelector('thead');
    element.scrollIntoView({ block: 'center', behavior: 'smooth' });
    this.interface = {
      ...this.interface,
      currentPage: event,
    };
    this.interface.currentPage = event;
    this.store.dispatch(new LoadControlVideos(this.interface.currentPage));
    this.cdr.detectChanges();
  }

  actionLastPage(): void {
    this.store.dispatch(new LoadControlVideos(this.interface.totalPage));
  }

  private getPages(currentPage: number, totalPage: number) {
    if (currentPage <= 10) {
      if (totalPage < 10) {
        return [...Array(totalPage).keys()].map((x) => ++x);
      }
      return [...Array(10).keys()].map((x) => ++x);
    }
    if (currentPage > 10) {
      if (currentPage >= totalPage - 2) {
        return [totalPage - 3, totalPage - 2, totalPage - 1, totalPage];
      } else {
        return [currentPage - 1, currentPage, currentPage + 1];
      }
    }
  }

  getCategoryByParent(_id: '') {}

  actionOpenVideo(url) {
    this.store.dispatch(new ModalOpenAction('video_16_9', true, url));
  }
}
