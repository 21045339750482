import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import {Store} from "@ngxs/store";
import {LoadVideos} from "../../_stores/practice/_actions/practice.actions";
import {RouterState} from "@ngxs/router-plugin";
import {PageVideosModel} from "../../_models/page-video.model";
import {PracticeState} from "../../_stores/practice/_state/practice.state";
import {
  EventPracticeVideoCloseLectures,
  EventPracticeVideoOpenLectures,
  EventWorksheetOpened
} from "../../_stores/event_ws/_actions/events.actions";
import { take } from "rxjs";
import { RouterSelectors } from "../../_stores/routing/router.selectors";

@Component({
  selector: 'ui-page-video',
  templateUrl: './ui-page-video.component.html',
  styleUrls: ['./ui-page-video.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UiPageVideoComponent implements OnInit, OnDestroy {

  interface: PageVideosModel = {
    videos: [],
    _id:null,
    _cache: {
      active_video: 0,
      player: null,
      send_event: false
    }
  }


  constructor(private store: Store, private cdr: ChangeDetectorRef) {}

  ngOnDestroy(): void {
    this.store.dispatch(new EventPracticeVideoCloseLectures({ categoryId: this.interface._id }));
    }

  ngOnInit(): void {
    this.loadCategoryVideos();
    this.initVideos();
  }
   loadCategoryVideos(): void {
     this.store.select(RouterSelectors.params).pipe(take(1)).subscribe((_params) => {
       this.interface._id = _params.id;
       this.store.dispatch(new EventPracticeVideoOpenLectures({ categoryId: this.interface._id }));
     });
   }


  initVideos(): void {
    //
    this.store.select(PracticeState.selectVideos).subscribe((_videos) => {
      this.interface.videos = _videos;
      if(!this.interface._cache.send_event && _videos.length > 0) {

        this.interface._cache.send_event = true;
      }
      this.cdr.detectChanges();
    });
  }





}
