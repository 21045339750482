import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class WebSocketService {
  private socket_url: string = 'wss://ai.argoprep.com/queue/join';
  private socket: WebSocket;
  private session_hash: string;
  private session_index: number;
  private session_question_index: number;
  private answer: string;
  private answerSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public answer$: Observable<string> = this.answerSubject.asObservable();



  constructor() { }

  _session_hash() {
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    let randomString = '';

    for (let i = 0; i < 11; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomString += characters.charAt(randomIndex);
    }

    return randomString;
  }


  initSession(): void {
    this.session_hash = this._session_hash();
    this.session_index = 11;
    this.session_question_index = 0;
  }
  initAI(): void {
    this.socket = new WebSocket(this.socket_url);
    this.socket.onopen = (event) => {};
    this.socket.onmessage = (event) => {
      const _event_data = JSON.parse(event.data);
      if(_event_data.msg == 'send_hash'){
        this.socket.send(JSON.stringify({
            fn_index:this.session_index,
            session_hash:this.session_hash
          }));
      }
      if(_event_data.msg == 'send_data'){
        this.socket.send(JSON.stringify({
          fn_index:this.session_index,
          data: [{}],
          event_data: null,
          session_hash:this.session_hash
        }));
      }
    };
    this.socket.onclose = (event) => {};
    this.socket.onerror = (event) => {};
  }
  initAIQuestion(question:string): void {
    this.socket = new WebSocket(this.socket_url);
    this.socket.onopen = (event) => {};
    this.socket.onmessage = (event) => {
      const _event_data = JSON.parse(event.data);
      if(_event_data.msg == 'send_hash'){
        this.socket.send(JSON.stringify({
          fn_index:this.session_index-2,
          session_hash:this.session_hash
        }));
      }
      if(_event_data.msg == 'send_data'){
        this.socket.send(JSON.stringify({
          fn_index:this.session_index-2,
          data: [null, question],
          event_data: null,
          session_hash:this.session_hash
        }));
      }
    };
    this.socket.onclose = (event) => {
      this.initAIAnswer();
    };
    this.socket.onerror = (event) => {};
  }
  initAIAnswer(): void {
    this.socket = new WebSocket(this.socket_url);
    this.socket.onopen = (event) => {};
    this.socket.onmessage = (event) => {
      const _event_data = JSON.parse(event.data);
      if(_event_data.msg == 'send_hash'){
        this.socket.send(JSON.stringify({
          fn_index:this.session_index-1,
          session_hash:this.session_hash
        }));
      }
      if(_event_data.msg == 'send_data'){
        this.socket.send(JSON.stringify({
          fn_index:this.session_index-1,
          data: [null, "eachadea/vicuna-7b-1.1", 0.7, 1, 512],
          event_data: null,
          session_hash:this.session_hash
        }));
      }
      if(_event_data.msg == 'process_generating') {
        const regex = /<(?!\/?(code|pre)\b)[^<>]*>/gi;
        const _ai_answer = _event_data.output.data[1][this.session_question_index][1];
        this.answer = _ai_answer?.replace(regex, '');
        this.answerSubject.next(this.answer);
      }
      if(_event_data.msg == 'process_completed') {
        this.session_question_index ++;
      }
    };
    this.socket.onclose = (event) => {};
    this.socket.onerror = (event) => {};
  }

  initLocalSocket(): void {

  }


}
