import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UiClassesModel } from '../../_models/ui-classes.model';
import { Store } from '@ngxs/store';
import { ClassesState } from '../../_stores/classes/_state/classes.state';
import { ModalOpenAction } from '../../_stores/modal/_actions/modal.actions';

@Component({
  selector: 'ui-classes',
  templateUrl: './ui-classes.component.html',
  styleUrls: ['./ui-classes.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UiClassesComponent implements OnInit {
  interface: UiClassesModel = {
    loading: true,
    classes: [],
  };

  constructor(private store: Store, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.initClasses();
  }

  initClasses(): void {
    this.store.select(ClassesState.selectClassList).subscribe((_classes) => {
      this.interface.classes = _classes.filter((_classes) => !_classes.options.isArchive);
      this.interface.loading = false;
      this.cdr.detectChanges();
    });
  }

  actionOpenAddClassModal(): void {
    this.store.dispatch(new ModalOpenAction('class-add'));
  }

}
