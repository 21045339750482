import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiAwardsComponent } from "./ui-awards.component";
import { UiTooltipModule } from "../ui-tooltip/ui-tooltip.module";



@NgModule({
  declarations: [UiAwardsComponent],
  exports: [UiAwardsComponent],
  imports: [
    CommonModule,
    UiTooltipModule
  ]
})
export class UiAwardsModule { }
