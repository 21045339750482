import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiAiComponent } from "./ui-ai.component";



@NgModule({
  declarations: [UiAiComponent],
  exports: [UiAiComponent],
  imports: [
    CommonModule
  ]
})
export class UiAiModule { }
