import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { RoleModel } from '../../_models/role.model';
import { Store } from '@ngxs/store';
import { HttpClient } from '@angular/common/http';
import {
  ClearControlDashboardSchoolData,
  LoadControlDashboardSchoolData,
  LoadControlRoles,
} from '../../_stores/control/_actions/control.actions';
import { ControlState } from '../../_stores/control/_state/control.state';
import { formatDate } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { ModalOpenActionWithID } from '../../_stores/modal/_actions/modal.actions';
import { ClassModel } from '../../_models/class.model';
import { UserModel } from '../../_models/user.model';
import { GradeModel } from '../../_models/grade.model';
import { CategoryModel } from '../../_models/category.model';
import { API } from '../../_stores/api';
import { PracticeState } from '../../_stores/practice/_state/practice.state';

@Component({
  selector: 'ui-control-school',
  templateUrl: './ui-control-school.component.html',
  styleUrls: ['./ui-control-school.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UiControlSchoolComponent implements OnInit, OnDestroy {
  interface: {
    _id: any;
    loading: boolean;
    statistic: any;
    charts: any;
    license: any;
    roles: RoleModel[];
    reports: {
      loading: boolean;
      type: string;
      classes: ClassModel[];
      class: ClassModel;
      students: UserModel[];
      student: UserModel;
      grades: GradeModel[];
      period: string;
      report: {
        short: {
          quiz: number;
          video: number;
          worksheet: number;
          book_pages: number;
        };
        trackers: any;
        assignments: {
          total: number;
          complete: number;
          incomplete: number;
        };
        worksheets: {
          total: number;
          print: number;
          download: number;
        };
        books: {
          total: number;
          print: number;
          download: number;
        };
        session: {
          time: string;
        };
        goings: [];
        activity: [];
        practice: {
          questions: {
            total: number;
            complete: number;
            started: number;
            incomplete: number;
          };
          drills: {
            total: number;
            complete: number;
            started: number;
            incomplete: number;
          };
          video_lectures: {
            total: number;
            complete: number;
            started: number;
            incomplete: number;
          };
          worksheets: {
            total: number;
            complete: number;
            started: number;
            incomplete: number;
          };
        };
      };
      report_practice: any;
      _cache: {
        open_select_student: boolean;
        categories: CategoryModel[];
        category_selected: any;
        grade_selected: GradeModel;
        grade_report_practice_selected: any;
        period: {
          start: Date;
          end: Date;
        };
      };
    };
  } = {
    _id: null,
    loading: false,
    statistic: undefined,
    charts: {
      loading: true,
      today: Date.now(),
      start_date: new Date(),
      end_date: new Date(),
      users_register: {
        chart: {
          height: 400,
          type: 'bar',
          stacked: true,
          fontFamily: 'Montserrat, sans-serif',
          toolbar: {
            show: false,
            offsetY: -30,
            tools: {
              download: false,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
            },
            autoSelected: 'pan',
          },
        },
        colors: ['#727EEB'],
        tooltip: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
          width: [0.5, 0.5, 3],
        },
        plotOptions: {
          bar: {
            borderRadius: 12,
            dataLabels: {
              position: 'center',
            },
          },
        },
        legend: {
          show: true,
          horizontalAlign: 'center',
          offsetX: 40,
          showForSingleSeries: true,
          markers: {
            width: 20,
            height: 20,
            radius: 6,
            offsetX: -3,
          },
        },
        series: [
          {
            name: '',
            type: 'area',
            data: [],
          },
        ],
        dataLabels: {
          enabled: true,
          enabledOnSeries: [0, 1, 2],
          style: {
            fontFamily: 'Montserrat, sans-serif',
            fontWeight: 200,
          },
        },
        xaxis: {
          categories: [],
          labels: {
            rotateAlways: true,
          },
        },
        yaxis: [
          {
            forceNiceScale: true,
            axisTicks: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
            title: {
              text: 'User per day',
              style: {},
            },
          },
        ],
      },
      users_register_by_roles: {
        chart: {
          height: 400,
          type: 'bar',
          stacked: false,
          fontFamily: 'Montserrat, sans-serif',
          toolbar: {
            show: false,
            offsetY: -30,
            tools: {
              download: false,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
            },
            autoSelected: 'pan',
          },
        },
        colors: ['#9EA7F2', '#FEC688', '#94a3b8', '#fb7185'],
        tooltip: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 4,
          colors: ['transparent'],
        },
        plotOptions: {
          bar: {
            borderRadius: 7,
            endingShape: 'rounded',
            dataLabels: {
              position: 'center',
            },
          },
        },
        legend: {
          show: true,
          horizontalAlign: 'center',
          offsetX: 40,
          showForSingleSeries: true,
          markers: {
            width: 20,
            height: 20,
            radius: 6,
            offsetX: -3,
          },
        },
        series: [
          {
            name: '',
            type: 'column',
            data: [],
          },
          {
            name: '',
            type: 'column',
            data: [],
          },
          {
            name: '',
            type: 'column',
            data: [],
          },
          {
            name: '',
            type: 'column',
            data: [],
          },
        ],
        dataLabels: {
          enabled: true,
          style: {
            fontFamily: 'Montserrat, sans-serif',
            fontWeight: 200,
          },
          background: {
            borderRadius: 5,
          },
        },
        xaxis: {
          categories: [],
        },
        yaxis: [
          {
            forceNiceScale: true,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: false,
            },
            title: {
              text: '',
              style: {},
            },
          },
        ],
      },
    },
    license: undefined,
    roles: [],
    reports: {
      loading: false,
      type: 'practice',
      classes: [],
      class: null,
      students: [],
      student: undefined,
      grades: [],
      period: 'day',
      report: {
        short: {
          quiz: 0,
          video: 0,
          worksheet: 0,
          book_pages: 0,
        },
        trackers: {
          chart: {
            type: 'bar',
            height: 350,
            stacked: false,
            fontFamily: 'Montserrat, sans-serif',
            toolbar: {
              show: true,
              offsetY: -30,
              tools: {
                download: true,
                selection: true,
                zoom: true,
                zoomin: true,
                zoomout: true,
                pan: true,
                reset: true,
              },
              autoSelected: 'pan',
            },
          },
          series: [],
          colors: ['#60a5fa', '#fb7185', '#2dd4bf', '#fdba74'],
          legend: {
            show: true,
            horizontalAlign: 'center',
            offsetX: 40,
            showForSingleSeries: true,
            markers: {
              width: 20,
              height: 20,
              radius: 6,
              offsetX: -3,
            },
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '55%',
              endingShape: 'rounded',
              borderRadius: 4,
            },
          },
          dataLabels: {
            enabled: true,
          },
          stroke: {
            show: true,
            width: 1,
            colors: ['transparent'],
          },
          xaxis: {
            categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
          },
          yaxis: {
            show: true,
          },
          fill: {
            opacity: 1,
          },
          tooltip: {
            enabled: false,
          },
        },
        assignments: {
          total: 0,
          complete: 0,
          incomplete: 0,
        },
        worksheets: {
          total: 0,
          print: 0,
          download: 0,
        },
        books: {
          total: 0,
          print: 0,
          download: 0,
        },
        session: {
          time: '00:00',
        },
        goings: [],
        activity: [],
        practice: {
          questions: {
            total: 0,
            complete: 0,
            started: 0,
            incomplete: 0,
          },
          drills: {
            total: 0,
            complete: 0,
            started: 0,
            incomplete: 0,
          },
          video_lectures: {
            total: 0,
            complete: 0,
            started: 0,
            incomplete: 0,
          },
          worksheets: {
            total: 0,
            complete: 0,
            started: 0,
            incomplete: 0,
          },
        },
      },
      report_practice: undefined,
      _cache: {
        open_select_student: false,
        categories: [],
        category_selected: undefined,
        grade_selected: undefined,
        grade_report_practice_selected: undefined,
        period: {
          start: new Date(),
          end: new Date(),
        },
      },
    },
  };

  constructor(
    private store: Store,
    private cdr: ChangeDetectorRef,
    private http: HttpClient,
    private route: ActivatedRoute,
  ) {
    let _id = this.route.snapshot.paramMap.get('id');
    this.interface._id = _id;
    this.store.dispatch(new LoadControlDashboardSchoolData(_id));
    this.store.dispatch(new LoadControlRoles());
  }

  ngOnInit(): void {
    this.initRoles();
    this.initStatistic();
    this.initGrades();
    this.initReports();
  }

  ngOnDestroy(): void {
    this.store.dispatch(new ClearControlDashboardSchoolData());
  }

  initRoles(): void {
    this.store.select(ControlState.selectRoles).subscribe((_data) => {
      this.interface.roles = _data.filter((value) => value.name !== 'Administrator');
    });
  }

  initStatistic(): void {
    this.interface.loading = true;
    this.store.select(ControlState.selectSchool).subscribe({
      next: (res) => {
        if (!res) {
          return;
        }
        this.interface.statistic = res;
        //console.log('initStatistic', res); //sessionDuration

        /*



sessionEBook: {duration: 485}
sessionPracticeDrills: {duration: 0}
sessionPracticeQuestions: {duration: 0}
sessionUser: {duration: 267655}
sessionUserAverage: {duration: 0}
sessionVideo: {duration: 0}
sessionWorksheet: {duration: 0}




        * */

        this.interface.license = res.license.license[0];
        this.initChartUserRegisterByRoles();
        this.interface.loading = false;
        this.cdr.detectChanges();
      },
      error: (err) => {
        this.interface.loading = false;
        this.cdr.detectChanges();
      },
    });
  }

  initChartUserRegisterByRoles(): void {
    let _data = this.interface.statistic.user.register.reduce((data, current) => {
      const _date = formatDate(new Date(current.createdAt), 'MM-dd-yyyy', 'en');
      if (!data[_date]) {
        data[_date] = [current];
      } else {
        data[_date].push(current);
      }
      return data;
    }, []);

    this.interface.charts.users_register_by_roles.series = this.interface.roles.reduce((data, role) => {
      let _serie = {
        name: role.name,
        data: Object.values(_data).reduce((data, current: any[]) => {
          let _by_role = current.filter((_user) => _user.role.name == role.name);
          // @ts-ignore
          data.push(_by_role.length);
          return data;
        }, []),
      };
      data.push(_serie);
      return data;
    }, []);
    this.interface.charts.users_register_by_roles.xaxis.categories = Object.keys(_data);
  }

  actionOpenAddLicenseModal(): void {
    this.store.dispatch(new ModalOpenActionWithID('control_license_add', this.interface._id));
  }

  initGrades(): void {
    this.store.select(PracticeState.selectGrades).subscribe((_grades) => {
      this.interface.reports.grades = _grades;
      this.cdr.detectChanges();
    });
  }

  initReports(): void {
    this.interface.loading = true;
    this.interface.reports._cache.categories = [];
    this.interface.reports._cache.category_selected = undefined;
    this.interface.reports._cache.grade_report_practice_selected = undefined;
    let _params = '';
    let _date = this.interface.reports._cache.period.start;

    _params += '&school=' + this.interface._id;

    if (this.interface.reports.class) {
      _params = 'class=' + this.interface.reports.class._id;
    }

    if (this.interface.reports.period == 'day' || this.interface.reports.period == 'custom') {
      _params += '&beginDate=' + this.interface.reports._cache.period.start.getTime();
    }
    if (this.interface.reports.period == 'week') {
      _params += '&beginDate=' + (this.interface.reports._cache.period.end.getTime() - 86400000 * 7);
      _params += '&endDate=' + this.interface.reports._cache.period.end.getTime();
    }
    if (this.interface.reports.period == 'month') {
      _params += '&beginDate=' + (this.interface.reports._cache.period.end.getTime() - 86400000 * 30);
      _params += '&endDate=' + this.interface.reports._cache.period.end.getTime();
    }

    if (this.interface.reports.student) {
      _params += '&student=' + this.interface.reports.student._id;
    }

    //62f515fbd62293f5fddcac98
    //_params += '&grade=5e703269f73e83064765eb0f';

    this.http.get<any>(API.url('reports/school?' + _params)).subscribe((_report) => {
      this.initReport();
      this.interface.reports.report.short = _report.short;
      this.interface.reports.report.assignments = _report.assignments;
      this.interface.reports.report.books = _report.books;
      this.interface.reports.report.worksheets = _report.worksheets;
      this.interface.reports.report.activity = _report.activity;
      this.interface.reports.report.practice = _report.practice;
      this.interface.reports.students = _report.users;
      this.interface.reports.report.goings = _report.goings ? _report.goings : [];
      this.interface.reports.report.trackers.series = _report.trackers.series;
      this.interface.loading = false;
      this.http.get<any>(API.url('reports/school/practice?' + _params)).subscribe((_report) => {
        this.interface.reports.report_practice = _report;
        this.actionSelectGrade(this.interface.reports._cache.grade_selected);
        this.cdr.detectChanges();
      });
    });
  }

  initReport(): void {
    this.initTrackerChartDefault();
    this.initTrackerChart();
  }

  initTrackerChartDefault(): void {
    let _categories = [];
    let _series = [];

    if (this.interface.reports.period == 'day' || this.interface.reports.period == 'custom') {
      _series = [
        {
          name: 'Quiz',
          data: []
            .constructor(24)
            .fill(0)
            .map((_, i) => {
              return Math.floor(Math.random() * 10);
            }),
        },
        {
          name: 'Video',
          data: []
            .constructor(24)
            .fill(0)
            .map((_, i) => {
              return Math.floor(Math.random() * 10);
            }),
        },
        {
          name: 'Worksheet',
          data: []
            .constructor(24)
            .fill(0)
            .map((_, i) => {
              return Math.floor(Math.random() * 10);
            }),
        },
        {
          name: 'Book pages',
          data: []
            .constructor(24)
            .fill(0)
            .map((_, i) => {
              return Math.floor(Math.random() * 10);
            }),
        },
      ];
      _categories = []
        .constructor(24)
        .fill(0)
        .map((_, i) => {
          return ('0' + ~~i + ': 0' + 60 * (i % 1)).replace(/\d(\d\d)/g, '$1');
        });
    }
    if (this.interface.reports.period == 'week') {
      _series = [
        {
          name: 'Quiz',
          data: [].constructor(7).fill(0),
        },
        {
          name: 'Video',
          data: [].constructor(7).fill(0),
        },
        {
          name: 'Worksheet',
          data: [].constructor(7).fill(0),
        },
        {
          name: 'Book pages',
          data: [].constructor(7).fill(0),
        },
      ];
      _categories = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    }
    if (this.interface.reports.period == 'month') {
      const _count_days = 30;
      _series = [
        {
          name: 'Quiz',
          data: [].constructor(_count_days).fill(0),
        },
        {
          name: 'Video',
          data: [].constructor(_count_days).fill(0),
        },
        {
          name: 'Worksheet',
          data: [].constructor(_count_days).fill(0),
        },
        {
          name: 'Book pages',
          data: [].constructor(_count_days).fill(0),
        },
      ];
      _categories = []
        .constructor(_count_days)
        .fill(0)
        .map((_, i) => {
          return i + 1;
        });
    }

    this.interface.reports.report.trackers.series = _series;
    this.interface.reports.report.trackers.xaxis.categories = _categories;
  }

  initTrackerChart(): void {
    let _categories = [];
    let _series = [];

    if (this.interface.reports.period == 'day' || this.interface.reports.period == 'custom') {
      _series = [
        {
          name: 'Quiz',
          data: [].constructor(24).fill(0),
        },
        {
          name: 'Video',
          data: [].constructor(24).fill(0),
        },
        {
          name: 'Worksheet',
          data: [].constructor(24).fill(0),
        },
        {
          name: 'Book pages',
          data: [].constructor(24).fill(0),
        },
      ];
    }
    if (this.interface.reports.period == 'week') {
      _series = [
        {
          name: 'Quiz',
          data: [].constructor(7).fill(0),
        },
        {
          name: 'Video',
          data: [].constructor(7).fill(0),
        },
        {
          name: 'Worksheet',
          data: [].constructor(7).fill(0),
        },
        {
          name: 'Book pages',
          data: [].constructor(7).fill(0),
        },
      ];
    }
    if (this.interface.reports.period == 'month') {
      const _count_days = 30;
      _series = [
        {
          name: 'Quiz',
          data: [].constructor(_count_days).fill(0),
        },
        {
          name: 'Video',
          data: [].constructor(_count_days).fill(0),
        },
        {
          name: 'Worksheet',
          data: [].constructor(_count_days).fill(0),
        },
        {
          name: 'Book pages',
          data: [].constructor(_count_days).fill(0),
        },
      ];
    }
    //this.interface.report.trackers.series = _series;
  }

  getGradeMaterialTotal(_id): number {
    let grade = this.interface.reports.report_practice?.gradeMaterial?.find((_grade) => _grade.id == _id);
    return grade ? grade.data.totalMaterial : 0;
  }

  actionSelectPeriod(period): void {
    this.interface.reports.period = period;
    if (period == 'day') {
      this.interface.reports._cache.period.start = new Date();
      this.interface.reports._cache.period.end = new Date();
    }

    this.initReports();
  }

  actionSelectPeriodDate(event): void {
    this.interface.reports._cache.period.start = event;
    this.interface.reports._cache.period.end = event;
    this.actionSelectPeriod('custom');
  }

  actionSelectGrade(grade): void {
    if (!grade) {
      this.interface.reports._cache.grade_selected = this.interface.reports.grades[8];
    } else {
      this.interface.reports._cache.grade_selected = grade;
    }

    let _grade_index = this.interface.reports.report_practice?.gradeMaterial?.findIndex(
      (_grade) => _grade.id == this.interface.reports._cache.grade_selected.category,
    );
    //console.log(_grade_index);
    this.interface.reports._cache.grade_report_practice_selected =
      this.interface.reports.report_practice?.progressTree[_grade_index];
    this.actionSelectCategory(this.interface.reports._cache.grade_report_practice_selected[0]);

    // this.initGradeCategories();
  }

  actionSelectCategory(category): void {
    this.interface.reports._cache.category_selected = category;
  }

  actionSelectStudent(_student): void {
    if (this.interface.reports.loading) {
      return;
    }
    this.interface.reports.student = _student;
    this.actionSelectPeriod('day');
    this.interface.reports._cache.open_select_student = false;
    this.store.dispatch(new LoadControlDashboardSchoolData(this.interface._id, this.interface.reports.student._id));
  }

  actionToggleSelectStudent(): void {
    this.interface.reports._cache.open_select_student = !this.interface.reports._cache.open_select_student;
    this.cdr.detectChanges();
  }
}
