import {ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Store} from "@ngxs/store";
import {ModalState} from "../../_stores/modal/_state/modal.state";
import {ModalCloseAction} from "../../_stores/modal/_actions/modal.actions";
import { UiStudentEditModel } from "../../_models/ui-student-edit.model";
import { UserModel } from "../../_models/user.model";
import { GradeModel } from "../../_models/grade.model";
import { RoleModel } from "../../_models/role.model";
import { SchoolModel } from "../../_models/school.model";
import { ClassModel } from "../../_models/class.model";
import { HttpClient } from "@angular/common/http";
import { UserState } from "../../_stores/user/_state/user.state";
import { ClassesState } from "../../_stores/classes/_state/classes.state";
import { PracticeState } from "../../_stores/practice/_state/practice.state";
import { take } from "rxjs";
import { API } from "../../_stores/api";
import { CurrentUsers, GetTeachersStudents } from "../../_stores/user/_actions/user.actions";
import { ControlState } from "../../_stores/control/_state/control.state";
import { LoadControlRoles } from "../../_stores/control/_actions/control.actions";

@Component({
  selector: 'ui-control-user-edit',
  templateUrl: './ui-control-user-edit.component.html',
  styleUrls: ['./ui-control-user-edit.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UiControlUserEditComponent implements OnInit {
  interface:  {
    _id: null | string,
    loading: boolean,
    user: UserModel,
    roles: RoleModel[],
    schools: SchoolModel[],
    grades: GradeModel[],
    auth: {
      login: string,
      password?: string,
      verify: boolean
    },
    profile: {
      avatar: string,
      firstName: string,
      lastName: string,
      email: string,
      phone: string,
      verifyCode: number,
      practice: GradeModel,
      school: SchoolModel
    },
    _cache: any
  } = {
    _id: null,
    loading: false,
    user: undefined,
    roles: [],
    schools: [],
    grades: [],
    auth: {
      login: "",
      verify: false
    },
    profile: {
      avatar: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      verifyCode: null,
      practice: undefined,
      school: undefined,
    },
    _cache: {
      open_choose_grade: false,
      open_search_school: false,
      selected_school: '',
      selected_grade: '',
      accepted: false,
      error_fields: [],
      profile: null
    }
  }

  constructor(private store: Store, private cdr: ChangeDetectorRef, private http: HttpClient) { }

  ngOnInit(): void {
    this.initGrades();
    this.initUser();
    this.initDataID();
  }

  initDataID() {
    this.store.select(ModalState.selectOpenedID).pipe(take(1)).subscribe((_openedID) => {
      this.interface._id = _openedID;
      this.initProfile();
      this.cdr.detectChanges();
    });
  }
  initGrades(): void {
    this.store.select(PracticeState.selectGrades).subscribe((_grades) => {
      this.interface.grades = _grades;
      this.cdr.detectChanges();
    });
  }
  initUser(): void {
    this.store.select(UserState.selectUser).subscribe((_user) => {
      this.interface.user = _user;
      this.cdr.detectChanges();
    });
  }
  initProfile(): void {
    this.http.get<any[]>(API.url('users/'+this.interface._id+'/auth')).subscribe({
      next: (_user_data: any) => {
        this.interface._cache.profile = _user_data.profile;
        this.interface.auth.login = _user_data.auth.login;
        this.interface.auth.verify = _user_data.auth.verify;
        this.interface.profile.avatar = _user_data.profile.avatar;
        this.interface.profile.firstName = _user_data.profile.firstName;
        this.interface.profile.lastName = _user_data.profile.lastName;
        this.interface.profile.email = _user_data.profile.email;
        this.interface.profile.school = _user_data.profile.school;
        this.interface.profile.practice = _user_data.profile.practice;

        this.interface.profile.phone = _user_data.profile.phone;
        this.interface.profile.verifyCode = _user_data.profile.verifyCode;

        this.interface._cache.selected_school = _user_data.profile.school.schoolName;
        this.interface._cache.grade_selected = _user_data.profile.practice;

        this.cdr.detectChanges();
      },
    });
  }

  actionEnterLogin(event): void {
    this.interface.auth.login = event.target.value;
  }
  actionEnterPassword(event): void {
    this.interface.auth.password = event.target.value;
  }
  actionSelectAvatar(index): void {
    this.interface.profile.avatar = 'https://cdn.argoprep.com/avatars/avatar-' + index + '.svg';
  }
  actionEnterFirstName(event): void {
    this.interface.profile.firstName = event.target.value;
  }
  actionEnterLastName(event): void {
    this.interface.profile.lastName = event.target.value;
  }
  actionEnterEmail(event): void {
    this.interface.profile.email = event.target.value;
  }
  actionGeneratePassword(): void {
    this.interface.auth.password = Math.random().toString(36).slice(-6);
  }
  actionDisconnectAccount(kid): void {
    let _body;
    _body = {
      id: kid._id,
    }
    this.http.put(API.url('auth/unbind/user'), _body).subscribe({
      next: (value) => {
        this.interface.loading = false;
        this.store.dispatch(new CurrentUsers());
        this.store.dispatch(new ModalCloseAction());
      },
      error: (err) => {
        this.interface.loading = false;
        this.cdr.detectChanges();
      },
    });
  }

  actionToggleSearchSchool(): void {
    this.interface._cache.open_search_school = !this.interface._cache.open_search_school;
  }
  actionSearchSchool(event?): void {
    const _search = event? event.target.value: '';
    this.http.get<any[]>(API.url('schools/search'),  { params: { search: _search } }).subscribe({
      next: (_next) => {
        this.interface.schools = _next;
        this.cdr.detectChanges();
      },
    });
  }
  actionSelectSchool(school): void {
    this.interface._cache.open_search_school = false;
    this.interface._cache.selected_school = school.schoolName;
    this.interface.profile.school = school;
    this.cdr.detectChanges();
  }

  actionToggleSelectGrade(): void {
    this.interface._cache.open_select_grade = !this.interface._cache.open_select_grade;
  }
  actionToggleChooseGrade(): void {
    this.interface._cache.open_choose_grade = !this.interface._cache.open_choose_grade;
  }
  actionSelectGrade(_grade): void {
    this.interface._cache.grade_selected = _grade;
    this.interface._cache.open_select_grade = false;
    this.interface.profile.practice = _grade;
  }

  actionConfirm(): void {
    this.interface.loading = true;
    let _body = {
      auth: this.interface.auth,
      profile: this.interface.profile
    };

    this.http.put<UserModel>(API.url('users/'+this.interface._id+'/auth'), _body).subscribe({
      next: (res) => {
        this.store.dispatch(new ModalCloseAction());
        this.interface.loading = false;
        this.cdr.detectChanges();
      },
      error: (err) => {
        this.interface.loading = false;
        this.cdr.detectChanges();
      },
    });
  }
  actionClose() {
    this.store.dispatch(new ModalCloseAction());
  }

  actionToggleVerify() : void {
    this.interface.auth.verify = !this.interface.auth.verify;
  }


 /* interface: any = {
    loading: false,
    _id: null,
    user: new (class implements UserModel {
      _id: string;
      avatar: string;
      language: string;
      displayName: string;
      email: string;
      firstName: string;
      lastName: string;
      password: string;
      practice: string | GradeModel;
      role: RoleModel;
      school: SchoolModel;
      classes?: ClassModel[];
      ain: number;
    })(),
    auth: {
      login: ""
    },
    roles: [],
    schools: [],
    grades: [],
    _cache: {
      open_choose_grade: false,
      open_search_school: false,
      selected_school: '',
      selected_grade: '',
      accepted: false,
      error_fields: [],
    },
  };



  constructor(private store: Store, private cdr: ChangeDetectorRef, private http: HttpClient) {
    this.store.dispatch(new LoadControlRoles());
  }

  ngOnInit(): void {
    this.initClearUser();
    this.initRoles();
    this.initGrades();
    this.initDataID();
  }

  initClearUser(): void {
    this.interface.user = {
      _id: null,
      avatar: '',
      language: 'en',
      displayName: '',
      email: '',
      firstName: '',
      lastName: '',
      password: '',
      practice: null,
      role: null,
      school: null,
      ain: null
    }
  }
  initGrades(): void {
    this.store.select(PracticeState.selectGrades).subscribe((_grades) => {
      this.interface.grades = _grades;
      this.cdr.detectChanges();
    });
  }
  initRoles(): void {
    this.store.select(ControlState.selectRoles).subscribe((_data) => {
      this.interface.roles = _data;
      this.interface.user.role = this.interface.roles[0];
      this.cdr.detectChanges();
    });
  }
  initDataID() {
    this.store.select(ModalState.selectOpenedID).pipe(take(1)).subscribe((_openedID) => {
      this.interface._id = _openedID;
      this.initProfile();
      this.cdr.detectChanges();
    });
  }
  initProfile(): void {
    this.http.get<any[]>(API.url('users/'+this.interface._id+'/auth')).subscribe({
      next: (_user_data: any) => {
        console.log(_user_data.profile);
        this.interface._cache.grade_selected = <GradeModel>_user_data.profile.practice;
        this.interface._cache.selected_school = _user_data.profile.school.schoolName;
        this.interface.user.avatar = _user_data.profile.avatar;
/!*        this.interface.auth.login = _user_data.auth.login;
        this.interface.profile.avatar = _user_data.profile.avatar;
        this.interface.profile.firstName = _user_data.profile.firstName;
        this.interface.profile.lastName = _user_data.profile.lastName;
        this.interface.profile.email = _user_data.profile.email;*!/
        this.cdr.detectChanges();
      },
    });
  }


  initEditedUser(): void {
/!*    this.store.select(UserState.selectEditedStudent).pipe(take(1)).subscribe((_student) => {
      this.interface.student = {..._student};
      this.interface._cache.grade_selected = <GradeModel>this.interface.user.practice;
      this.interface._cache.class_selected = this.interface.user.classes[0]
      this.cdr.detectChanges();
    });*!/
  }

  actionToggleSearchSchool(): void {
    this.interface._cache.open_search_school = !this.interface._cache.open_search_school;
  }
  actionSearchSchool(event?): void {
    const _search = event? event.target.value: '';
    this.http.get<any[]>(API.url('schools/search'),  { params: { search: _search } }).subscribe({
      next: (_next) => {
        this.interface.schools = _next;
        this.cdr.detectChanges();
      },
    });
  }
  actionSelectSchool(school): void {
    this.interface._cache.open_search_school = false;
    this.interface._cache.selected_school = school.schoolName;
    this.interface.user.school = school._id;
    this.interface._cache.error_fields = this.interface._cache.error_fields.filter((e) => e !== 'school');
    this.cdr.detectChanges();
  }

  actionSelectRole(role): void {
    this.interface.user.role = role;
  }
  actionSelectGrade(_grade): void {
    this.interface._cache.grade_selected = _grade;
    this.interface._cache.open_select_grade = false;
    this.interface.user.practice = _grade;
  }
  actionSelectAvatar(index): void {
    this.interface.user.avatar = 'https://cdn.argoprep.com/avatars/avatar-' + index + '.svg';
  }
  actionEnterFirstName(event): void {
    this.interface.user.firstName = event.target.value;
  }
  actionEnterLastName(event): void {
    this.interface.user.lastName = event.target.value;
  }
  actionEnterLogin(event): void {
    this.interface.user.email = event.target.value;
  }
  actionEnterPassword(event): void {
    this.interface.user.password = event.target.value;
  }
  actionGeneratePassword(): void {
    this.interface.auth.password = Math.random().toString(36).slice(-6);
  }
  actionToggleSelectGrade(): void {
    this.interface._cache.open_select_grade = !this.interface._cache.open_select_grade;
  }
  actionToggleChooseGrade(): void {
    this.interface._cache.open_choose_grade = !this.interface._cache.open_choose_grade;
  }

  actionConfirm(): void {
    this.interface.loading = true;
    let _body = this.interface.user;

  }
  actionClose() {
    this.store.dispatch(new ModalCloseAction());
  }*/
}

