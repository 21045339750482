<ui-page-small-sidebar></ui-page-small-sidebar>
<ui-page-sidebar></ui-page-sidebar>
<div class="ui-page-container">
  <ui-page-header></ui-page-header>
  <ui-page-crumbs></ui-page-crumbs>
  <div class="ui-page-body">
    <ng-container *ngIf="interface.loading">
      <ng-container *ngIf="interface.user?.role?._id == '6301e162029cc9403c26de0c'" [ngTemplateOutlet]="DASHBOARD_ADMINISTRATOR"></ng-container>
      <ng-container *ngIf="interface.user?.role?._id == '62ebc2da8e33b7bc52c853c4'" [ngTemplateOutlet]="DASHBOARD_TEACHER"></ng-container>
      <ng-container *ngIf="interface.user?.role?._id == '62ebbf45f009df0b43698376'" [ngTemplateOutlet]="DASHBOARD_PARENT"></ng-container>
      <ng-container *ngIf="interface.user?.role?._id == '62ebc2e38e33b7bc52c853c6'" [ngTemplateOutlet]="DASHBOARD_STUDENT"></ng-container>
    </ng-container>
    <router-outlet (activate)="childContentLoad($event)" (deactivate)="childContentUnLoad($event)"></router-outlet>
  </div>
  <ui-page-footer></ui-page-footer>
</div>
<ui-modal [class.ui-modal-no-close]="interface.modal_no_close"></ui-modal>
<ui-notification></ui-notification>
<ui-ai></ui-ai>

<ng-template #DASHBOARD_ADMINISTRATOR>
  <ui-control-dashboard></ui-control-dashboard>
</ng-template>
<ng-template #DASHBOARD_TEACHER>
  <ui-faq-videos></ui-faq-videos>
  <ui-practice></ui-practice>
  <ui-books></ui-books>
  <ui-classes></ui-classes>
  <ui-assignments></ui-assignments>
</ng-template>
<ng-template #DASHBOARD_PARENT>
  <ui-faq-videos></ui-faq-videos>
  <ui-books></ui-books>
  <ui-assignments></ui-assignments>
</ng-template>
<ng-template #DASHBOARD_STUDENT>
  <ui-homeworks></ui-homeworks>
  <ui-books></ui-books>
</ng-template>
