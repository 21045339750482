import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { PageQuestionsModel} from '../../_models/page-questions.model';
import { Store } from '@ngxs/store';
import { RouterSelectors } from "../../_stores/routing/router.selectors";
import {
  EventPracticeDrillsClose,
  EventPracticeDrillsOpen, EventPracticeQuestionClose,
  EventPracticeQuestionOpen
} from "../../_stores/event_ws/_actions/events.actions";
import { take } from "rxjs";


@Component({
  selector: 'ui-page-questions',
  templateUrl: './ui-page-questions.component.html',
  styleUrls: ['./ui-page-questions.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UiPageQuestionsComponent implements OnInit, OnDestroy {
  interface: PageQuestionsModel = {
    _id: null,
    url: ''
  };

  constructor(private store: Store, private cdr: ChangeDetectorRef) {}

  ngOnDestroy(): void {
        this.initClosedEvent();
    }

  ngOnInit(): void {
    this.initQuestionsId();
  }
  initQuestionsId(): void {
    this.store.select(RouterSelectors.params).pipe(take(1)).subscribe((_params) => {
      this.interface._id = _params.id;
      this.initQuestionsType();
    });
  }

  initQuestionsType(): void {
    this.store.select(RouterSelectors.url).pipe(take(1)).subscribe((_params) => {
      this.interface.url = _params;
      this.initOpenedEvent();
    });
  }


  initOpenedEvent(): void {
    if(this.interface.url.includes('practice_questions')) {
      this.store.dispatch(new EventPracticeQuestionOpen({ categoryId: this.interface._id }));
    }
    if(this.interface.url.includes('practice_drills')) {
      this.store.dispatch(new EventPracticeDrillsOpen({ categoryId: this.interface._id }));
    }
  }
  initClosedEvent(): void {
    if(this.interface.url.includes('practice_questions')) {
      this.store.dispatch(new EventPracticeQuestionClose({ categoryId: this.interface._id }));
    }
    if(this.interface.url.includes('practice_drills')) {
      this.store.dispatch(new EventPracticeDrillsClose({ categoryId: this.interface._id }));
    }
  }
}
