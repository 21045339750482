import { Injectable } from '@angular/core';
import { AssignmentModel } from '../../../_models/assignment.model';
import { HttpClient } from '@angular/common/http';
import { API } from '../../api';
import { Observable } from 'rxjs';
import { CategoryModel } from '../../../_models/category.model';

@Injectable({
  providedIn: 'root',
})
export class CategoriesService {
  // default parent of category: Kindergarten grade
  private DefaultParentCategory = '5e703269f73e83064765eb0f';

  constructor(private http: HttpClient) {}

  createCategory(data) {
    return this.http.post<CategoryModel>(API.url('categories'), data);
  }

  editCategory(data: AssignmentModel) {
    return this.http.patch<CategoryModel>(API.url('categories/' + data._id), data);
  }

  deleteCategory(id: string) {
    return this.http.delete<CategoryModel>(API.url('categories/' + id));
  }

  getCategoriesList(): Observable<{
    breadcrumbs: [];
    parent: null;
    children: [];
  }> {
    return this.http.get<{
      breadcrumbs: [];
      parent: null;
      children: [];
    }>(API.url('categories/parent/' + this.DefaultParentCategory));
  }

  getCategoriesListByParentId(parentId: string): Observable<{
    breadcrumbs: [];
    parent: null;
    children: [];
  }> {
    return this.http.get<{
      breadcrumbs: [];
      parent: null;
      children: [];
    }>(API.url('categories/parent/' + parentId));
  }

  getCategory(categoryID: string): Observable<CategoryModel> {
    return this.http.get<CategoryModel>(API.url('categories/' + categoryID));
  }
}
