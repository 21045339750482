import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { UiTooltipModule } from '../ui-tooltip/ui-tooltip.module';
import { UiControlVideoAddComponent } from './ui-control-video-add.component';
import { UiVideoplayerModule } from '../ui-videoplayer/ui-videoplayer.module';

@NgModule({
  declarations: [UiControlVideoAddComponent],
  exports: [UiControlVideoAddComponent],
  imports: [CommonModule, TranslocoModule, UiTooltipModule, UiVideoplayerModule],
})
export class UiControlVideoAddModule {}
