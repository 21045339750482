<div class="ui-page-sidebar">
  <div class="ui-page-logo">
    <h1>Schools</h1>
    <button (click)="actionToggleSidebar()" tooltip description="Hide sidebar">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"
           stroke-width="1.5">
        <path d="M20 6H4M20 12H12M20 18H4" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M6.5 14L4 11.5L6.5 9" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </button>
  </div>
  <div class="ui-page-user">
    <div class="ui-page-userinfo">
      <div class="ui-page-username">{{interface.user?.displayName}}</div>
      <div class="flex gap-1">
        <div>
          <div class="ui-page-userrole">{{interface.user?.role?.name}}</div>
          <div class="ui-page-userain">{{interface.user?.ain}}</div>
        </div>
        <div class="flex items-center ml-auto mr-4">
          <div class="ui-page-userawards" (click)="actionOpenAllAwards()" *ngIf="false">
           <!-- <div class="ui-page-userawards" (click)="actionOpenAllAwards()" *ngIf="interface.user?.role?._id == '6301e162029cc9403c26de0c'">-->
              <ng-container *ngFor="let award of interface.user?.awards; let a=index">
                <div *ngIf="a < 2" class="ui-page-useraward">
                  <img src="assets/images/awards/{{award.order}}.svg" alt="{{award?.title}}" tooltip title="{{award?.title}}" description="{{award?.description}}"/>
                </div>
              </ng-container>
            <div *ngIf="interface.user?.awards?.length > 2" class="ui-page-useraward ui-page-useraward-label">
              +{{interface.user?.awards?.length - 2}}
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="ui-page-useraction">
      <button tooltip description="Edit user" (click)="actionOpenEditUserModal()">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"
             stroke-width="1.5">
          <path stroke-linecap="round" stroke-linejoin="round"
                d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
        </svg>
      </button>
    </div>
  </div>
  <div class="ui-page-menus">
    <ng-container *ngIf="interface.user?.role?._id == '6301e162029cc9403c26de0c'"
                  [ngTemplateOutlet]="MENU_ADMINISTRATOR"></ng-container>
    <ng-container *ngIf="interface.user?.role?._id == '62ebc2da8e33b7bc52c853c4'"
                  [ngTemplateOutlet]="MENU_TEACHER"></ng-container>
    <ng-container *ngIf="interface.user?.role?._id == '62ebbf45f009df0b43698376'"
                  [ngTemplateOutlet]="MENU_PARENT"></ng-container>
    <ng-container *ngIf="interface.user?.role?._id == '62ebc2e38e33b7bc52c853c6'"
                  [ngTemplateOutlet]="MENU_STUDENT"></ng-container>
  </div>
</div>

<ng-template #MENU_ADMINISTRATOR>
  <div class="ui-page-menu">
    <div class="ui-page-menu-title">
      <span>{{'menu.menu' | transloco}}</span>
    </div>
    <ul>
      <li routerLink="/" routerLinkActive="ui-page-menu-item-active" [routerLinkActiveOptions]="{exact: true}">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"
             stroke-width="1.5">
          <path stroke-linecap="round" stroke-linejoin="round"
                d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
        </svg>
        <span>{{'menu.dashboard' | transloco}}</span>
      </li>
      <li routerLink="/control/users" routerLinkActive="ui-page-menu-item-active"
          [routerLinkActiveOptions]="{exact: true}">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"
             stroke-width="1.5">
          <path stroke-linecap="round" stroke-linejoin="round"
                d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
        </svg>
        <span>Users</span>
      </li>
      <li routerLink="/control/roles" routerLinkActive="ui-page-menu-item-active"
          [routerLinkActiveOptions]="{exact: true}">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"
             stroke-width="1.5">
          <path stroke-linecap="round" stroke-linejoin="round"
                d="M9 3v2m6-2v2M9 19v2m6-2v2M5 9H3m2 6H3m18-6h-2m2 6h-2M7 19h10a2 2 0 002-2V7a2 2 0 00-2-2H7a2 2 0 00-2 2v10a2 2 0 002 2zM9 9h6v6H9V9z" />
        </svg>
        <span>Roles</span>
      </li>
      <!--<li routerLink="/control/statistic" routerLinkActive="ui-page-menu-item-active"
          [routerLinkActiveOptions]="{exact: true}">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"
             stroke-width="1.5">
          <path stroke-linecap="round" stroke-linejoin="round"
                d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z" />
        </svg>
        <span>Statistic</span>
      </li>-->
    </ul>
  </div>
  <div class="ui-page-menu">
    <div class="ui-page-menu-title">
      <span>{{'menu.resources' | transloco}}</span>
    </div>
    <ul>
      <li routerLink="/control/practices/grades" routerLinkActive="ui-page-menu-item-active"
          [routerLinkActiveOptions]="{exact: true}">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"
             stroke-width="1.5">
          <path stroke-linecap="round" stroke-linejoin="round"
                d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
        </svg>
        <span>Grades</span>
      </li>
      <li routerLink="/control/practices/categories" routerLinkActive="ui-page-menu-item-active"
          [routerLinkActiveOptions]="{exact: true}">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"
             stroke-width="1.5">
          <path stroke-linecap="round" stroke-linejoin="round"
                d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
        </svg>
        <span>Categories</span>
      </li>
      <!--      <li routerLink="/control/practices/questions" routerLinkActive="ui-page-menu-item-active" [routerLinkActiveOptions]="{exact: true}">-->
      <!--        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">-->
      <!--          <path stroke-linecap="round" stroke-linejoin="round" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />-->
      <!--        </svg>-->
      <!--        <span>Questions</span>-->
      <!--      </li>-->
      <!--      <li routerLink="/control/practices/videos" routerLinkActive="ui-page-menu-item-active" [routerLinkActiveOptions]="{exact: true}">-->
      <!--        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">-->
      <!--          <path stroke-linecap="round" stroke-linejoin="round" d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z" />-->
      <!--        </svg>-->
      <!--        <span>Videos</span>-->
      <!--      </li>-->
      <!--      <li routerLink="/control/practices/worksheets" routerLinkActive="ui-page-menu-item-active" [routerLinkActiveOptions]="{exact: true}">-->
      <!--        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">-->
      <!--          <path stroke-linecap="round" stroke-linejoin="round" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />-->
      <!--        </svg>-->
      <!--        <span>Worksheets</span>-->
      <!--      </li>-->
      <li routerLink="/control/practices/books" routerLinkActive="ui-page-menu-item-active"
          [routerLinkActiveOptions]="{exact: true}">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"
             stroke-width="1.5">
          <path stroke-linecap="round" stroke-linejoin="round"
                d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
        </svg>
        <span>Books</span>
      </li>
    </ul>
  </div>
  <div class="ui-page-menu">
    <div class="ui-page-menu-title">
      <span>Schools / Libraries</span>
    </div>
    <ul>
      <li routerLink="/control/schools" routerLinkActive="ui-page-menu-item-active"
          [routerLinkActiveOptions]="{exact: true}">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"
             stroke-width="1.5">
          <path stroke-linecap="round" stroke-linejoin="round"
                d="M8 14v3m4-3v3m4-3v3M3 21h18M3 10h18M3 7l9-4 9 4M4 10h16v11H4V10z" />
        </svg>
        <span>List</span>
      </li>
      <li routerLink="/control/schools/licenses" routerLinkActive="ui-page-menu-item-active"
          [routerLinkActiveOptions]="{exact: true}">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"
             stroke-width="1.5">
          <path stroke-linecap="round" stroke-linejoin="round"
                d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
        </svg>
        <span>Licenses</span>
      </li>
      <li routerLink="/control/schools/no-licenses" routerLinkActive="ui-page-menu-item-active"
          [routerLinkActiveOptions]="{exact: true}">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
             class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round"
                d="M11.412 15.655L9.75 21.75l3.745-4.012M9.257 13.5H3.75l2.659-2.849m2.048-2.194L14.25 2.25 12 10.5h8.25l-4.707 5.043M8.457 8.457L3 3m5.457 5.457l7.086 7.086m0 0L21 21" />
        </svg>
        <span>No Licenses</span>
      </li>
    </ul>
  </div>
</ng-template>
<ng-template #MENU_TEACHER>
  <div class="ui-page-menu">
    <div class="ui-page-menu-title">
      <span>{{'menu.menu' | transloco}}</span>
    </div>
    <ul>
      <li routerLink="/" routerLinkActive="ui-page-menu-item-active" [routerLinkActiveOptions]="{exact: true}">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"
             stroke-width="1.5">
          <path stroke-linecap="round" stroke-linejoin="round"
                d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
        </svg>
        <span>Dashboard</span>
      </li>
      <li routerLink="/books" routerLinkActive="ui-page-menu-item-active" [routerLinkActiveOptions]="{exact: true}">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"
             stroke-width="1.5">
          <path stroke-linecap="round" stroke-linejoin="round"
                d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
        </svg>
        <span>Books</span>
      </li>
      <li routerLink="/worksheet-generators" routerLinkActive="ui-page-menu-item-active" [routerLinkActiveOptions]="{exact: true}">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
        </svg>
        <span>Worksheet generators</span>
      </li>
      <li routerLink="/students" routerLinkActive="ui-page-menu-item-active" [routerLinkActiveOptions]="{exact: true}">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"
             stroke-width="1.5">
          <path stroke-linecap="round" stroke-linejoin="round"
                d="M10 6H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V8a2 2 0 00-2-2h-5m-4 0V5a2 2 0 114 0v1m-4 0a2 2 0 104 0m-5 8a2 2 0 100-4 2 2 0 000 4zm0 0c1.306 0 2.417.835 2.83 2M9 14a3.001 3.001 0 00-2.83 2M15 11h3m-3 4h2" />
        </svg>
        <span>Students</span>
      </li>
      <li routerLink="/parents" routerLinkActive="ui-page-menu-item-active" [routerLinkActiveOptions]="{exact: true}">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
             class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round"
                d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
        </svg>
        <span>Parents</span>
      </li>
      <li routerLink="/reports" routerLinkActive="ui-page-menu-item-active" [routerLinkActiveOptions]="{exact: true}">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
             class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 6a7.5 7.5 0 107.5 7.5h-7.5V6z" />
          <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 10.5H21A7.5 7.5 0 0013.5 3v7.5z" />
        </svg>
        <span>Statistic</span>
      </li>

    </ul>
  </div>
  <div class="ui-page-menu">
    <div class="ui-page-menu-title">
      <span>{{'menu.resources' | transloco}}</span>
    </div>
    <ng-container [ngTemplateOutlet]="MENU_PRACTICE"></ng-container>
  </div>
  <div class="ui-page-menu">
    <div class="ui-page-menu-title">
      <span>{{'menu.classes' | transloco}}</span>
      <div class="ui-page-menu-title-action" tooltip title="{{'tooltips.add_classes.title' | transloco}}"
           description="{{'tooltips.add_classes.description' | transloco}}" (click)="actionOpenAddClassModal()">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"
             stroke-width="1.5">
          <path stroke-linecap="round" stroke-linejoin="round"
                d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z" />
        </svg>
      </div>
    </div>
    <ul>
      <ng-container *ngFor="let cl of interface.classes | sort: 'asc':'title' |  slice:0:3; let p=index">
        <li [routerLink]="'/classes/'+cl._id" routerLinkActive="ui-page-menu-item-active"
            [routerLinkActiveOptions]="{exact: true}">
          <svg class="h-6 w-6 bg-pap-150 rounded-lg" viewBox="0 0 24 24" fill="none">
            <path class="fill-pap-300"
                  d="M5.5 14.361v3.14A1.5 1.5 0 007 19h10a1.5 1.5 0 001.5-1.5v-3.14c-2.102.42-4.275.64-6.5.64-2.225 0-4.399-.22-6.5-.64z"></path>
            <path class="fill-pap-400" fill-rule="evenodd"
                  d="M9 6.5V7H6.5A1.5 1.5 0 005 8.5v3.53c0 .702.487 1.315 1.178 1.442C8.066 13.819 10.012 14 12 14s3.934-.181 5.822-.528A1.454 1.454 0 0019 12.03V8.5A1.5 1.5 0 0017.5 7H15v-.5A1.5 1.5 0 0013.5 5h-3A1.5 1.5 0 009 6.5zm1 0a.5.5 0 01.5-.5h3a.5.5 0 01.5.5V7h-4v-.5zm2 6.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
                  clip-rule="evenodd"></path>
            <circle cx="12" cy="12" r=".75" class="fill-white"></circle>
          </svg>
          <span>{{cl.title}}</span>
        </li>
      </ng-container>
      <li class="ui-page-menu-item-add" *ngIf="interface.classes.length == 0" (click)="actionOpenAddClassModal()">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
             class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
        <span>Add class</span>
      </li>
      <li class="ui-page-menu-item-more" routerLink="/classes">
        <span>All classes</span>
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"
             stroke-width="1.5">
          <path stroke-linecap="round" stroke-linejoin="round"
                d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z" />
        </svg>
      </li>
    </ul>
  </div>
  <div class="ui-page-menu">
    <div class="ui-page-menu-title">
      <span>{{'menu.assignments' | transloco}}</span>
      <div class="ui-page-menu-title-action" tooltip title="{{'tooltips.add_assignments.title' | transloco}}"
           description="{{'tooltips.add_assignments.description' | transloco}}" (click)="actionAddAssignment()">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"
             stroke-width="1.5">
          <path stroke-linecap="round" stroke-linejoin="round"
                d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z" />
        </svg>
      </div>
    </div>
    <ul>
      <ng-container *ngFor="let assignment of interface.assignments | sort: 'asc':'title' |  slice:0:3; let p=index">
        <li [routerLink]="'/assignments/'+ assignment._id" routerLinkActive="ui-page-menu-item-active"
            [routerLinkActiveOptions]="{exact: true}">
          <svg class="h-6 w-6 bg-sap-200 rounded-lg" viewBox="0 0 24 24" fill="none">
            <path d="M4 12a7 7 0 0 1 7-7h2a7 7 0 1 1 0 14h-2a7 7 0 0 1-7-7Z" class="fill-sap-400"></path>
            <path d="M10.25 9.75 7.75 12l2.5 2.25" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
                  class="stroke-white"></path>
            <path d="m13.75 9.75 2.5 2.25-2.5 2.25" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
                  class="stroke-white"></path>
          </svg>
          <span>{{assignment.title}}</span>
        </li>
      </ng-container>
      <li class="ui-page-menu-item-add" *ngIf="interface.assignments.length == 0" (click)="actionAddAssignment()">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
             class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
        <span>Add assignment</span>
      </li>
      <li class="ui-page-menu-item-more" routerLink="/assignments">
        <span>All assignments</span>
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"
             stroke-width="1.5">
          <path stroke-linecap="round" stroke-linejoin="round"
                d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z" />
        </svg>
      </li>
    </ul>
  </div>
</ng-template>
<ng-template #MENU_PARENT>
  <div class="ui-page-menu">
    <div class="ui-page-menu-title">
      <span>{{'menu.menu' | transloco}}</span>
    </div>
    <ul>
      <li routerLink="/" routerLinkActive="ui-page-menu-item-active" [routerLinkActiveOptions]="{exact: true}">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"
             stroke-width="1.5">
          <path stroke-linecap="round" stroke-linejoin="round"
                d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
        </svg>
        <span>{{'menu.dashboard' | transloco}}</span>
      </li>
      <li routerLink="/books" routerLinkActive="ui-page-menu-item-active" [routerLinkActiveOptions]="{exact: true}">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"
             stroke-width="1.5">
          <path stroke-linecap="round" stroke-linejoin="round"
                d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
        </svg>
        <span>{{'menu.books' | transloco}}</span>
      </li>
    </ul>
  </div>
  <div class="ui-page-menu">
    <div class="ui-page-menu-title">
      <span>{{'menu.resources' | transloco}}</span>
    </div>
    <ng-container [ngTemplateOutlet]="MENU_PRACTICE"></ng-container>
  </div>
  <div class="ui-page-menu">
    <div class="ui-page-menu-title">
      <span>{{'menu.assignments' | transloco}}</span>
      <div class="ui-page-menu-title-action" tooltip title="{{'tooltips.add_assignments.title' | transloco}}"
           description="{{'tooltips.add_assignments.description' | transloco}}" (click)="actionAddAssignment()">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"
             stroke-width="1.5">
          <path stroke-linecap="round" stroke-linejoin="round"
                d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z" />
        </svg>
      </div>
    </div>
    <ul>
      <ng-container *ngFor="let assignment of interface.assignments | sort: 'asc':'title' |  slice:0:3; let p=index">
        <li [routerLink]="'/assignments/'+ assignment._id" routerLinkActive="ui-page-menu-item-active"
            [routerLinkActiveOptions]="{exact: true}">
          <svg class="h-6 w-6 bg-sap-200 rounded-lg" viewBox="0 0 24 24" fill="none">
            <path d="M4 12a7 7 0 0 1 7-7h2a7 7 0 1 1 0 14h-2a7 7 0 0 1-7-7Z" class="fill-sap-400"></path>
            <path d="M10.25 9.75 7.75 12l2.5 2.25" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
                  class="stroke-white"></path>
            <path d="m13.75 9.75 2.5 2.25-2.5 2.25" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
                  class="stroke-white"></path>
          </svg>
          <span>{{assignment.title}}</span>
        </li>
      </ng-container>
      <li class="ui-page-menu-item-add" *ngIf="interface.assignments.length == 0" (click)="actionAddAssignment()">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
             class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
        <span>Add assignment</span>
      </li>
      <li class="ui-page-menu-item-more" routerLink="/assignments">
        <span>All assignments</span>
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"
             stroke-width="1.5">
          <path stroke-linecap="round" stroke-linejoin="round"
                d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z" />
        </svg>
      </li>
    </ul>
  </div>
</ng-template>
<ng-template #MENU_STUDENT>
  <div class="ui-page-menu">
    <div class="ui-page-menu-title">
      <span>{{'menu.menu' | transloco}}</span>
    </div>
    <ul>
      <li routerLink="/" routerLinkActive="ui-page-menu-item-active" [routerLinkActiveOptions]="{exact: true}">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"
             stroke-width="1.5">
          <path stroke-linecap="round" stroke-linejoin="round"
                d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
        </svg>
        <span>{{'menu.dashboard' | transloco}}</span>
      </li>
      <li routerLink="/books" routerLinkActive="ui-page-menu-item-active" [routerLinkActiveOptions]="{exact: true}">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"
             stroke-width="1.5">
          <path stroke-linecap="round" stroke-linejoin="round"
                d="M8 14v3m4-3v3m4-3v3M3 21h18M3 10h18M3 7l9-4 9 4M4 10h16v11H4V10z" />
        </svg>
        <span>Library</span>
      </li>
    </ul>
  </div>
  <div class="ui-page-menu">
    <div class="ui-page-menu-title">
      <span>{{'menu.resources' | transloco}}</span>
    </div>
    <ng-container [ngTemplateOutlet]="MENU_PRACTICE"></ng-container>
  </div>
  <div class="ui-page-menu">
    <div class="ui-page-menu-title">
      <span>{{'menu.homeworks' | transloco}}</span>
    </div>
    <ul *ngIf="interface.assignments.length > 0">
      <ng-container *ngFor="let homework of interface.homeworks | sort: 'asc':'title' |  slice:0:3; let p=index">
        <li [routerLink]="'/homeworks/'+ homework._id" routerLinkActive="ui-page-menu-item-active"
            [routerLinkActiveOptions]="{exact: true}">
          <svg class="h-6 w-6 bg-emerald-50 rounded-lg" viewBox="0 0 24 24" fill="none">
            <path class="fill-emerald-400"
                  d="M5.5 14.361v3.14A1.5 1.5 0 007 19h10a1.5 1.5 0 001.5-1.5v-3.14c-2.102.42-4.275.64-6.5.64-2.225 0-4.399-.22-6.5-.64z"></path>
            <path class="fill-emerald-500" fill-rule="evenodd"
                  d="M9 6.5V7H6.5A1.5 1.5 0 005 8.5v3.53c0 .702.487 1.315 1.178 1.442C8.066 13.819 10.012 14 12 14s3.934-.181 5.822-.528A1.454 1.454 0 0019 12.03V8.5A1.5 1.5 0 0017.5 7H15v-.5A1.5 1.5 0 0013.5 5h-3A1.5 1.5 0 009 6.5zm1 0a.5.5 0 01.5-.5h3a.5.5 0 01.5.5V7h-4v-.5zm2 6.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
                  clip-rule="evenodd"></path>
            <circle cx="12" cy="12" r=".75" class="fill-white"></circle>
          </svg>
          <span>{{homework.title}}</span>
        </li>
      </ng-container>
      <li class="ui-page-menu-item-more" routerLink="/homeworks">
        <span>All homework</span>
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"
             stroke-width="1.5">
          <path stroke-linecap="round" stroke-linejoin="round"
                d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z" />
        </svg>
      </li>
    </ul>
  </div>
</ng-template>


<ng-template #MENU_PRACTICE>
  <ul>
    <li routerLink="/practices/video_lectures" routerLinkActive="ui-page-menu-item-active"
        [routerLinkActiveOptions]="{exact: true}">
      <svg class="h-6 w-6" viewBox="0 0 24 24" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M19 12a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"
              class="fill-[#83caf6] "></path>
        <path
          d="M11.082 9.107a.685.685 0 0 0-.72-.01.757.757 0 0 0-.362.653v4.5c0 .27.138.52.362.653.224.133.5.13.72-.01l3.571-2.25A.758.758 0 0 0 15 12a.758.758 0 0 0-.347-.643l-3.571-2.25Z"
          class="fill-[#f1f8fe]"></path>
      </svg>
      <span>Video lectures</span>
    </li>
    <li routerLink="/practices/practice_questions" routerLinkActive="ui-page-menu-item-active"
        [routerLinkActiveOptions]="{exact: true}">
      <svg class="h-6 w-6" viewBox="0 0 24 24" fill="none">
        <path d="m6 9 6-3 6 3v6l-6 3-6-3V9Z" class="fill-[#e4e5fb]"></path>
        <path d="m6 9 6 3v7l-6-3V9Z" class="fill-[#b1b2f0]"></path>
        <path d="m18 9-6 3v7l6-3V9Z" class="fill-[#8d87e6]"></path>
      </svg>
      <span>Practice questions</span>
    </li>
    <li routerLink="/practices/practice_drills" routerLinkActive="ui-page-menu-item-active"
        [routerLinkActiveOptions]="{exact: true}">
      <svg class="h-6 w-6" viewBox="0 0 24 24" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M8 6C6.89543 6 6 6.89543 6 8V16C6 17.1046 6.89543 18 8 18H10.5C11.0523 18 11.5 17.5523 11.5 17V12C11.5 10.6193 12.6193 9.5 14 9.5H18V8C18 6.89543 17.1046 6 16 6H8ZM7.25 8C7.25 7.58579 7.58579 7.25 8 7.25H8.01C8.42421 7.25 8.76 7.58579 8.76 8C8.76 8.41421 8.42421 8.75 8.01 8.75H8C7.58579 8.75 7.25 8.41421 7.25 8ZM10 7.25C9.58579 7.25 9.25 7.58579 9.25 8C9.25 8.41421 9.58579 8.75 10 8.75H10.01C10.4242 8.75 10.76 8.41421 10.76 8C10.76 7.58579 10.4242 7.25 10.01 7.25H10Z"
              fill="#E879F9" class="fill-[#F68383]"></path>
        <path
          d="M13 12C13 11.4477 13.4477 11 14 11H17C17.5523 11 18 11.4477 18 12V17C18 17.5523 17.5523 18 17 18H14C13.4477 18 13 17.5523 13 17V12Z"
          fill="#F0ABFC" class="fill-[#fdcbcb]"></path>
      </svg>
      <span>Practice drills</span>
    </li>
    <li routerLink="/practices/worksheets" routerLinkActive="ui-page-menu-item-active"
        [routerLinkActiveOptions]="{exact: true}">
      <svg class="h-6 w-6" viewBox="0 0 24 24" fill="none">
        <path
          d="M6 8a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2V8ZM6 15a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2v-1Z"
          class="fill-[#dc8c5b]"></path>
        <path d="M13 8a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2V8Z" class="fill-[#f0d2b8]"></path>
        <path d="M13 15a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2v-1Z"
              class="fill-[#dc8c5b]"></path>
      </svg>
      <span>Worksheets</span>
    </li>
  </ul>
</ng-template>
