import {
  Action, createSelector,
  NgxsAfterBootstrap,
  NgxsOnChanges,
  NgxsOnInit,
  NgxsSimpleChange,
  Selector,
  State,
  StateContext,
  Store
} from "@ngxs/store";
import { Injectable } from '@angular/core';
import { BooksService } from "../_service/books.service";
import {
  ClearBookPages,
  ClearBookVideos,
  GetBooksList,
  LoadBookPages,
  LoadBookVideos,
  SetBooksList
} from "../_actions/books.actions";
import { BookModel } from "../../../_models/book.model";
import {BookPageModel} from "../../../_models/book-page.model";
import {CategoryModel} from "../../../_models/category.model";




export class BooksStateModel {
  books: BookModel[];
  videos: CategoryModel[];
  page: BookPageModel;
  spread: BookPageModel[]
}

@State<BooksStateModel>({
  name: 'SAP_BOOKS',
  defaults: {
    books: [],
    videos: [],
    page: undefined,
    spread: []
  },
})
@Injectable()
export class BooksState
  implements NgxsOnInit, NgxsOnChanges, NgxsAfterBootstrap
{
  constructor(private store: Store, private booksService: BooksService) {}


  ngxsAfterBootstrap(ctx?: StateContext<BooksStateModel>): void {
    this.store.dispatch(new GetBooksList());
    this.store.dispatch(new ClearBookVideos());
  }

  ngxsOnChanges(change: NgxsSimpleChange): void {
  }

  ngxsOnInit(ctx?: StateContext<any>): any {
  }



  @Selector()
  static selectBooksList(state: BooksStateModel) {
    return state.books;
  }

  @Selector()
  static selectBookSpread(state: BooksStateModel) {
    return state.spread;
  }
  @Selector()
  static selectBookPage(state: BooksStateModel) {
    return state.page;
  }
  @Selector()
  static selectBookVideos(state: BooksStateModel) {
    return state.videos;
  }
  static selectBookById(id: string)  {
    return createSelector([ BooksState.selectBooksList ], ( books: BookModel[]) => {
      return books.find(x => x._id === id);
    });
  }



  @Action(GetBooksList)
  getBooksList(ctx: StateContext<BooksStateModel>) {
    this.booksService.getBooksList().subscribe((res) => {
      ctx.dispatch(new SetBooksList(res));
    });
  }

  @Action(SetBooksList)
  setBooksList(ctx: StateContext<BooksStateModel>, payload) {
    ctx.patchState({books: payload.list});
  }

  @Action(LoadBookPages)
  loadBookPages(ctx: StateContext<BooksStateModel>, payload) {
    this.booksService.getBookPages(payload.book_id, payload.page_number).subscribe((_pages) => {
      ctx.patchState({
        page: _pages.find((_page) => _page.page == payload.page_number),
        spread:_pages
      });
    });
  }
  @Action(ClearBookPages)
  clearBookPages(ctx: StateContext<BooksStateModel>) {
    ctx.patchState({
      page: null,
      spread:[]
    });
  }

  @Action(LoadBookVideos)
  loadBookVideos(ctx: StateContext<BooksStateModel>, payload) {
    this.booksService.getBookVideos(payload.book_id).subscribe((_categories) => {
      ctx.patchState({
        videos:_categories
      });
    });
  }

  @Action(ClearBookVideos)
  clearBookVideos(ctx: StateContext<BooksStateModel>) {
    ctx.patchState({
      videos:[]
    });
  }

}
