import { CategoryModel } from '../../../_models/category.model';
import {
  Action,
  createSelector,
  NgxsAfterBootstrap,
  NgxsOnChanges,
  NgxsOnInit,
  NgxsSimpleChange,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import { Injectable } from '@angular/core';
import { PracticeStateModel } from '../../practice/_state/practice.state';
import { RouterState } from '@ngxs/router-plugin';
import { RouterStateModel as RouterStateOuterModel } from '@ngxs/router-plugin/src/router.state';
import { RouterStateModel } from '../../../_models/router-state.model';
import { ModalState } from '../../modal/_state/modal.state';
import { ModalModel } from '../../../_models/modal.model';
import {
  CreateCategory,
  DeleteCategory,
  EditCategory,
  GetCategory,
  GetCategoryList,
  GetCategoryListByParent,
} from '../_actions/categories.actions';
import { CategoriesService } from '../_services/categories.service';
import { UserState } from '../../user/_state/user.state';
import { first } from 'rxjs';
import { CategoriesBreadCrumbModel } from '../../../_models/categories-bread-crumb-model';
import { ModalCloseAction } from '../../modal/_actions/modal.actions';

export interface CategoriesStateModel {
  isLoading: boolean;
  lastCreatedCategory: CategoryModel;
  categories: CategoryModel[];
  category: CategoryModel;
  data: {
    breadcrumbs: CategoriesBreadCrumbModel[];
    parent: CategoryModel;
    children: CategoryModel[];
  };
}

export const clearCategories = {
  isLoading: false,
  lastCreatedCategory: null,
  categories: [],
  category: null,
  data: {
    breadcrumbs: [],
    parent: null,
    children: [],
  },
};

@State<CategoriesStateModel>({
  name: 'SAP_CATEGORIES',
  defaults: clearCategories,
})
@Injectable()
export class CategoriesState implements NgxsOnInit, NgxsOnChanges, NgxsAfterBootstrap {
  constructor(private store: Store, private categoriesService: CategoriesService) {}

  @Selector()
  public static selectCategoriesState(state: CategoriesStateModel) {
    return state;
  }

  @Selector()
  public static selectCategory(state: CategoriesStateModel) {
    return state.category;
  }

  @Selector()
  public static selectCategoryListByParent(state: CategoriesStateModel) {
    return state.data;
  }

  @Selector()
  public static selectCategoryList(state: CategoriesStateModel) {
    return state.categories;
  }

  @Selector()
  public static selectHomeworkList(state: CategoriesStateModel) {
    return state.categories;
  }

  @Selector()
  public static selectLastCreatedCategory(state: CategoriesStateModel) {
    return state.lastCreatedCategory;
  }

  @Selector([ModalState])
  public static selectCategoriesByModalId(state: CategoriesStateModel, modal: ModalModel) {
    return modal && modal._id ? modal._id : '';
  }

  @Selector([ModalState])
  public static selectEditCategoryByModalId(state: CategoriesStateModel, modal: ModalModel) {
    return state.data.children.find((value) => value._id === modal._id);
  }

  @Selector([RouterState])
  public static selectCategoriesByRouteId(state: CategoriesStateModel, route: RouterStateOuterModel<RouterStateModel>) {
    return state.categories.find((_category) => _category._id == route.state.params.category_id);
  }

  static selectCategoryById(id: string) {
    return createSelector([CategoriesState.selectCategoryList], (categories: CategoryModel[]) => {
      return categories.find((x) => x._id === id);
    });
  }

  static selectCategoriesByParentId(id: string) {
    return createSelector([CategoriesState.selectCategoryList], (categories: CategoryModel[]) => {
      return categories.filter((x) => x.parent._id === id);
    });
  }

  ngxsAfterBootstrap(ctx?: StateContext<PracticeStateModel>): void {
    this.store
      .select(UserState.selectUser)
      .pipe(first((user) => !!user))
      .subscribe((user) => {
        if (user.role.name.toLowerCase() === 'administrator') {
          ctx.dispatch(new GetCategoryList());
        }
      });
  }

  ngxsOnInit(ctx?: StateContext<PracticeStateModel>): void {}

  ngxsOnChanges(change: NgxsSimpleChange<PracticeStateModel>): void {}

  @Action(CreateCategory)
  create(ctx: StateContext<CategoriesStateModel>, { payload }: CreateCategory) {
    ctx.patchState({
      isLoading: true,
    });
    this.categoriesService.createCategory(payload.data).subscribe({
      next: (res) => {
        const state = ctx.getState();

        ctx.patchState({
          data: { breadcrumbs: [], children: [], parent: undefined },
          category: res,
          categories: [res, ...state.categories],
          lastCreatedCategory: res,
          isLoading: false,
        });
        // this.store.dispatch(new EventCategoryCreated({ categoryId: res._id }));
        this.store.dispatch(new GetCategoryListByParent({ parentId: res?.parent._id }));
        this.store.dispatch(new ModalCloseAction());
      },
      error: (err) => {
        //console.log('err: ', err);
      },
      complete: () => {},
    });
  }

  @Action(EditCategory)
  edit(ctx: StateContext<CategoriesStateModel>, { payload }: EditCategory) {
    ctx.patchState({
      isLoading: true,
    });
    return this.categoriesService.editCategory(payload.data).subscribe({
      next: (res) => {
        const state = ctx.getState();
        ctx.dispatch(new GetCategoryList());
        ctx.setState({
          ...state,
          category: res,
        });
        ctx.dispatch(new ModalCloseAction());
      },
      error: () => {},
      complete: () => {},
    });
  }

  @Action(GetCategory)
  getCategory({ getState, setState }: StateContext<CategoriesStateModel>, { payload }: any) {
    const state = getState();
    setState({
      ...state,
      category: null,
    });
    return this.categoriesService.getCategory(payload.categoryID).subscribe({
      next: (res: any) => {
        setState({
          ...state,
          category: res,
        });
      },
      error: () => {},
      complete: () => {},
    });
  }

  @Action(DeleteCategory)
  deleteCategory(ctx: StateContext<CategoriesStateModel>, { payload }: any) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      category: null,
    });
    return this.categoriesService.deleteCategory(payload.categoryID).subscribe({
      next: (res: CategoryModel) => {
        ctx.dispatch(new GetCategoryListByParent({ parentId: res.parent._id }));
      },
      error: () => {},
      complete: () => {},
    });
  }

  @Action(GetCategoryList)
  getCategoryList({ getState, setState }: StateContext<CategoriesStateModel>) {
    return this.categoriesService.getCategoriesList().subscribe({
      next: (res) => {
        const state = getState();
        setState({
          ...state,
          data: res,
        });
      },
      error: () => {},
      complete: () => {},
    });
  }

  @Action(GetCategoryListByParent)
  getCategoryListByParent({ getState, setState }: StateContext<CategoriesStateModel>, { payload }: any) {
    return this.categoriesService.getCategoriesListByParentId(payload.parentId).subscribe({
      next: (res) => {
        const state = getState();
        setState({
          ...state,
          data: res,
        });
      },
      error: () => {},
      complete: () => {},
    });
  }
}
