import { Injectable } from '@angular/core';
import { AssignmentModel, CreateAssignmentDataModel } from '../../../_models/assignment.model';
import { HttpClient } from '@angular/common/http';
import { API } from '../../api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AssignmentsService {
  constructor(private http: HttpClient) {}

  createAssignment(data: CreateAssignmentDataModel) {
    return this.http.post<AssignmentModel>(API.url('assignments'), data);
  }

  editAssignment(data: AssignmentModel) {
    console.log('editAssignment data: ', data);
    return this.http.patch<AssignmentModel>(API.url('assignments/' + data._id), data);
  }

  archiveAssignment(data: any) {
    return this.http.post<AssignmentModel>(API.url(`classes/${data.classId}/assignments/archive`), data);
  }

  deleteAssignment(id: string) {
    return this.http.delete<AssignmentModel>(API.url('assignments/' + id + '/creator'));
  }

  getAssignmentsList(): Observable<AssignmentModel[]> {
    return this.http.get<AssignmentModel[]>(API.url('assignments/from-creator'));
  }

  getHomeworksList(): Observable<AssignmentModel[]> {
    return this.http.get<AssignmentModel[]>(API.url('assignments/assigned'));
  }
}
