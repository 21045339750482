import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { UiTooltipModule } from '../ui-tooltip/ui-tooltip.module';
import { UiVideoplayerModule } from '../ui-videoplayer/ui-videoplayer.module';
import { UiControlQuestionAddComponent } from './ui-control-question-add.component';

@NgModule({
  declarations: [UiControlQuestionAddComponent],
  exports: [UiControlQuestionAddComponent],
  imports: [CommonModule, TranslocoModule, UiTooltipModule, UiVideoplayerModule],
})
export class UiControlQuestionAddModule {}
