import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiControlCategoryEditComponent } from './ui-control-category-edit.component';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
  declarations: [UiControlCategoryEditComponent],
  exports: [UiControlCategoryEditComponent],
  imports: [CommonModule, TranslocoModule],
})
export class UiControlCategoryEditModule {}
