import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { ModalCloseAction } from '../../_stores/modal/_actions/modal.actions';
import { VideosState } from '../../_stores/videos/_state/videos.state';
import { EditVideo } from '../../_stores/videos/_actions/videos.actions';
import { VideoModel } from '../../_models/video.model';

export interface UiControlVideoEditModel {
  loading: boolean;
  edit_video: VideoModel;
  _cache: {
    edit_video: VideoModel;
    thumbnail: string;
    url: string;
    title: string;
    slug: string;
    vimeoid: string | number;
    visible: boolean;
    description: string;
  };
}

@Component({
  selector: 'ui-control-question-edit',
  templateUrl: './ui-control-question-edit.component.html',
  styleUrls: ['./ui-control-question-edit.component.scss'],
})
export class UiControlQuestionEditComponent implements OnInit {
  public interface: UiControlVideoEditModel = {
    _cache: {
      description: '',
      edit_video: undefined,
      slug: '',
      thumbnail: '',
      title: '',
      url: '',
      vimeoid: undefined,
      visible: false,
    },
    loading: false,
    edit_video: undefined,
  };

  constructor(private store: Store, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.store.select(VideosState.selectVideosByModalId).subscribe((videoID) => {
      if (videoID && videoID.length > 0) {
        this.store.select(VideosState.selectVideo).subscribe({
          next: (_editVideo) => {
            if (_editVideo) {
              this.interface.edit_video = { ..._editVideo };
              this.interface._cache = {
                ...this.interface._cache,
                url: this.interface.edit_video.options.url,
                vimeoid: this.interface.edit_video.options.vimeoid,
                visible: this.interface.edit_video.options.visible,
                thumbnail: this.interface.edit_video.thumbnail,
                description: this.interface.edit_video.description,
                slug: this.interface.edit_video.slug,
                title: this.interface.edit_video.title,
                edit_video: this.interface.edit_video,
              };
            }
            this.cdr.detectChanges();
          },
        });
      }
    });
  }

  actionClose() {
    this.store.dispatch(new ModalCloseAction());
  }

  actionEnterVideoDescription(event) {
    this.interface._cache.description = event.target.value;
    this.cdr.detectChanges();
  }

  actionEnterVimeoID(event) {
    this.interface._cache.vimeoid = event.target.value;
    this.interface._cache.url = 'https://argoprep.com/?ept_vimeo&type=link_file&id='.concat(
      this.interface._cache.vimeoid.toString(),
    );
    this.interface._cache.thumbnail = 'https://argoprep.com/?ept_vimeo&type=poster&id='.concat(
      this.interface._cache.vimeoid.toString(),
    );
    this.cdr.detectChanges();
  }

  actionEnterVideoSlug(event) {
    this.interface._cache.slug = event.target.value;
    this.cdr.detectChanges();
  }

  actionSelectVisible(event) {
    this.interface._cache.visible = !this.interface._cache.visible;
  }

  actionEnterTitle(event) {
    this.interface._cache.title = event.target.value;
    this.cdr.detectChanges();
  }

  actionEditVideo() {
    this.interface._cache.edit_video = {
      ...this.interface._cache.edit_video,
      title: this.interface._cache.title,
      slug: this.interface._cache.slug,
      options: {
        visible: this.interface._cache.visible,
        url: this.interface._cache.url,
        vimeoid: this.interface._cache.vimeoid,
      },
      thumbnail: this.interface._cache.thumbnail,
      description: this.interface._cache.description,
    };
    this.store.dispatch(new EditVideo({ data: this.interface._cache.edit_video }));
  }

  private initMaterialsOfCategory() {}
}
