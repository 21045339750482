import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {UiCategoriesTreeComponent} from "./ui-categories-tree.component";
import {PipesModule} from "../../app.pipes.module";
import {RouterModule} from "@angular/router";
import {SortPipe} from "../../_pipes/sort.pipe";
import { LazyLoadImageModule } from "ng-lazyload-image";



@NgModule({
  declarations: [UiCategoriesTreeComponent],
  exports: [UiCategoriesTreeComponent],
  imports: [
    CommonModule,
    PipesModule,
    RouterModule,
    LazyLoadImageModule
  ],
  providers: [SortPipe],
})
export class UiCategoriesTreeModule { }
