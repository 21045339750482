import { ChangeDetectorRef, Component, HostListener, OnInit } from "@angular/core";
import { Store } from "@ngxs/store";
import { TranslocoService } from "@ngneat/transloco";
import { WebSocketService } from "../../_services/websocket.service";
import { UserState } from "../../_stores/user/_state/user.state";
import { UserModel } from "../../_models/user.model";
import { AppState } from "../../_stores/app/_state/app.state";
import { ClearMessageToAI } from "../../_stores/app/_actions/app.actions";

export interface UserMessageModel {
  question: string,
  show: boolean,
  answer:string
}


@Component({
  selector: 'ui-ai',
  templateUrl: './ui-ai.component.html',
  styleUrls: ['./ui-ai.component.scss']
})
export class UiAiComponent implements OnInit {
  interface: {
    init_chat: boolean,
    chat_open: boolean,
    user: UserModel,
    messages: UserMessageModel[],
    _cache: any
  } = {
    init_chat: false,
    chat_open: false,
    user: undefined,
    messages: [],
    _cache: {
      error_fields: [],
      message: ''
    }
  };

  constructor(private store: Store, private cdr: ChangeDetectorRef, private transloco: TranslocoService, private websocketService: WebSocketService) {}

  ngOnInit(): void {
    this.initApp();
    this.initUser();
  }

  initApp(): void {
    this.store.select(AppState.selectState).subscribe((_app) => {
      if(!_app) { return; }
      if(_app.message) {
        this.interface.messages.push({
          question: _app.message,
          show: false,
          answer: undefined
        });
        this.websocketService.initAIQuestion(_app.message);
        this.interface._cache.message = undefined;
        this.store.dispatch(new ClearMessageToAI());
        if(!this.interface.chat_open) {
          this.actionOpenChat();
        }

      }
      this.cdr.detectChanges();
    });
  }


  initUser(): void {
    this.store.select(UserState.selectUser).subscribe((_user) => {
      this.interface.user = _user;
      //teacher@argoprep.com
      this.initChat();
      this.cdr.detectChanges();
    });
  }

  initChat(): void {
    this.initAnswer();
    this.websocketService.initSession();
    this.websocketService.initAI();
    this.interface.init_chat = true;
  }
  initAnswer(): void {
    this.websocketService.answer$.subscribe((answer: string) => {
      if(answer && this.interface.messages.length > 0) {
        this.interface.messages[this.interface.messages.length-1].answer = answer;
        this.cdr.detectChanges();
      }
    });
  }


  actionOpenChat(): void {
    this.interface.chat_open  = !this.interface.chat_open;
    if(this.interface.chat_open) {

    } else {
      this.interface.messages = [];
    }

  }


  actionEnterMessages(event) {
    this.interface._cache.message = event.target.value;
  }
  actionSubmitQuestion(): void {
    this.interface.messages.push({
      question: this.interface._cache.message,
      show: true,
      answer: undefined
    });
    this.websocketService.initAIQuestion(this.interface._cache.message);
    this.interface._cache.message = undefined;
  }

  preventEnter(event) {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  }

  @HostListener('document:keydown.enter', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.key == 'Enter' && this.interface._cache.message.length > 0) {
      this.actionSubmitQuestion();
    }
  }

}
