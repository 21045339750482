import { Injectable } from '@angular/core';
import { AssignmentModel } from '../../../_models/assignment.model';
import { HttpClient } from '@angular/common/http';
import { API } from '../../api';
import { Observable } from 'rxjs';
import { VideoModel } from '../../../_models/video.model';

@Injectable({
  providedIn: 'root',
})
export class VideosService {
  // default parent of category: Kindergarten grade
  private DefaultParentVideo = '5e703269f73e83064765eb0f';

  constructor(private http: HttpClient) {}

  createVideo(data) {
    return this.http.post<VideoModel>(API.url('videos'), data);
  }

  editVideo(data: AssignmentModel) {
    return this.http.patch<VideoModel>(API.url('videos/' + data._id), data);
  }

  deleteVideo(id: string) {
    return this.http.delete<VideoModel>(API.url('videos/' + id));
  }

  getVideosList(): Observable<{
    breadcrumbs: [];
    parent: null;
    children: [];
  }> {
    return this.http.get<{
      breadcrumbs: [];
      parent: null;
      children: [];
    }>(API.url('videos'));
  }

  getVideosListByParent(parentId): Observable<VideoModel[]> {
    return this.http.get<VideoModel[]>(API.url('videos/category/' + parentId));
  }

  getVideo(videoID: string): Observable<VideoModel> {
    return this.http.get<VideoModel>(API.url('videos/video/' + videoID));
  }
}
