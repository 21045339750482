import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { ModalCloseAction } from '../../_stores/modal/_actions/modal.actions';
import { CreateVideo } from '../../_stores/videos/_actions/videos.actions';
import { VideosState } from '../../_stores/videos/_state/videos.state';

@Component({
  selector: 'ui-control-video-add',
  templateUrl: './ui-control-video-add.component.html',
  styleUrls: ['./ui-control-video-add.component.scss'],
})
export class UiControlVideoAddComponent implements OnInit {
  public interface = {
    new_video: {
      slug: '',
      title: '',
      options: {
        visible: false,
        url: '',
        vimeoid: '',
      },
      description: '',
      thumbnail: '',
      parent: '',
    },
    videoVisible: false,
    _cache: {
      validate_form_fields: {
        videoDescription: '',
        videoThumbnail: '',
        videoTitle: '',
        videoSlug: '',
        vimeoID: '',
        videoUrl: '',
      },
      videoVisible: false,
      parent_video: undefined,
      open_select_category: false,
      open_select_category_type: false,
      open_select_material: false,
    },
    _cache_error: { fields: '   ' },
    loading: false,
  };

  constructor(private store: Store, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.store.select(VideosState.selectVideosByModalId).subscribe((category) => {
      this.interface._cache = { ...this.interface._cache, parent_video: category };
    });
  }

  actionClose() {
    this.store.dispatch(new ModalCloseAction());
  }

  actionEnterVideoDescription(event) {
    this.interface._cache.validate_form_fields.videoDescription = event.target.value;
    this.actionValidateFields();
    this.cdr.detectChanges();
  }

  actionValidateFields() {
    this.interface._cache_error.fields = '';
    Object.keys(this.interface._cache.validate_form_fields).map((item) => {
      if (!!this.interface._cache.validate_form_fields[item]) {
      } else {
        this.interface._cache_error.fields = this.interface._cache_error.fields.concat(item.toString());
      }
    });
    this.cdr.detectChanges();
  }

  actionEnterVimeoID(event) {
    this.interface._cache.validate_form_fields.vimeoID = event.target.value;
    this.interface._cache.validate_form_fields.videoUrl = 'https://argoprep.com/?ept_vimeo&type=link_file&id='.concat(
      this.interface._cache.validate_form_fields.vimeoID,
    );
    this.interface._cache.validate_form_fields.videoThumbnail =
      'https://argoprep.com/?ept_vimeo&type=poster&id='.concat(this.interface._cache.validate_form_fields.vimeoID);
    this.actionValidateFields();
    this.cdr.detectChanges();
  }

  actionEnterVideoUrl(event) {
    // this.interface._cache.validate_form_fields.videoUrl = event.target.value;
    // this.interface._cache.validate_form_fields.videoUrl + this.interface._cache.validate_form_fields.videoID;
    // this.cdr.detectChanges();
  }

  actionSelectVisible(event) {
    this.interface.videoVisible = !this.interface.videoVisible;
  }

  private initMaterialsOfCategory() {}

  actionEnterTitle(event) {
    this.interface._cache.validate_form_fields.videoTitle = event.target.value;
    this.interface._cache.validate_form_fields.videoSlug = this.interface._cache.validate_form_fields.videoTitle
      .toLowerCase()
      .replace(/ /g, '_');
    this.actionValidateFields();
    this.cdr.detectChanges();
  }

  actionAddVideo() {
    this.interface.new_video = {
      title: this.interface._cache.validate_form_fields.videoTitle,
      slug: this.interface._cache.validate_form_fields.videoSlug,
      options: {
        visible: this.interface.videoVisible,
        url: this.interface._cache.validate_form_fields.videoUrl,
        vimeoid: this.interface._cache.validate_form_fields.vimeoID,
      },
      description: this.interface._cache.validate_form_fields.videoDescription,
      thumbnail: this.interface._cache.validate_form_fields.videoThumbnail,
      parent: this.interface._cache.parent_video,
    };
    this.store.dispatch(new CreateVideo({ data: this.interface.new_video }));
  }
}
