import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngxs/store';
import {
  LoadControlRoles,
  LoadControlUsers,
  SearchControlUsers,
  UpdateControlUsersPage,
} from '../../_stores/control/_actions/control.actions';
import { ControlState } from '../../_stores/control/_state/control.state';
import { ModalOpenAction, ModalOpenActionWithID } from '../../_stores/modal/_actions/modal.actions';
import { ActivatedRoute } from '@angular/router';
import { EventTestKlaviyo } from '../../_stores/event_ws/_actions/events.actions';
import { NotificationOpen } from '../../_stores/notification/_actions/notification.actions';

@Component({
  selector: 'ui-control-users',
  templateUrl: './ui-control-users.component.html',
  styleUrls: ['./ui-control-users.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UiControlUsersComponent implements OnInit, OnDestroy {
  interface: any = {
    pages: [],
    currentPage: 1,
    data: [],
    perPage: 0,
    totalData: 0,
    totalPage: 0,
    period: 'all',
    _cache: {
      period: {
        start: new Date(),
        end: new Date(),
      },
      roles: [],
      role_selected: undefined,
      open_roles_selected: false,
    },
  };
  public role: any;
  public schools: any;

  constructor(
    private store: Store,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private activatedRoute: ActivatedRoute,
  ) {
    this.store.dispatch(new LoadControlRoles());
    this.role = this.route.snapshot.paramMap.get('role');
    this.schools = this.route.snapshot.paramMap.get('school');
    //console.log(this.route.snapshot.paramMap);
    this.activatedRoute.fragment.subscribe((x) => {
      this.interface.currentPage = x;
      //this.store.dispatch(new LoadControlUsers(this.interface.currentPage, this.role, this.schools,this.interface.period));
      this.actionSelectPeriod(this.interface.period);
    });
  }

  ngOnDestroy(): void {
    this.store.dispatch(new UpdateControlUsersPage(1));
  }

  ngOnInit(): void {
    this.initData();
    this.initRoles();
  }

  initData(): void {
    this.store.select(ControlState.selectUsers).subscribe((_data) => {
      this.interface.data = _data.data;
      this.interface = {
        ...this.interface,
        currentPage: _data.currentPage,
        totalPage: _data.totalPage,
        totalData: _data.totalData,
        pages: this.getPages(this.interface.currentPage, this.interface.totalPage),
      };
      this.cdr.detectChanges();
    });
  }

  initRoles(): void {
    this.store.select(ControlState.selectRoles).subscribe((_data) => {
      if (_data) {
        this.interface._cache.roles = _data;
        this.interface._cache.role_selected = 'All';
        if (this.role) {
          this.interface._cache.role_selected = this.interface._cache.roles.find((role) => role._id == this.role)?.name;
        }
        this.cdr.detectChanges();
      }
    });
  }

  actionOpenAddDataModal(): void {
    this.store.dispatch(new ModalOpenAction('control_user_add'));
  }

  actionUpdateKlavio(): void {
    this.store.dispatch(new EventTestKlaviyo());
    this.store.dispatch(new NotificationOpen('klaviyo_update'));
  }

  actionOpenEditDataItemModal(_id): void {
    // this.store.dispatch(new ModalOpenAction('phone-verify', false));
    this.store.dispatch(new ModalOpenActionWithID('control_user_edit', _id));
  }

  actionOpenDeleteDataItemModal(_id): void {
    this.store.dispatch(new ModalOpenActionWithID('control_user_delete', _id, false));
  }

  actionToggleSelectRole(): void {
    this.interface._cache.open_roles_selected = !this.interface._cache.open_roles_selected;
  }

  actionFirstPage(): void {
    this.store.dispatch(new LoadControlUsers(1, this.role));
  }

  actionPrevPage(): void {
    this.store.dispatch(new LoadControlUsers(this.interface.currentPage - 1, this.role));
  }

  actionLoadPage(page): void {
    this.store.dispatch(new LoadControlUsers(page));
  }

  actionNextPage(event): void {
    let element = document.querySelector('thead');
    element.scrollIntoView({ block: 'center', behavior: 'smooth' });
    this.interface = {
      ...this.interface,
      currentPage: event,
    };
    this.interface.currentPage = event;
    //this.store.dispatch(new LoadControlUsers(this.interface.currentPage, this.role, this.schools));
    this.actionSelectPeriod(this.interface.period);
    this.cdr.detectChanges();
  }

  actionLastPage(): void {
    this.store.dispatch(new LoadControlUsers(this.interface.totalPage, this.role));
  }

  actionSearch(event) {
    if (event.target.value && event.target.value.length > 0) {
      this.store.dispatch(new SearchControlUsers(event.target.value));
      this.initData();
      this.cdr.detectChanges();
    } else {
      this.cdr.detectChanges();
    }
  }

  actionSelectRole(role): void {
    this.interface._cache.open_roles_selected = false;
    if (role == 'All') {
      this.interface._cache.role_selected = 'All';
    } else {
      this.interface._cache.role_selected = role.name;
      this.role = role._id;
      this.actionSelectPeriod(this.interface.period);
    }
  }

  actionSelectPeriod(period): void {
    this.interface.period = period;
    if (period == 'day') {
      this.interface._cache.period.start = new Date();
      this.interface._cache.period.end = new Date();
    }
    if (period == 'week') {
      this.interface._cache.period.start = new Date(this.interface._cache.period.end.getTime() - 86400000 * 7);
    }
    if (period == 'month') {
      this.interface._cache.period.start = new Date(this.interface._cache.period.end.getTime() - 86400000 * 30);
    }
    if (period == 'all') {
      this.store.dispatch(new LoadControlUsers(this.interface.currentPage, this.role, this.schools));
      return;
    }

    this.store.dispatch(
      new LoadControlUsers(
        this.interface.currentPage,
        this.role,
        this.schools,
        this.interface._cache.period.start.getTime(),
        this.interface._cache.period.end.getTime(),
      ),
    );
  }

  actionSelectPeriodDate(event): void {
    this.interface._cache.period.start = event;
    this.interface._cache.period.end = event;
    this.interface.currentPage = 1;
    this.actionSelectPeriod('custom');
  }

  private getPages(currentPage: number, totalPage: number) {
    if (currentPage <= 10) {
      if (totalPage < 10) {
        return [...Array(totalPage).keys()].map((x) => ++x);
      }
      return [...Array(10).keys()].map((x) => ++x);
    }
    if (currentPage > 10) {
      if (currentPage >= totalPage - 2) {
        return [totalPage - 3, totalPage - 2, totalPage - 1, totalPage];
      } else {
        return [currentPage - 1, currentPage, currentPage + 1];
      }
    }
  }
}
