import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {UiPageControlComponent} from "./ui-page-control.component";
import {RouterModule} from "@angular/router";
import {ActivateProtectedChildGuard} from "../../_guards/activate-protected.guard";
import {LoadProtectedGuard} from "../../_guards/load-protected.guard";
import {NgxsModule} from "@ngxs/store";
import {ControlState} from "../../_stores/control/_state/control.state";
import { UiControlDashboardModule } from "../../_ui_control/ui-control-dashboard/ui-control-dashboard.module";



@NgModule({
  declarations: [UiPageControlComponent],
  exports: [UiPageControlComponent],
  imports: [
    CommonModule,
    NgxsModule.forFeature([ControlState]),
    RouterModule.forChild([
      {
        path: "",
        component: UiPageControlComponent,
        canLoad: [LoadProtectedGuard],
        canActivateChild: [ActivateProtectedChildGuard],
        children: [
          {
            path: "users/:id/info",
            loadChildren: () =>
              import("../../_ui_control/ui-control-user/ui-control-user.module").then((mod) => mod.UiControlUserModule)
          },
          {
            path: "users",
            loadChildren: () => import("../../_ui_control/ui-control-users/ui-control-users.module").then((mod) => mod.UiControlUsersModule)
          },
          {
            path: "roles",
            loadChildren: () =>
              import("../../_ui_control/ui-control-roles/ui-control-roles.module").then((mod) => mod.UiControlRolesModule)
          },
          {
            path: "statistic",
            loadChildren: () =>
              import("../../_ui_control/ui-control-statistic/ui-control-statistic.module").then((mod) => mod.UiControlStatisticModule)
          },
          {
            path: "practices/grades",
            loadChildren: () =>
              import("../../_ui_control/ui-control-grades/ui-control-grades.module").then((mod) => mod.UiControlGradesModule)
          },
          {
            path: "practices/categories",
            loadChildren: () =>
              import("../../_ui_control/ui-control-categories/ui-control-categories.module").then((mod) => mod.UiControlCategoriesModule)
          },
          {
            path: "practices/questions",
            loadChildren: () =>
              import("../../_ui_control/ui-control-questions/ui-control-questions.module").then((mod) => mod.UiControlQuestionsModule)
          },
          {
            path: "practices/videos",
            loadChildren: () =>
              import("../../_ui_control/ui-control-videos/ui-control-videos.module").then((mod) => mod.UiControlVideosModule)
          },
          {
            path: "practices/worksheets",
            loadChildren: () =>
              import("../../_ui_control/ui-control-worksheets/ui-control-worksheets.module").then((mod) => mod.UiControlWorksheetsModule)
          },
          {
            path: "practices/books",
            loadChildren: () =>
              import("../../_ui_control/ui-control-books/ui-control-books.module").then((mod) => mod.UiControlBooksModule)
          },

          {
            path: "schools/licenses",
            loadChildren: () =>
              import("../../_ui_control/ui-control-schools-licenses/ui-control-schools-licenses.module").then((mod) => mod.UiControlSchoolsLicensesModule)
          },
          {
            path: "schools/no-licenses",
            loadChildren: () =>
              import("../../_ui_control/ui-control-schools-no-license/ui-control-schools-no-license.module").then((mod) => mod.UiControlSchoolsNoLicenseModule)
          },
          {
            path: "schools/:id/detail",
            loadChildren: () =>
              import("../../_ui_control/ui-control-school/ui-control-school.module").then((mod) => mod.UiControlSchoolModule)
          },
          {
            path: "schools",
            loadChildren: () =>
              import("../../_ui_control/ui-control-schools/ui-control-schools.module").then((mod) => mod.UiControlSchoolsModule)
          },
          {
            path: "**",
            redirectTo: "/"
          }
        ]
      }
    ]),
    UiControlDashboardModule
  ]
})
export class UiPageControlModule { }
