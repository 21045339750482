import {
  Action,
  NgxsAfterBootstrap,
  NgxsOnChanges,
  NgxsOnInit,
  NgxsSimpleChange,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import { Injectable } from '@angular/core';
import { SetCrumbs } from '../_actions/crumbs.actions';
import { RouterNavigation } from '@ngxs/router-plugin';
import { PracticeState } from '../../practice/_state/practice.state';
import { first, map } from 'rxjs';
import { BooksState } from '../../books/_state/books.state';
import { AssignmentsState } from '../../asignments/_state/assignments.state';
import { ClassesState } from '../../classes/_state/classes.state';
import { GetSchool } from '../../schools/_actions/school.actions';
import { SchoolsState } from '../../schools/_state/schools.state';
import { RouterSelectors } from '../../routing/router.selectors';

export class CrumbsStateModel {
  crumbs: {
    title: string;
    subtitle: string;
  };
}

@State<CrumbsStateModel>({
  name: 'SAP_CRUMBS',
  defaults: {
    crumbs: {
      title: ' ',
      subtitle: ' ',
    },
  },
})
@Injectable()
export class CrumbsState implements NgxsOnInit, NgxsOnChanges, NgxsAfterBootstrap {
  constructor(private store: Store) {}

  ngxsAfterBootstrap(ctx?: StateContext<CrumbsStateModel>): void {}

  ngxsOnChanges(change: NgxsSimpleChange): void {}

  ngxsOnInit(ctx?: StateContext<any>): any {}

  @Action(SetCrumbs)
  setCrumbs(ctx: StateContext<CrumbsStateModel>, payload) {
    ctx.setState(payload);
  }

  @Action(RouterNavigation)
  routerNavigation(ctx: StateContext<CrumbsStateModel>, payload) {
    const url = payload.routerState.url;

    if (url === '/') {
      ctx.patchState({
        crumbs: {
          title: 'Dashboard',
          subtitle: 'Welcome back',
        },
      });
    }

    if (url === '/practices') {
      ctx.patchState({
        crumbs: {
          title: 'Practices',
          subtitle: 'Materials to improve your knowledge',
        },
      });
    }

    if (url === '/practices/worksheets') {
      const state = ctx.getState();
      ctx.patchState({
        crumbs: {
          title: 'Worksheets',
          subtitle: 'Awesome ArgoPrep worksheets',
        },
      });
    }

    if (url === '/books') {
      const state = ctx.getState();
      ctx.patchState({
        crumbs: {
          title: 'Books',
          subtitle: 'Access all of our workbooks',
        },
      });
    }

    if (url === '/worksheet-generators') {
      const state = ctx.getState();
      ctx.patchState({
        crumbs: {
          title: 'Worksheet generators',
          subtitle: 'With ArgoPrep’s worksheet generator, you can create worksheets in less than a minute!',
        },
      });
    }

    if (url === '/students') {
      const state = ctx.getState();
      ctx.patchState({
        crumbs: {
          title: 'Students',
          subtitle: 'The students in your school that you teach',
        },
      });
    }

    if (url === '/parents') {
      const state = ctx.getState();
      ctx.patchState({
        crumbs: {
          title: 'Parents',
          subtitle: 'Parents from your classroom',
        },
      });
    }

    if (url === '/reports') {
      const state = ctx.getState();
      ctx.patchState({
        crumbs: {
          title: 'Reports',
          subtitle: 'Get a deeper insight into your class and student statistics',
        },
      });
    }
    if (url === '/reports/classes') {
      const state = ctx.getState();
      ctx.patchState({
        crumbs: {
          title: 'Classes reports',
          subtitle: 'Get a deeper insight into your class statistics',
        },
      });
    }
    if (url === '/reports/practice') {
      const state = ctx.getState();
      ctx.patchState({
        crumbs: {
          title: 'Practice reports',
          subtitle: 'Get a deeper insight into your student practice statistics',
        },
      });
    }
    if (url === '/reports/students') {
      const state = ctx.getState();
      ctx.patchState({
        crumbs: {
          title: 'Users reports',
          subtitle: 'Get a deeper insight into your student and parents statistics',
        },
      });
    }

    if (url == '/classes') {
      ctx.patchState({
        crumbs: {
          title: 'Classes',
          subtitle: 'Student classes for preparation ',
        },
      });
    }

    if (url == '/assignments') {
      ctx.patchState({
        crumbs: {
          title: 'Assignments',
          subtitle: 'Student assignments for preparation',
        },
      });
    }

    if (url == '/assignments/builder') {
      ctx.patchState({
        crumbs: {
          title: 'Assignment Builder',
          subtitle: 'Create assignments for students to complete',
        },
      });
    }

    if (url == '/homeworks') {
      ctx.patchState({
        crumbs: {
          title: 'Homework',
          subtitle: 'Tasks you have been assigned to complete',
        },
      });
    }

    if (url.includes('practice_drills')) {
      ctx.patchState({
        crumbs: {
          title: 'Practices Drills',
          subtitle: 'Practices',
        },
      });
    }

    if (url.includes('practice_questions')) {
      ctx.patchState({
        crumbs: {
          title: 'Practices Questions',
          subtitle: 'Practices',
        },
      });
    }

    if (url.includes('video_lectures')) {
      ctx.patchState({
        crumbs: {
          title: 'Video Lectures',
          subtitle: 'Video',
        },
      });
    }

    if (url.includes('practices') && payload.routerState.params.id) {
      this.store
        .select(PracticeState.selectCategoryById(payload.routerState.params.id))
        .pipe(
          first((cat) => !!cat),
          map((cat) => {
            if (url.includes('result')) {
              ctx.patchState({
                crumbs: {
                  title: 'Practice result',
                  subtitle: cat.title,
                },
              });
            } else if (url.includes('video_lectures')) {
              ctx.patchState({
                crumbs: {
                  title: cat.title,
                  subtitle: !!cat.description.trim() ? cat.description : 'Video lectures',
                },
              });
            } else {
              ctx.patchState({
                crumbs: {
                  title: cat.title,
                  subtitle: !!cat.description.trim() ? cat.description : 'Practice quiz',
                },
              });
            }
          }),
        )
        .subscribe();
    }

    if (url.includes('books/e-book' && payload.routerState.params.id)) {
      this.store
        .select(BooksState.selectBookById(payload.routerState.params.id))
        .pipe(
          first((book) => !!book),
          map((book) => {
            ctx.patchState({
              crumbs: {
                title: book.title,
                subtitle: book.description,
              },
            });
          }),
        )
        .subscribe();
    }

    if (url.includes('classes') && payload.routerState.params.class_id) {
      this.store
        .select(ClassesState.selectClassByRouteId)
        .pipe(first((_class) => !!_class))
        .subscribe((_class) => {
          ctx.patchState({
            crumbs: {
              title: _class.title,
              subtitle: !!_class.description.trim() ? _class.description : 'Class',
            },
          });
        });
    }

    if (url.includes('assignments' && payload.routerState.params.assignment_id)) {
      this.store
        .select(AssignmentsState.selectAssignmentById(payload.routerState.params.assignment_id))
        .pipe(
          first((assignment) => !!assignment),
          map((assignment) => {
            ctx.patchState({
              crumbs: {
                title: assignment.title,
                subtitle: !!assignment.description.trim() ? assignment.description : 'Assignment',
              },
            });
          }),
        )
        .subscribe();
    }

    if (url.includes('homeworks' && payload.routerState.params.homeworks_id)) {
      this.store
        .select(AssignmentsState.selectHomeworkById(payload.routerState.params.homeworks_id))
        .pipe(first((assignment) => !!assignment))
        .subscribe((assignment) => {
          ctx.patchState({
            crumbs: {
              title: assignment.title,
              subtitle: !!assignment.description.trim() ? assignment.description : 'Homework',
            },
          });
        });
    }

    if (
      url.includes(
        'homeworks' &&
          payload.routerState.params.type &&
          payload.routerState.params.id &&
          payload.routerState.params.assignment_id,
      )
    ) {
      this.store
        .select(
          AssignmentsState.selectHomeworkByIdMaterialId(
            payload.routerState.params.assignment_id,
            payload.routerState.params.id,
          ),
        )
        .pipe(first((assignment) => !!assignment))
        .subscribe((assignment) => {
          ctx.patchState({
            crumbs: {
              title: assignment.title,
              subtitle: 'Homework',
            },
          });
        });
    }

    if (url == '/control/users') {
      ctx.patchState({
        crumbs: {
          title: 'Users',
          subtitle: 'Registered users of the system',
        },
      });
    }
    if (url.includes('users' && 'info')) {
      ctx.patchState({
        crumbs: {
          title: 'User',
          subtitle: 'Detailed information about user',
        },
      });
    }
    if (url == '/control/roles') {
      ctx.patchState({
        crumbs: {
          title: 'Roles',
          subtitle: 'Control',
        },
      });
    }
    if (url == '/control/statistic') {
      ctx.patchState({
        crumbs: {
          title: 'Statistic',
          subtitle: 'Control',
        },
      });
    }
    if (url == '/control/practices/grades') {
      ctx.patchState({
        crumbs: {
          title: 'Grades',
          subtitle: 'Control',
        },
      });
    }
    if (url == '/control/practices/categories') {
      ctx.patchState({
        crumbs: {
          title: 'Categories',
          subtitle: 'Control',
        },
      });
    }
    if (url == '/control/practices/questions') {
      ctx.patchState({
        crumbs: {
          title: 'Questions',
          subtitle: 'Control',
        },
      });
    }
    if (url == '/control/practices/videos') {
      ctx.patchState({
        crumbs: {
          title: 'Videos',
          subtitle: 'Control',
        },
      });
    }
    if (url == '/control/practices/worksheets') {
      ctx.patchState({
        crumbs: {
          title: 'Worksheets',
          subtitle: 'Control',
        },
      });
    }
    if (url == '/control/practices/books') {
      ctx.patchState({
        crumbs: {
          title: 'Books',
          subtitle: 'Control',
        },
      });
    }
    if (url == '/control/schools') {
      ctx.patchState({
        crumbs: {
          title: 'Schools',
          subtitle: 'Control',
        },
      });
    }
    if (url.includes('schools' && 'detail')) {
      let id = '';
      this.store.select(RouterSelectors.params).subscribe((params) => {
        id = params.id;
      });
      this.store.dispatch(new GetSchool(id)).subscribe((value) => {
        this.store.select(SchoolsState.selectSchool).subscribe({
          next: (school) => {
            ctx.patchState({
              crumbs: {
                title: school && school.schoolName ? school.schoolName : '',
                subtitle: school && school.address ? school.address : '',
              },
            });
          },
        });
      });
    }
    if (url == '/control/schools/active') {
      ctx.patchState({
        crumbs: {
          title: 'Schools',
          subtitle: 'Active schools with licenses',
        },
      });
    }
    if (url == '/control/schools/licenses') {
      ctx.patchState({
        crumbs: {
          title: 'Schools licenses',
          subtitle: 'Control',
        },
      });
    }
    if (url == '/control/schools/no-licenses') {
      ctx.patchState({
        crumbs: {
          title: 'Schools without licenses',
          subtitle: 'Control',
        },
      });
    }

  }

  @Selector()
  static selectCrumbs(state: CrumbsStateModel) {
    return state.crumbs;
  }
}
