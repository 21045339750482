import {
  Action,
  NgxsAfterBootstrap,
  NgxsOnChanges,
  NgxsOnInit,
  NgxsSimpleChange,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import { Injectable } from '@angular/core';
import {
  SendWebSocketMessage,
  WebSocketConnected,
  WebSocketDisconnected,
  WebsocketMessageError
} from "@ngxs/websocket-plugin";
import {
  EventAssignmentCreated,
  EventChangeLanguage,
  EventClassCreated,
  EventEBookClosed,
  EventEBookOpened,
  EventEBookPrinted,
  EventInviteToClassLinkCopied,
  EventInviteToSchoolLinkCopied,
  EventOpenModalChangeLanguage,
  EventOpenPurchase, EventPracticeDrillsClose, EventPracticeDrillsOpen, EventPracticeQuestionClose,
  EventPracticeQuestionOpen,
  EventPracticeVideoCloseLectures,
  EventPracticeVideoOpenLectures,
  EventSchoolCreated,
  EventTestKlaviyo,
  EventUserDelete,
  EventWorksheetClosed,
  EventWorksheetDownloaded,
  EventWorksheetOpened,
  EventWorksheetPrinted
} from "../_actions/events.actions";

import { UserState } from '../../user/_state/user.state';
import { map } from 'rxjs/operators';
import { first } from 'rxjs';

export class EventsStateModel {}

@State<EventsStateModel>({
  name: 'SAP_EVENTS',
  defaults: {},
})
@Injectable()
export class EventsState implements NgxsOnInit, NgxsOnChanges, NgxsAfterBootstrap {
  constructor(private store: Store) {}

  ngxsAfterBootstrap(ctx?: StateContext<EventsStateModel>): void {}

  ngxsOnChanges(change: NgxsSimpleChange): void {}

  ngxsOnInit(ctx?: StateContext<any>): any {}

  @Action(WebSocketConnected)
  initSocket(ctx: StateContext<EventsStateModel>) {
    const state = ctx.getState();
  }

  @Action(WebSocketDisconnected)
  disconnectedSocket(ctx: StateContext<EventsStateModel>) {
    //console.log('Disconnected')
  }

  @Action(WebsocketMessageError)
  errorSocket(ctx: StateContext<EventsStateModel>) {
    //console.log('WebsocketMessageError')
  }

  @Action(EventEBookOpened)
  eBookOpened(ctx: StateContext<EventsStateModel>, data) {
    return this.store.select(UserState.selectUser).pipe(
      first((user) => !!user),
      map((user: any) => {
        const mes = {
          event: 'EventEBookOpened',
          data: {
            user: user._id,
            role: user.role._id,
            school: user.school._id,
            type: 'BookOpened',
            resource: data.payload.bookId,
          },
        };
        ctx.dispatch(new SendWebSocketMessage(mes));
      }),
    );
  }

  @Action(EventEBookClosed)
  eBookClosed(ctx: StateContext<EventsStateModel>, data) {
    return this.store.select(UserState.selectUser).pipe(
      first((user) => !!user),
      map((user: any) => {
        const mes = {
          event: 'EventEBookClosed',
          data: {
            user: user._id,
            role: user.role._id,
            school: user.school._id,
            type: 'BookClose',
            resource: data.payload.bookId,
          },
        };
        ctx.dispatch(new SendWebSocketMessage(mes));
      }),
    );
  }

  @Action(EventEBookPrinted)
  eventEBookPrinted(ctx: StateContext<EventsStateModel>, data) {
    return this.store.select(UserState.selectUser).pipe(
      first((user) => !!user),
      map((user: any) => {
        const mes = {
          event: 'EventEBookPrinted',
          data: {
            user: user._id,
            role: user.role._id,
            school: user.school._id,
            type: 'BookPrinted',
            resource: {
              book: data.payload.bookId,
              page: data.payload.bookPage,
            },
          },
        };
        ctx.dispatch(new SendWebSocketMessage(mes));
      }),
    );
  }

  @Action(EventClassCreated)
  eventClassCreated(ctx: StateContext<EventsStateModel>, data) {
    return this.store.select(UserState.selectUser).pipe(
      first((user) => !!user),
      map((user: any) => {
        const mes = {
          event: 'EventClassCreated',
          data: {
            user: user._id,
            role: user.role._id,
            school: user.school._id,
            type: 'ClassCreated',
            resource: data.payload.classID,
          },
        };
        ctx.dispatch(new SendWebSocketMessage(mes));
      }),
    );
  }

  @Action(EventAssignmentCreated)
  eventAssignmentCreated(ctx: StateContext<EventsStateModel>, data) {
    return this.store.select(UserState.selectUser).pipe(
      first((user) => !!user),
      map((user: any) => {
        const mes = {
          event: 'EventAssignmentCreated',
          data: {
            user: user._id,
            role: user.role._id,
            school: user.school._id,
            type: 'AssignmentCreated',
            resource: data.payload.assignmentId,
          },
        };
        ctx.dispatch(new SendWebSocketMessage(mes));
      }),
    );
  }

  @Action(EventUserDelete)
  eventUserDelete(ctx: StateContext<EventsStateModel>, data) {

    return this.store.select(UserState.selectUser).pipe(
      first((user) => !!user),
      map((user: any) => {
        const mes = {
          event: 'EventUserDelete',
          data: {
            user: user._id,
            role: user.role._id,
            school: user.school._id,
            type: 'UserDelete',
            resource: data.payload.userID,
          },
        };

        ctx.dispatch(new SendWebSocketMessage(mes));
      }),
    );
  }

  @Action(EventSchoolCreated)
  eventSchoolCreated(ctx: StateContext<EventsStateModel>, data) {
    return this.store.select(UserState.selectUser).pipe(
      first((user) => !!user),
      map((user: any) => {
        const mes = {
          event: 'EventSchoolCreated',
          data: {
            user: user._id,
            role: user.role._id,
            school: user.school._id,
            type: 'SchoolCreated',
            resource: data.payload.schoolId,
          },
        };
        ctx.dispatch(new SendWebSocketMessage(mes));
      }),
    );
  }

  @Action(EventWorksheetOpened)
  eventWorksheetOpened(ctx: StateContext<EventsStateModel>, data) {
    return this.store.select(UserState.selectUser).pipe(
      first((user) => !!user),
      map((user: any) => {
        const mes = {
          event: 'EventWorksheetOpened',
          data: {
            user: user._id,
            role: user.role._id,
            school: user.school._id,
            type: 'WorksheetOpened',
            resource: data.payload.worksheetId,
            data: {
              id: data.payload.worksheetId, //- ключ worksheet
              parent: data.payload.categoryId, //- ключ категории
            }
          },
        };
        ctx.dispatch(new SendWebSocketMessage(mes));
      }),
    );
  }
  @Action(EventWorksheetClosed)
  eventWorksheetClosed(ctx: StateContext<EventsStateModel>, data) {
    return this.store.select(UserState.selectUser).pipe(
      first((user) => !!user),
      map((user: any) => {
        const mes = {
          event: 'EventWorksheetClosed',
          data: {
            user: user._id,
            role: user.role._id,
            school: user.school._id,
            type: 'WorksheetClosed',
            resource: data.payload.worksheetId,
            data: {
              id: data.payload.worksheetId, //- ключ worksheet
              parent: data.payload.categoryId, //- ключ категории
            }
          },
        };
        ctx.dispatch(new SendWebSocketMessage(mes));
      }),
    );
  }

  @Action(EventWorksheetDownloaded)
  eventWorksheetDownloaded(ctx: StateContext<EventsStateModel>, data) {
    return this.store.select(UserState.selectUser).pipe(
      first((user) => !!user),
      map((user: any) => {
        const mes = {
          event: 'EventWorksheetDownloaded',
          data: {
            user: user._id,
            role: user.role._id,
            school: user.school._id,
            type: 'WorksheetDownloaded',
            resource: data.payload.worksheetId,
            data: {
              id: data.payload.worksheetId, //- ключ worksheet
              parent: data.payload.categoryId, //- ключ категории
            }
          },
        };
        ctx.dispatch(new SendWebSocketMessage(mes));
      }),
    );
  }

  @Action(EventWorksheetPrinted)
  eventWorksheetPrinted(ctx: StateContext<EventsStateModel>, data) {
    return this.store.select(UserState.selectUser).pipe(
      first((user) => !!user),
      map((user: any) => {
        const mes = {
          event: 'EventWorksheetPrinted',
          data: {
            user: user._id,
            role: user.role._id,
            school: user.school._id,
            type: 'WorksheetPrinted',
            resource: data.payload.worksheetId,
            data: {
              id: data.payload.worksheetId, //- ключ worksheet
              parent: data.payload.categoryId, //- ключ категории
            }
          },
        };
        ctx.dispatch(new SendWebSocketMessage(mes));
      }),
    );
  }

  @Action(EventInviteToSchoolLinkCopied)
  eventInviteToSchoolLinkCopied(ctx: StateContext<EventsStateModel>, data) {
    return this.store.select(UserState.selectUser).pipe(
      first((user) => !!user),
      map((user: any) => {
        const mes = {
          event: 'EventInviteToSchoolLinkCopied',
          data: {
            user: user._id,
            role: user.role._id,
            school: user.school._id,
            type: 'InviteToSchoolLinkCopied',
          },
        };
        ctx.dispatch(new SendWebSocketMessage(mes));
      }),
    );
  }

  @Action(EventInviteToClassLinkCopied)
  eventInviteToClassLinkCopied(ctx: StateContext<EventsStateModel>, data) {
    return this.store.select(UserState.selectUser).pipe(
      first((user) => !!user),
      map((user: any) => {
        const mes = {
          event: 'EventInviteToClassLinkCopied',
          data: {
            user: user._id,
            role: user.role._id,
            school: user.school._id,
            type: 'InviteToClassLinkCopied',
            resource: data.payload.classId,
          },
        };
        ctx.dispatch(new SendWebSocketMessage(mes));
      }),
    );
  }

  @Action(EventOpenModalChangeLanguage)
  eventOpenModalChangeLanguage(ctx: StateContext<EventsStateModel>, data) {
    return this.store.select(UserState.selectUser).pipe(
      first((user) => !!user),
      map((user: any) => {
        const mes = {
          event: 'EventOpenModalChangeLanguage',
          data: {
            user: user._id,
            role: user.role._id,
            school: user.school._id,
            type: 'OpenModalChangeLanguage',
          },
        };
        ctx.dispatch(new SendWebSocketMessage(mes));
      }),
    );
  }

  @Action(EventChangeLanguage)
  eventChangeLanguage(ctx: StateContext<EventsStateModel>, data) {
    return this.store.select(UserState.selectUser).pipe(
      first((user) => !!user),
      map((user: any) => {
        const mes = {
          event: 'EventChangeLanguage',
          data: {
            user: user._id,
            role: user.role._id,
            school: user.school._id,
            type: data.payload.change,
          },
        };
        ctx.dispatch(new SendWebSocketMessage(mes));
      }),
    );
  }

  @Action(EventTestKlaviyo)
  eventTestKlaviyo(ctx: StateContext<EventsStateModel>, data) {
    return this.store.select(UserState.selectUser).pipe(
      first((user) => !!user),
      map((user: any) => {
        const mes = {
          event: 'EventTestKlaviyo',
          data: {
            user: user._id,
            role: user.role._id,
            school: user.school._id,
            type: '',
          },
        };
        ctx.dispatch(new SendWebSocketMessage(mes));
      }),
    );
  }

  @Action(EventOpenPurchase)
  eventOpenPurchase(ctx: StateContext<EventsStateModel>, data) {
    return this.store.select(UserState.selectUser).pipe(
      first((user) => !!user),
      map((user: any) => {
        const mes = {
          event: 'EventOpenPurchase',
          data: {
            user: user._id,
            role: user.role._id,
            school: user.school._id,
            type: 'EventOpenPurchase',
          },
        };
        ctx.dispatch(new SendWebSocketMessage(mes));
      }),
    );
  }


  @Action(EventPracticeVideoOpenLectures)
  eventPracticeVideoOpenLectures(ctx: StateContext<EventsStateModel>, data) {
    return this.store.select(UserState.selectUser).pipe(
      first((user) => !!user),
      map((user: any) => {
        const mes = {
          event: 'EventPracticeVideoOpenLectures',
          data: {
            user: user._id,
            role: user.role._id,
            school: user.school._id,
            type: 'PracticeVideoOpenLectures',
            resource: data.payload.categoryId,
            data: {
              key: 'Practice',
              type: 'Category',
              id: data.payload.categoryId, //- ключ category
              tracker: null,
              parent: data.payload.categoryId, //- ключ категории
            }
          },
        };
        ctx.dispatch(new SendWebSocketMessage(mes));
      }),
    );
  }
  @Action(EventPracticeVideoCloseLectures)
  eventPracticeVideoCloseLectures(ctx: StateContext<EventsStateModel>, data) {
    return this.store.select(UserState.selectUser).pipe(
      first((user) => !!user),
      map((user: any) => {
        const mes = {
          event: 'EventPracticeVideoCloseLectures',
          data: {
            user: user._id,
            role: user.role._id,
            school: user.school._id,
            type: 'PracticeVideoCloseLectures',
            resource: data.payload.categoryId,
            data: {
              key: 'Practice',
              type: 'Category',
              id: data.payload.categoryId, //- ключ category
              tracker: null,
              parent: data.payload.categoryId, //- ключ категории
            }
          },
        };
        ctx.dispatch(new SendWebSocketMessage(mes));
      }),
    );
  }


  @Action(EventPracticeQuestionOpen)
  eventPracticeQuestionOpen(ctx: StateContext<EventsStateModel>, data) {
    return this.store.select(UserState.selectUser).pipe(
      first((user) => !!user),
      map((user: any) => {
        const mes = {
          event: 'EventPracticeQuestionOpen',
          data: {
            user: user._id,
            role: user.role._id,
            school: user.school._id,
            type: 'PracticeQuestionOpen',
            resource: data.payload.categoryId,
            data: {
              key: 'Practice',
              type: 'Category',
              id: data.payload.categoryId, //- ключ category
              tracker: null,
              parent: data.payload.categoryId, //- ключ категории
            }
          },
        };
        ctx.dispatch(new SendWebSocketMessage(mes));
      }),
    );
  }
  @Action(EventPracticeQuestionClose)
  eventPracticeQuestionClose(ctx: StateContext<EventsStateModel>, data) {
    return this.store.select(UserState.selectUser).pipe(
      first((user) => !!user),
      map((user: any) => {
        const mes = {
          event: 'EventPracticeQuestionClose',
          data: {
            user: user._id,
            role: user.role._id,
            school: user.school._id,
            type: 'PracticeQuestionClose',
            resource: data.payload.categoryId,
            data: {
              key: 'Practice',
              type: 'Category',
              id: data.payload.categoryId, //- ключ category
              tracker: null,
              parent: data.payload.categoryId, //- ключ категории
            }
          },
        };
        ctx.dispatch(new SendWebSocketMessage(mes));
      }),
    );
  }



  @Action(EventPracticeDrillsOpen)
  eventPracticeDrillsOpen(ctx: StateContext<EventsStateModel>, data) {
    return this.store.select(UserState.selectUser).pipe(
      first((user) => !!user),
      map((user: any) => {
        const mes = {
          event: 'EventPracticeDrillsOpen',
          data: {
            user: user._id,
            role: user.role._id,
            school: user.school._id,
            type: 'PracticeDrillsOpen',
            resource: data.payload.categoryId,
            data: {
              key: 'Practice',
              type: 'Category',
              id: data.payload.categoryId, //- ключ category
              tracker: null,
              parent: data.payload.categoryId, //- ключ категории
            }
          },
        };
        ctx.dispatch(new SendWebSocketMessage(mes));
      }),
    );
  }
  @Action(EventPracticeDrillsClose)
  eventPracticeDrillsClose(ctx: StateContext<EventsStateModel>, data) {
    return this.store.select(UserState.selectUser).pipe(
      first((user) => !!user),
      map((user: any) => {
        const mes = {
          event: 'EventPracticeDrillsClose',
          data: {
            user: user._id,
            role: user.role._id,
            school: user.school._id,
            type: 'PracticeDrillsClose',
            resource: data.payload.categoryId,
            data: {
              key: 'Practice',
              type: 'Category',
              id: data.payload.categoryId, //- ключ category
              tracker: null,
              parent: data.payload.categoryId, //- ключ категории
            }
          },
        };
        ctx.dispatch(new SendWebSocketMessage(mes));
      }),
    );
  }

}
