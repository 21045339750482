import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from "@angular/core";
import { API } from "../../_stores/api";
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute, Router } from "@angular/router";
import { RoleModel } from "../../_models/role.model";
import { SchoolModel } from "../../_models/school.model";
import { UserModel } from "../../_models/user.model";
import { ClassModel } from "../../_models/class.model";
import { UserService } from "../../_stores/user/_service/user.service";
import { ModalOpenAction } from "../../_stores/modal/_actions/modal.actions";
import { Store } from "@ngxs/store";
import { AuthLogin } from "../../_stores/auth/_actions/auth.actions";

@Component({
  selector: 'ui-page-invite',
  templateUrl: './ui-page-invite.component.html',
  styleUrls: ['./ui-page-invite.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UiPageInviteComponent implements OnInit {
  interface: {
    loading: boolean,
    error: boolean,
    submit: boolean,
    user: {
      firstName: string,
      lastName: string,
      email: string,
      password: string,
      invite: number
    },
    teacher: UserModel,
    school: SchoolModel,
    class: ClassModel,
    role: RoleModel,
    _cache: {
      accepted: boolean,
      error_fields: string[],
    }
  } = {
    loading: false,
    error: false,
    submit: false,
    user: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      invite: null
    },
    teacher: undefined,
    school: undefined,
    class: undefined,
    role: undefined,
    _cache: {
      accepted: false,
      error_fields: []
    }
  }

  constructor(private store: Store,private cdr: ChangeDetectorRef,private http: HttpClient,private activatedRoute: ActivatedRoute,private userService: UserService,private router: Router) {
    this.interface.user.invite = Number(this.activatedRoute.snapshot.paramMap.get('invite_id'));
  }

  ngOnInit(): void {
    this.initInvite();
  }
  initInvite(): void {
    this.interface.loading = true;
    this.http.get<any>(API.url('invitations/' + this.interface.user.invite)).subscribe(
      (_invite) => {
        this.interface.teacher = _invite.teacher;
        this.interface.school = _invite.school;
        this.interface.class = _invite.class;
        this.interface.role = _invite.role;

        this.interface.loading = false;
        this.cdr.detectChanges();
      },
      (_error) => {
        this.interface.loading = false;
        this.interface.error = true;
        this.cdr.detectChanges();
      }
    )
  }

  actionEnterFirstName(event): void {
    this.interface.user.firstName = event.target.value;
  }
  actionEnterLastName(event): void {
    this.interface.user.lastName = event.target.value;
  }
  actionEnterLogin(event): void {
    this.interface.user.email = event.target.value.toLowerCase();
    this.interface._cache.error_fields = this.interface._cache.error_fields.filter((e) => e !== 'email');
    if(this.interface.role?._id != '62ebc2e38e33b7bc52c853c6') {
      this.actionGrantEmail();
    }
  }
  actionEnterPassword(event): void {
    this.interface.user.password = event.target.value.replace(/[^a-zA-Z0-9!@#$%^&*()]/gi, '',);
  }
  actionGeneratePassword(): void {
    this.interface.user.password = Math.random().toString(36).slice(-8);
  }
  actionConfirmAccepted(event): void {
    this.interface._cache.accepted = event.target.checked;
  }
  actionPasteEmail(event): boolean {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData?.getData('text');
    if(pastedText) {
      this.interface.user.email = pastedText.toLowerCase();
      if(this.interface.role?._id != '62ebc2e38e33b7bc52c853c6') {
        this.actionGrantEmail();
      }
    }
    return false;
  }
  actionGrantEmail(): boolean {
    let _grant = true;
    const _email_rules = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;
    if (!_email_rules.test(this.interface.user.email)) {
      this.interface._cache.error_fields.push('email');
      _grant = false;
    }
    return _grant;
  }

  actionGrantRegister(): boolean {
    let _grant = true;

    if(this.interface.user.firstName.length < 3) {
      _grant = false;
    }
    if(this.interface.user.lastName.length < 3) {
      _grant = false;
    }
    if(this.interface.user.email.length < 6) {
      _grant = false;
    }
    if(this.interface.user.password.length == 0) {
      _grant = false;
    }
    if(!this.interface._cache.accepted) {
      _grant = false;
    }
    if(this.interface._cache.accepted && this.interface.user.email.length > 5 && this.interface.role?._id != '62ebc2e38e33b7bc52c853c6') {
      _grant = this.actionGrantEmail();
    }


    return _grant;
  }
  actionGoBasicRegister(): void {
    this.router.navigate(['register']).then();
  }
  actionRegister(): void {
    this.interface.submit = true;
    this.userService.inviteUser(this.interface.user).subscribe({
      next: (_next) => {
        this.interface.submit = false;
        //this.router.navigate(['/']).then();
        this.store.dispatch(new AuthLogin(this.interface.user.email, this.interface.user.password));
        this.cdr.detectChanges();
      },
      error: (_error) => {
        this.interface.submit = false;
        if (_error.status === 401) {
          this.store.dispatch(new ModalOpenAction('not_whitelisted', false));
          this.cdr.detectChanges();
        } else if (_error.status === 422) {
          this.store.dispatch(new ModalOpenAction('exceeded_the_limit', false));
          this.cdr.detectChanges();
        }
        else {
          _error.error.message.forEach((_message) => {
            if(_message.includes('login')) {
              this.interface._cache.error_fields.push('email');
            }
          });
        }
        this.cdr.detectChanges();
      },
    });
  }
  actionCleverRegister(): void {
    this.interface.submit = true;
  }

}
