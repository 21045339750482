import { BookPageModel } from '../../../_models/book-page.model';

export class EventAssignmentCreated {
  public static readonly type = '[Event] Assignment Created';
  constructor(public payload: { assignmentId: string }) {}
}

export class EventUserDelete {
  public static readonly type = '[Event] User Delete';
  constructor(public payload: { userID: string }) {}
}

export class EventSchoolCreated {
  public static readonly type = '[Event] School Created';
  constructor(public payload: { schoolId: string }) {}
}

export class EventQuestionsAnswered {
  public static readonly type = '[Event] Questions Answered';
  constructor(public payload: { questions: string }) {}
}

export class EventClassCreated {
  public static readonly type = '[Event] Class Created';
  constructor(public payload: { classID: string }) {}
}

export class EventEBookOpened {
  public static readonly type = '[Event] EBook Opened';
  constructor(public payload: { bookId: string }) {}
}

export class EventEBookClosed {
  public static readonly type = '[Event] EBook Closed';
  constructor(public payload: { bookId: string }) {}
}
export class EventWorksheetOpened {
  public static readonly type = '[Event] Worksheets Opened';
  constructor(public payload: { worksheetId: string, categoryId: string }) {}
}
export class EventWorksheetClosed {
  public static readonly type = '[Event] Worksheets Closed';
  constructor(public payload: { worksheetId: string, categoryId: string }) {}
}

export class EventWorksheetDownloaded {
  public static readonly type = '[Event] Worksheets Downloaded';
  constructor(public payload: { worksheetId: string, categoryId: string }) {}
}

export class EventWorksheetPrinted {
  public static readonly type = '[Event] Worksheets Printed';
  constructor(public payload: { worksheetId: string, categoryId: string }) {}
}

export class EventEBookPrinted {
  public static readonly type = '[Event] EBook Printed';
  constructor(public payload: { bookPage: BookPageModel[]; bookId: string }) {}
}

export class EventInviteToSchoolLinkCopied {
  public static readonly type = '[Event] Invite To School Link Copied';
  constructor() {}
}

export class EventInviteToClassLinkCopied {
  public static readonly type = '[Event] Invite To Class Link Copied';
  constructor(public payload: { classId: string }) {}
}

export class EventOpenModalChangeLanguage {
  public static readonly type = '[Event] Open Modal Change Language';
  constructor() {}
}

export class EventChangeLanguage {
  public static readonly type = '[Event] Change Language';
  constructor(public payload: { change: string }) {}
}

export class EventPracticeVideoOpenLectures {
  public static readonly type = '[Event] Event Practice Video Open Lectures';
  constructor(public payload: { categoryId: string }) {}
}
export class EventPracticeVideoCloseLectures {
  public static readonly type = '[Event] Event Practice Video Close Lectures';
  constructor(public payload: { categoryId: string }) {}
}

export class EventPracticeQuestionOpen {
  public static readonly type = '[Event] Event Practice Question Open ';
  constructor(public payload: { categoryId: string }) {}
}
export class EventPracticeQuestionClose {
  public static readonly type = '[Event] Event Practice Question Close ';
  constructor(public payload: { categoryId: string }) {}
}


export class EventPracticeDrillsOpen {
  public static readonly type = '[Event] Event Practice Drills Open ';
  constructor(public payload: { categoryId: string }) {}
}
export class EventPracticeDrillsClose {
  public static readonly type = '[Event] Event Practice Drills Close ';
  constructor(public payload: { categoryId: string }) {}
}


export class EventTestKlaviyo {
  public static readonly type = '[Event] Event Test Klaviyo';
  constructor() {}
}

export class EventOpenPurchase {
  public static readonly type = '[Event] Event Open Purchase';
  constructor() {}
}
