import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngxs/store';
import { ModalOpenActionWithID } from '../../_stores/modal/_actions/modal.actions';
import { HttpClient } from '@angular/common/http';
import { API } from '../../_stores/api';
import { CategoryModel } from '../../_models/category.model';
import { PracticeState } from '../../_stores/practice/_state/practice.state';
import { GradeModel } from '../../_models/grade.model';
import { GetCategoryList, GetCategoryListByParent } from '../../_stores/categories/_actions/categories.actions';
import { CategoriesState } from '../../_stores/categories/_state/categories.state';
import { CategoriesBreadCrumbModel } from '../../_models/categories-bread-crumb-model';
import { ActivatedRoute } from '@angular/router';

interface UiControlCategoriesModel {
  isFromMaterial: boolean;
  category_parent: string;
  categoriesData: { data: { parent: CategoryModel; children: CategoryModel[] } };
  grades: GradeModel[];
  _cache: {
    category_types: [{ _id: ''; title: ''; description: ''; order: 0; slug: ''; thumbnail: '' }];
    current_category_parent_id: string;
    current_category_parent: CategoryModel;
    current_category_children: CategoryModel[];
    open_choose_grade: boolean;
    searched: string;
    choose_grade: GradeModel;
    chooseCategory: CategoryModel;
    bread_crumbs: CategoriesBreadCrumbModel[];
  };
}

@Component({
  selector: 'ui-control-categories',
  templateUrl: './ui-control-categories.component.html',
  styleUrls: ['./ui-control-categories.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UiControlCategoriesComponent implements OnInit {
  public interface: UiControlCategoriesModel = {
    isFromMaterial: true,
    category_parent: '',
    _cache: {
      bread_crumbs: [],
      category_types: [{ _id: '', description: '', order: 0, slug: '', thumbnail: '', title: '' }],
      chooseCategory: undefined,
      choose_grade: undefined,
      current_category_children: [],
      current_category_parent: undefined,
      current_category_parent_id: '',
      open_choose_grade: false,
      searched: '',
    },

    categoriesData: { data: { children: [], parent: undefined } },
    grades: [],
  };

  constructor(
    private store: Store,
    private cdr: ChangeDetectorRef,
    private http: HttpClient,
    private route: ActivatedRoute,
  ) {
    this.store.dispatch(new GetCategoryList());
  }

  ngOnInit(): void {
    this.initGrades();
    this.initTypesOfCategory();
    this.initData();
  }

  initData(): void {
    this.interface.category_parent = this.route.snapshot.paramMap.get('category');
    if (this.interface.isFromMaterial && this.interface.category_parent && this.interface.category_parent.length > 0) {
      this.store.dispatch(new GetCategoryListByParent({ parentId: this.interface.category_parent }));
      this.store.select(CategoriesState.selectCategoryListByParent).subscribe({
        next: (_data) => {
          if (_data) {
            this.interface._cache = {
              ...this.interface._cache,
              current_category_parent: _data.parent,
              current_category_parent_id: _data.parent?._id,
              current_category_children: _data.parent ? _data?.children : this.filteredChildren(_data?.children),
              bread_crumbs: _data.breadcrumbs,
            };
          }
          this.cdr.detectChanges();
        },
      });
    } else {
      this.store.select(CategoriesState.selectCategoryListByParent).subscribe({
        next: (_data) => {
          if (_data) {
            this.interface._cache = {
              ...this.interface._cache,
              current_category_parent: _data.parent,
              current_category_parent_id: _data.parent?._id,
              current_category_children: _data.parent ? _data?.children : this.filteredChildren(_data?.children),
              bread_crumbs: _data.breadcrumbs,
            };
          }
          this.cdr.detectChanges();
        },
      });
    }
  }

  actionOpenAddDataModal(): void {
    this.store.dispatch(
      new ModalOpenActionWithID('control_category_add', this.interface?._cache?.current_category_parent_id, true),
    );
  }

  actionOpenEditDataItemModal(_id): void {
    this.store.dispatch(new ModalOpenActionWithID('control_category_edit', _id));
  }

  actionOpenDeleteDataItemModal(_id): void {
    this.store.dispatch(new ModalOpenActionWithID('control_category_delete', _id));
  }

  actionSearchCategories(event) {
    if (event.target.value && event.target.value.length > 0) {
      this.interface.categoriesData.data.children = this.interface.categoriesData.data.children.filter((item) => {
        return item.title.toLowerCase().includes(event.target.value.toLowerCase());
      });
    } else {
      this.initData();
    }
  }

  actionToggleChooseGrade(): void {
    this.interface._cache.open_choose_grade = !this.interface._cache.open_choose_grade;
  }

  actionSearchCategoriesByGrade(grade: GradeModel) {
    this.interface._cache.open_choose_grade = false;
    this.interface._cache.choose_grade = grade;
    this.getCategoryByParent(this.interface._cache.choose_grade.category);
  }

  actionOpenChildrenOfCategory(item: CategoryModel) {
    this.getCategoryByParent(item._id);
  }

  getCategoryByParent(parent) {
    this.interface = { ...this.interface, isFromMaterial: false };
    this.store.dispatch(new GetCategoryListByParent({ parentId: parent }));
    this.initData();
    this.cdr.detectChanges();
  }

  getType(type) {
    let find = this.interface._cache.category_types.find((value) => value._id === type);
    return find ? find.title : '';
  }

  getMaterialType(item: CategoryModel): string {
    return item.materials.title;
  }

  private initTypesOfCategory() {
    this.http
      .get<[{ _id: ''; title: ''; description: ''; order: 0; slug: ''; thumbnail: '' }]>(API.url('/types'))
      .subscribe({
        next: (_types) => {
          this.interface._cache = { ...this.interface._cache, category_types: _types };
          this.cdr.detectChanges();
        },
        error: (err) => {
          //console.log('err: ', err);
        },
        complete: () => {},
      });
  }

  private filteredChildren(children: CategoryModel[] | undefined) {
    return children.filter((value) => this.isFiltersChildrenOfGrades(value));
  }

  private isFiltersChildrenOfGrades(value: CategoryModel) {
    return (
      value.slug === 'video_lectures' ||
      value.slug === 'practice_questions' ||
      value.slug === 'practice_drills' ||
      value.slug === 'worksheets'
    );
  }

  private initGrades() {
    this.store.select(PracticeState.selectGrades).subscribe((_grades) => {
      this.interface.grades = _grades;
      this.interface._cache = {
        ...this.interface._cache,
        choose_grade: this.interface.grades.find((value) => value.slug === 'kindergarten'),
      };
      this.cdr.detectChanges();
    });
  }
}
