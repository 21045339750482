import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { API } from '../../api';
import { RoleModel } from '../../../_models/role.model';
import { GradeModel } from '../../../_models/grade.model';
import { BookModel } from '../../../_models/book.model';
import { VideoModel } from '../../../_models/video.model';
import { LicenseModel } from '../../../_models/license.model';
import { WorksheetModel } from '../../../_models/worksheet.model';
import { DashboardDataModel } from '../../../_models/dashboard-data.model';
import { UserModel } from '../../../_models/user.model';

@Injectable({
  providedIn: 'root',
})
export class ControlService {
  constructor(private http: HttpClient) {}

  public getUsersList(
    page: number,
    limit: number,
    userRole?: string,
    userSchool?: string,
    beginDate?: string,
    endDate?: string,
  ) {
    let params: any = {
      page,
      limit,
    };
    params = !!userRole ? Object.assign(params, { userRole: userRole }) : params;
    params = !!userSchool ? Object.assign(params, { userSchool: userSchool }) : params;
    params = !!beginDate ? Object.assign(params, { beginDate: beginDate }) : params;
    params = !!endDate ? Object.assign(params, { endDate: endDate }) : params;
    return this.http.get<any>(API.url('users/controls'), { params });
  }

  public searchUsersList(search: number, limit: number) {
    let httpParams = new HttpParams().set('limit', limit);
    httpParams = httpParams.append('search', search);
    return this.http.get<any>(API.url('users/controls'), { params: httpParams });
  }

  public getRolesList() {
    return this.http.get<RoleModel[]>(API.url('roles'));
  }

  public createRole(data: RoleModel) {
    return this.http.post<RoleModel>(API.url('roles'), data);
  }

  public editRole(data) {
    return this.http.patch<RoleModel>(API.url('roles/' + data._id), data);
  }

  public deleteRole(roleID: string) {
    return this.http.delete<RoleModel>(API.url('roles/' + roleID));
  }

  public getGradesList() {
    return this.http.get<GradeModel[]>(API.url('grades'));
  }

  public getCategoriesList(page, limit) {
    return this.http.get<any>(API.url('categories/controls?page=' + page + '&limit=' + limit));
  }

  public createGrade(data: GradeModel) {
    return this.http.post<GradeModel>(API.url('grades'), data);
  }

  public editGrade(data) {
    return this.http.patch<GradeModel>(API.url('grades/' + data._id), data);
  }

  public deleteGrade(gradeID: string) {
    return this.http.delete<GradeModel>(API.url('grades/' + gradeID));
  }

  public getQuestionsList(page, limit) {
    return this.http.get<any>(API.url('question/controls?page=' + page + '&limit=' + limit));
  }

  public getVideosList() {
    return this.http.get<VideoModel[]>(API.url('videos'));
  }

  public getWorksheetsList(search, page, grade) {
    let httpParams = new HttpParams().set('limit', 10);
    if (grade && grade.length > 0) {
      httpParams = httpParams.append('grade', grade);
    }
    if (search && search.length > 0) {
      httpParams = httpParams.append('search', search);
    }
    if (page > 0) {
      httpParams = httpParams.append('page', page);
    }
    return this.http.get<{
      currentPage: number;
      data: WorksheetModel[];
      perPage: number;
      totalData: number;
      totalPage: number;
    }>(API.url('worksheets/controls'), { params: httpParams });
  }

  public getBooksList() {
    return this.http.get<BookModel[]>(API.url('books'));
  }

  public getSchoolsList(search, page, sort, state) {
    let httpParams = new HttpParams().set('limit', 10);
    if (sort && sort.length > 0) {
      httpParams = httpParams.append('sort', sort);
    }
    if (search && search.length > 0) {
      httpParams = httpParams.append('search', search);
    }
    if (state && state.length > 0 && state !== 'All states') {
      httpParams = httpParams.append('state', state === 'No data' ? '' : state);
    }
    if (page > 0) {
      httpParams = httpParams.append('page', page);
    }
    return this.http.get<any>(API.url('schools/controls'), { params: httpParams });
  }

  public getSchoolsListWithoutLicense(search, page, sort, state) {
    let httpParams = new HttpParams().set('limit', 10);
    if (sort && sort.length > 0) {
      httpParams = httpParams.append('sort', sort);
    }
    if (search && search.length > 0) {
      httpParams = httpParams.append('search', search);
    }
    if (state && state.state_name !== 'All states') {
      httpParams = httpParams.append('state', [state.state_code, state.state_name].toString());
    }
    if (page > 0) {
      httpParams = httpParams.append('page', page);
    }

    httpParams = httpParams.append('license', 'no');
    httpParams = httpParams.append('count_users', 1);

    return this.http.get<any>(API.url('schools/relatively-users'), { params: httpParams });
  }

  public getVideoList(page, limit) {
    return this.http.get<any>(API.url('videos/controls?page=' + page + '&limit=' + limit));
  }

  public getSchoolsLicenseList() {
    return this.http.get<{ license: LicenseModel; users: UserModel[] }[]>(API.url('licenses'));
  }

  public addSchoolsLicense(_license) {
    return this.http.post<any>(API.url('licenses'), _license);
  }

  public getSchoolsLicenseById(_id) {
    return this.http.get<LicenseModel[]>(API.url('licenses/' + _id));
  }

  public updateSchoolsLicenseById(_license) {
    return this.http.patch<LicenseModel[]>(API.url('licenses/' + _license._id), _license);
  }

  public deleteSchoolsLicense(_id) {
    return this.http.delete<LicenseModel[]>(API.url('licenses/' + _id));
  }

  public getDashboardData() {
    return this.http.get<any>(API.url('reports/dashboard'));
    //return this.http.get<any>('./assets/json/dd_data.json');
  }

  public getDashboardSchoolData(id, userId?) {
    let _uri = 'reports/school/' + id;
    if (userId) {
      _uri += '?student=' + userId;
    }
    return this.http.get<DashboardDataModel>(API.url(_uri));
  }

  public getDetailUserData(id) {
    return this.http.get<any>(API.url('reports/users/' + id));
  }

  deleteUser(userID: string) {
    return this.http.delete(API.url('users/' + userID));
  }

  deleteSchool(schoolID: string) {
    return this.http.delete(API.url('school/' + schoolID));
  }
}
